import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import { convertToHumanReadableKgString } from '../../../util/data';

import css from '../ListingPage.module.css';

const InlinePrice = props => { 
  const { priceTitle, formattedPrice, pricing, user } = props;

  const soldByMethod = !!pricing?.method?.nickName ? pricing.method.nickName : '' ;
  const isSoldByWeight = soldByMethod == 'by-weight';
  const isSoldByUnit = soldByMethod == 'by-unit';
  
  const unitType = !!pricing?.method?.unitType && pricing.method.unitType != '' ? pricing.method.unitType : null ;
  const hasUnitTypeDefined = unitType;
  
  let soldByTanslationKey  = 'ListingPage.byUnit';
  let  MoQTranslationKey = 'ListingPage.byUnit';
  let pricingMethod = {};

  if (isSoldByWeight) {
    soldByTanslationKey = 'ListingPage.byWeight';
    pricingMethod.weight = convertToHumanReadableKgString(pricing.method.unitWeight);
    MoQTranslationKey = 'ListingPage.byWieghtMoQ';
    pricingMethod.MoQ = pricing.method.MoQ;
  }

  if (isSoldByUnit) {
    soldByTanslationKey = 'ListingPage.byUnit';
    MoQTranslationKey = 'ListingPage.byUnitMoQ';
    pricingMethod.MoQ = pricing.method.MoQ;
  }

  if (unitType && unitType != '') {
    soldByTanslationKey = `ListingPage.per${unitType}`;
  }

  return (
    <div className={css.inlineHeading}>
      <div className={css.inlinePriceContainer}>
        <div
          className={css.inlinePriceValue}
          title={priceTitle}
          data-cy="productDetailsPagePriceContainer"
        >
          {formattedPrice}
          <div className={css.inlinePerUnit}>
            <FormattedMessage id={soldByTanslationKey} key={Math.random()} values={pricingMethod} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InlinePrice;
