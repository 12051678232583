import React from 'react';
import { bool, func, string, shape } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import { Form, IconDisputeOrder, Modal, FieldSelect } from '../../components';

import css from './TransactionsListFilterForm.module.css';

const TransactionsListFilterFormComponent = (props) => (
  <FinalForm
    {...props}
    render={(fieldRenderProps) => {
      const {
        className,
        rootClassName,
        handleSubmit,
        onChange,
        usersList,
        intl,
      } = fieldRenderProps;

      const classes = classNames(rootClassName || css.formRoot, className);

      return (
        <div className={classes}>
          
          <Form onSubmit={handleSubmit} onChange={onChange}>
          <span>Filters: </span>
            <FieldSelect
              className={css.detailsSelect}
              name="name"
              id="sid"
              label="transactionsUserFilter"
            >
              <option value="">Select supplier</option>
              {usersList.map((u) => (
                <option key={u.id.uuid} value={u.id.uuid}>
                  {u.attributes.profile.displayName}
                </option>
              ))}
            </FieldSelect>
          </Form>
        </div>
      );
    }}
  />
);

TransactionsListFilterFormComponent.defaultProps = {
  className: null,
  rootClassName: null,
  usersList: null,
};

TransactionsListFilterFormComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
};

const TransactionsListFilterForm = compose()(
  injectIntl(TransactionsListFilterFormComponent)
);

export default TransactionsListFilterForm;
