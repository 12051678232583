import React from 'react';
import { useEffect, useState } from 'react';

import { node, number, string } from 'prop-types';
import classNames from 'classnames';
import { useSendbirdStateContext } from '@sendbird/uikit-react';

import css from './UnreadMessagesCounter.module.css';

const UnreadMessagesCounter = props => {
  const [unreadCount, setUnreadCount] = useState(0);

  let sdk = null;
  try {
    const { stores = null } = useSendbirdStateContext(); // Access the Sendbird state context
    sdk = stores?.sdkStore?.sdk;
  } catch (error) {
    console.error(error);
    // Expected output: ReferenceError: nonExistentFunction is not defined
    // (Note: the exact output may be browser-dependent)
  }

  const getCount = () => {
    if (sdk && sdk.groupChannel) {
      try {
        const response = sdk.groupChannel.getTotalUnreadMessageCount();
        return response;
      } catch (error) {
        console.error(error);
      }
    } else {
      return Promise.resolve(0);
    }
  };
  let counter = 0;
  getCount().then(count => {
    setUnreadCount(count);
  });

  const { children, className, rootClassName, ...rest } = props;
  const classes = classNames(rootClassName || css.root, className);

  return unreadCount > 0 ? (
    <div className={classes} {...rest}>
      {unreadCount}
    </div>
  ) : (
    ''
  );
};

UnreadMessagesCounter.defaultProps = {
  className: null,
  rootClassName: null,
  children: null,
};

UnreadMessagesCounter.propTypes = {
  className: string,
  rootClassName: string,
  children: node,
};

export default UnreadMessagesCounter;
