import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Modal, InviteBuyerForm } from '..';
import { parse } from '../../util/urlHelpers';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';

import css from './AddUserModal.module.css';

const AddUserModal = props => {
  const {
    setIsInviteSupplierModalClosed,
    isInviteSupplierModalOpen,

    setIsInviteBuyerModalClosed,
    isInviteBuyerModalOpen,
    currentUser,
    onSendInvite,
    sendInviteInProgress,
    sendInviteError,
    inviteHasAlreadySentError,
  } = props;
  const addSupplierModal = (
    <div>
      <Modal
        {...props}
        isOpen={isInviteSupplierModalOpen}
        onClose={() => {
          setIsInviteSupplierModalClosed(false);
        }}
        id="inviteSupplier"
      >
        <div className={css.holdingImageSuppliers}>
          <div className={css.helpCopy}>
            <p>Ever wished for a single platform to manage all your suppliers seemlessly?</p>
            <p>
              Well, we're compatible with all supplier ordering methods, so take control and save
              time with <span>Platter.</span>
            </p>

            <a
              className={css.tryForFree}
              href="https://forms.gle/cx8Bs6w7en9gScDM6"
              target="_blank"
            >
              Try for free
            </a>
            <a
              className={css.bookDemo}
              href="https://calendly.com/jack-526/platter-demo?month=2024-05"
              target="_blank"
            >
              Book demo
            </a>
          </div>
        </div>
      </Modal>
    </div>
  );

  return isInviteSupplierModalOpen ? addSupplierModal : null;

  const addBuyerModal = (
    <div>
      <Modal
        {...props}
        isOpen={isInviteBuyerModalOpen}
        onClose={() => {
          setIsInviteBuyerModalClosed(false);
        }}
        onManageDisableScrolling={onManageDisableScrolling}
        id="InviteBuyer"
      >
        <div className={css.holdingImageSuppliers}>
          <div className={css.helpCopy}>
            <InviteBuyerForm
              className={css.form}
              currentUser={currentUser}
              updateInProgress={sendInviteInProgress}
              inviteBuyerError={sendInviteError}
              inviteHasAlreadySentError={inviteHasAlreadySentError}
              // initialValues={{ firstName, lastName, bio, profileImage: user.profileImage }}
              // profileImage={profileImage}
              // onImageUpload={e => onImageUploadHandler(e, onImageUpload)}
              // uploadInProgress={uploadInProgress}
              // updateInProgress={updateInProgress}
              // uploadImageError={uploadImageError}
              // updateProfileError={updateProfileError}
              onSubmit={onSendInvite}
            />
          </div>
        </div>
      </Modal>
    </div>
  );

  return isInviteSupplierModalOpen
    ? addSupplierModal
    : isInviteBuyerModalOpen
    ? addBuyerModal
    : null;
};

AddUserModal.defaultProps = {
  className: null,
  rootClassName: null,
  containerClassName: null,
};

const { node, string } = PropTypes;

AddUserModal.propTypes = {
  children: node.isRequired,
  className: string,
  rootClassName: string,
  containerClassName: string,
};

export default AddUserModal;
