import React from 'react';
import loadable from '@loadable/component';

import config from '../config';

import getPageDataLoadingAPI from '../containers/pageDataLoadingAPI';
import NotFoundPage from '../containers/NotFoundPage/NotFoundPage';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from '../components';

const pageDataLoadingAPI = getPageDataLoadingAPI();

const AboutPage = loadable(() =>
  import(
    /* webpackChunkName: "AboutPage" */ '../containers/AboutPage/AboutPage'
  )
);
const AuthenticationPage = loadable(() =>
  import(
    /* webpackChunkName: "AuthenticationPage" */ '../containers/AuthenticationPage/AuthenticationPage'
  )
);
const ContactDetailsPage = loadable(() =>
  import(
    /* webpackChunkName: "ContactDetailsPage" */ '../containers/ContactDetailsPage/ContactDetailsPage'
  )
);
const EditListingPage = loadable(() =>
  import(
    /* webpackChunkName: "EditListingPage" */ '../containers/EditListingPage/EditListingPage'
  )
);
const EmailVerificationPage = loadable(() =>
  import(
    /* webpackChunkName: "EmailVerificationPage" */ '../containers/EmailVerificationPage/EmailVerificationPage'
  )
);
const InboxPage = loadable(() =>
  import(
    /* webpackChunkName: "InboxPage" */ '../containers/InboxPage/InboxPage'
  )
);
const LandingPage = loadable(() =>
  import(
    /* webpackChunkName: "LandingPage" */ '../containers/LandingPage/LandingPage'
  )
);
const ListingPageHeroImage = loadable(() =>
  import(
    /* webpackChunkName: "ListingPageHeroImage" */ /* webpackPrefetch: true */ '../containers/ListingPage/ListingPageHeroImage'
  )
);
const ListingPageFullImage = loadable(() =>
  import(
    /* webpackChunkName: "ListingPageFullImage" */ /* webpackPrefetch: true */ '../containers/ListingPage/ListingPageFullImage'
  )
);

const DonationListingPage = loadable(() =>
  import(
    /* webpackChunkName: "DonationListingPage" */ /* webpackPrefetch: true */ '../containers/ListingPage/DonationListingPage'
  )
);

const ManageListingsPage = loadable(() =>
  import(
    /* webpackChunkName: "ManageListingsPage" */ '../containers/ManageListingsPage/ManageListingsPage'
  )
);
const MessagingPage = loadable(() =>
  import(
    /* webpackChunkName: "MessagingPage" */ '../containers/MessagingPage/MessagingPage'
  )
);
const PasswordChangePage = loadable(() =>
  import(
    /* webpackChunkName: "PasswordChangePage" */ '../containers/PasswordChangePage/PasswordChangePage'
  )
);
const   PasswordRecoveryPage = loadable(() =>
  import(
    /* webpackChunkName: "PasswordRecoveryPage" */ '../containers/PasswordRecoveryPage/PasswordRecoveryPage'
  )
);
const PasswordResetPage = loadable(() =>
  import(
    /* webpackChunkName: "PasswordResetPage" */ '../containers/PasswordResetPage/PasswordResetPage'
  )
);
const OneClickPage = loadable(() =>
  import(
    /* webpackChunkName: "OneClickPage" */ '../containers/OneClickPage/OneClickPage'
  )
);
const OneClickMessagePage = loadable(() =>
  import(
    /* webpackChunkName: "OneClickMessagePage" */ '../containers/OneClickMessagePage/OneClickMessagePage'
  )
);
const PluginHaulageOneClickPage = loadable(() =>
  import(
    /* webpackChunkName: "PluginHaulageOneClickPage" */ '../containers/PluginHaulageOneClickPage/PluginHaulageOneClickPage'
  )
);
const ClaimAccountPage = loadable(() =>
  import(
    /* webpackChunkName: "ClaimAccountPage" */ '../containers/ClaimAccountPage/ClaimAccountPage'
  )
);
const PaymentMethodsPage = loadable(() =>
  import(
    /* webpackChunkName: "PaymentMethodsPage" */ '../containers/PaymentMethodsPage/PaymentMethodsPage'
  )
);
const PrivacyPolicyPage = loadable(() =>
  import(
    /* webpackChunkName: "PrivacyPolicyPage" */ '../containers/PrivacyPolicyPage/PrivacyPolicyPage'
  )
);
const ProfilePage = loadable(() =>
  import(
    /* webpackChunkName: "ProfilePage" */ '../containers/ProfilePage/ProfilePage'
  )
);
const ProfileSettingsPage = loadable(() =>
  import(
    /* webpackChunkName: "ProfileSettingsPage" */ '../containers/ProfileSettingsPage/ProfileSettingsPage'
  )
);
const SearchPageWithMap = loadable(() =>
  import(
    /* webpackChunkName: "SearchPageWithMap" */ /* webpackPrefetch: true */ '../containers/SearchPage/SearchPageWithMap'
  )
);
const SearchPageWithList = loadable(() =>
  import(
    /* webpackChunkName: "SearchPageWithList" */ /* webpackPrefetch: true */ '../containers/SearchPage/SearchPageWithList'
  )
);
const TermsOfServicePage = loadable(() =>
  import(
    /* webpackChunkName: "TermsOfServicePage" */ '../containers/TermsOfServicePage/TermsOfServicePage'
  )
);
const TransactionPage = loadable(() =>
  import(
    /* webpackChunkName: "TransactionPage" */ '../containers/TransactionPage/TransactionPage'
  )
);
const TransactionDonationPage = loadable(() =>
  import(
    /* webpackChunkName: "TransactionDonationPage" */ '../containers/TransactionPage/TransactionDonationPage'
  )
);

// Styleguide helps you to review current components and develop new ones
const StyleguidePage = loadable(() =>
  import(
    /* webpackChunkName: "StyleguidePage" */ '../containers/StyleguidePage/StyleguidePage'
  )
);

const SearchPage =
  config.searchPageVariant === 'map' ? SearchPageWithMap : SearchPageWithList;
const ListingPage =
  config.listingPageLayout === 'full-image'
    ? ListingPageFullImage
    : ListingPageHeroImage;

export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'PasswordChangePage',
  'PaymentMethodsPage',
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LoginPage" />;

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = () => {
  return [
    {
      path: '/',
      name: 'LandingPage',
      component: RedirectToLandingPage,
      buyerRoute: {
        name: 'InboxPage',
        params: { tab: 'suppliers' },
      },
      sellerRoute: {
        name: 'InboxPage',
        params: { tab: 'sales' },
      }
      ,
      doneeRoute: {
        name: 'InboxPage',
        params: { tab: 'received-donations' },
      },
    },


    
    {
      path: '/about',
      name: 'AboutPage',
      component: AboutPage,
    },
    {
      path: '/s',
      name: 'SearchPage',
      component: SearchPage,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
      auth: true,
    },
    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
      auth: true,
    },
    {
      path: '/l/:slug/:id',
      name: 'ListingPage',
      auth: true,
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },

        {
      path: '/l/:slug/:id/:bid',
      name: 'ListingPageSupplierView',
      auth: true,
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },

    {
      path: '/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/new',
      name: 'NewListingPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'details' }}
        />
      ),
    },
    {
      path: '/l/:slug/:id/:type/:tab',
      name: 'EditListingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/l/:id',
      name: 'ListingPageCanonical',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
      auth: true,
    },

    
    {
      path: '/dl/:id',
      name: 'DonationListingPageCanonical',
      component: DonationListingPage,
      loadData: pageDataLoadingAPI.DonationListingPage.loadData,
      auth: true,
    },
    {
      path: '/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
      auth: true,
    },
    {
      path: '/u/:id',
      name: 'ProfilePage',
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
      auth: true,
    },
    {
      path: '/profile-settings',
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProfileSettingsPage,
    },

    // Note: authenticating with IdP (e.g. Facebook) expects that /login path exists
    // so that in the error case users can be redirected back to the LoginPage
    // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
    {
      path: '/login',
      name: 'LoginPage',
      component: AuthenticationPage,
      extraProps: { tab: 'login' },
    },
    {
      path: '/signup',
      name: 'SignupPage',
      component: AuthenticationPage,
      extraProps: { tab: 'signup' },
    },
        {
      path: '/invite/request-for-info',
      name: 'InviteBuyerRequestForInfoPage',
      component: AuthenticationPage,
      extraProps: { tab: 'buyerRequestForInfo' },
    },
    {
      path: '/invited',
      name: 'AcceptInvitePage',
      component: AuthenticationPage,
      extraProps: { tab: 'acceptInvite' },
    },
    {
      path: '/confirm',
      name: 'ConfirmPage',
      component: AuthenticationPage,
      extraProps: { tab: 'confirm' },
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: PasswordRecoveryPage,
    },
    {
      path: '/oneclick',
      name: 'OneClickPage',
      component: OneClickPage,
      loadData: pageDataLoadingAPI.OneClickPage.loadData,
    },
        {
      path: '/oneclick/message',
      name: 'OneClickMessagePage',
      component: OneClickMessagePage,
      loadData: pageDataLoadingAPI.OneClickMessagePage.loadData,
    },
        {
      path: '/oneclick/haulage',
      name: 'PluginHaulageOneClickPage',
      component: PluginHaulageOneClickPage,
      loadData: pageDataLoadingAPI.PluginHaulageOneClickPage.loadData,
    },
        {
      path: '/claim-account',
      name: 'ClaimAccountPage',
      component: ClaimAccountPage,
      loadData: pageDataLoadingAPI.ClaimAccountPage.loadData,
    },
    {
      path: '/inbox',
      name: 'InboxBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => (
        <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />
      ),
      buyerRoute: {
        name: 'InboxPage',
        params: { tab: 'suppliers' },
      },
      sellerRoute: {
        name: 'InboxPage',
        params: { tab: 'sales' },
      },
      doneeRoute: {
        name: 'InboxPage',
        params: { tab: 'received-donations' },
      },
    },
    {
      path: '/inbox/:tab',
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: InboxPage,
      loadData: pageDataLoadingAPI.InboxPage.loadData,
    },

    {
      path: '/order/:id',
      name: 'OrderPage',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => (
        <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />
      ),
    },
    {
      path: '/order/:id/details',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'customer' },
      loadData: (params) =>
        pageDataLoadingAPI.TransactionPage.loadData({
          ...params,
          transactionRole: 'customer',
        }),
      setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
    },
    {
      path: '/order/:id/form',
      name: 'OrderFormPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'customer' },
      loadData: (params) =>
        pageDataLoadingAPI.TransactionPage.loadData({
          ...params,
          transactionRole: 'customer',
        }),
      setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
    },
    {
      path: '/sale/:id',
      name: 'SalePage',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => (
        <NamedRedirect name="SaleDetailsPage" params={{ ...props.params }} />
      ),
    },
    {
      path: '/sale/:id/details',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'provider' },
      loadData: (params) =>
        pageDataLoadingAPI.TransactionPage.loadData({
          ...params,
          transactionRole: 'provider',
        }),
    },
    {
      path: '/received-donation/:id',
      name: 'RecievedDonationPage',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => (
        <NamedRedirect name="RecievedDonationDetailsPage" params={{ ...props.params }} />
      ),
    },
    {
      path: '/received-donation/:id/details',
      name: 'ReceivedDonationDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionDonationPage,
      extraProps: { transactionRole: 'provider' },
      loadData: (params) =>
        pageDataLoadingAPI.TransactionPage.loadData({
          ...params,
          transactionRole: 'provider',
        }),
    },
    {
      path: '/offered-donation/:id',
      name: 'OfferedDonationPage',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => (
        <NamedRedirect name="OfferedDonationDetailsPage" params={{ ...props.params }} />
      ),
    },
    {
      path: '/offered-donation/:id/details',
      name: 'OfferedDonationDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionDonationPage,
      extraProps: { transactionRole: 'customer' },
      loadData: (params) =>
        pageDataLoadingAPI.TransactionPage.loadData({
          ...params,
          transactionRole: 'customer',
        }),
    },
    {
      path: '/listings',
      name: 'ManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ManageListingsPage,
      loadData: pageDataLoadingAPI.ManageListingsPage.loadData,
    },
     {
      path: '/messaging',
      name: 'MessagingPage',
      auth: true,
      authPage: 'LoginPage',
      component: MessagingPage,
      loadData: pageDataLoadingAPI.MessagingPage.loadData,
    },
    {
      path: '/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ContactDetailsPage" />,
    },
    {
      path: '/account/contact-details',
      name: 'ContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ContactDetailsPage,
      loadData: pageDataLoadingAPI.ContactDetailsPage.loadData,
    },
    {
      path: '/account/change-password',
      name: 'PasswordChangePage',
      auth: true,
      authPage: 'LoginPage',
      component: PasswordChangePage,
    },
    {
      path: '/account/payment-methods',
      name: 'PaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: PaymentMethodsPage,
      loadData: pageDataLoadingAPI.PaymentMethodsPage.loadData,
    },
    {
      path: '/terms-of-service',
      name: 'TermsOfServicePage',
      auth: false,
      component: TermsOfServicePage,
    },
    {
      path: '/privacy-policy',
      auth: false,
      name: 'PrivacyPolicyPage',
      component: PrivacyPolicyPage,
    },
    {
      path: '/styleguide',
      name: 'Styleguide',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/g/:group',
      name: 'StyleguideGroup',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component',
      name: 'StyleguideComponent',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example',
      name: 'StyleguideComponentExample',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example/raw',
      name: 'StyleguideComponentExampleRaw',
      component: StyleguidePage,
      extraProps: { raw: true },
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: (props) => <NotFoundPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: PasswordResetPage,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: EmailVerificationPage,
      loadData: pageDataLoadingAPI.EmailVerificationPage.loadData,
    },
  ];
};

export default routeConfiguration;
