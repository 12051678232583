import React, { Component } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';

import {
  Form,
  Button,
  IconSpinner,
  FieldTextInput,
  FieldCheckbox,
  FieldRadioButton,
} from '../../components';

import css from './InviteSupplierForm.module.css';

class InviteSupplierFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { uploadDelay: false };
    this.submittedValues = {};
  }

  componentDidUpdate(prevProps) {
    // Upload delay is additional time window where Avatar is added to the DOM,
    // but not yet visible (time to load image URL from srcset)
  }

  componentWillUnmount() {}

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            className,
            currentUser,
            handleSubmit,
            intl,
            invalid,
            pristine,
            rootClassName,
            updateInProgress,
            inviteSupplierError,
            uploadImageError,
            uploadInProgress,
            form,
            values,
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);

          // First name
          const firstNameLabel = intl.formatMessage({
            id: 'InviteSupplierForm.firstNameLabel',
          });
          const firstNamePlaceholder = intl.formatMessage({
            id: 'InviteSupplierForm.firstNamePlaceholder',
          });
          const firstNameRequiredMessage = intl.formatMessage({
            id: 'InviteSupplierForm.firstNameRequired',
          });
          const firstNameRequired = validators.required(firstNameRequiredMessage);

          // Last name
          const lastNameLabel = intl.formatMessage({
            id: 'InviteSupplierForm.lastNameLabel',
          });
          const lastNamePlaceholder = intl.formatMessage({
            id: 'InviteSupplierForm.lastNamePlaceholder',
          });
          const lastNameRequiredMessage = intl.formatMessage({
            id: 'InviteSupplierForm.lastNameRequired',
          });
          const lastNameRequired = validators.required(lastNameRequiredMessage);

          // Email
          const emailLabel = intl.formatMessage({
            id: 'InviteSupplierForm.emailLabel',
          });
          const emailPlaceholder = intl.formatMessage({
            id: 'InviteSupplierForm.emailPlaceholder',
          });
          const emailRequiredMessage = intl.formatMessage({
            id: 'InviteSupplierForm.emailRequired',
          });
          const emailRequired = validators.businesEmailValid(emailRequiredMessage);

          const submitError = inviteSupplierError ? (
            <div className={css.error}>
              <FormattedMessage id="InviteSupplierForm.updateProfileFailed" />
            </div>
          ) : null;

          const uploadPricesLabel = intl.formatMessage({
            id: 'InviteSupplierForm.uploadPricesLabel',
          });
          const uploadProductsLabel = intl.formatMessage({
            id: 'InviteSupplierForm.uploadProductsLabel',
          });
          const uploadImagesLabel = intl.formatMessage({
            id: 'InviteSupplierForm.uploadImagesLabel',
          });
          const uploadLogoLabel = intl.formatMessage({
            id: 'InviteSupplierForm.uploadLogoLabel',
          });

          const uploadDatasheetLabel = intl.formatMessage({
            id: 'InviteSupplierForm.uploadDatasheetLabel',
          });

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled =
            invalid || pristine || pristineSinceLastSubmit || uploadInProgress || submitInProgress;

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                e.preventDefault();
                handleSubmit(this.submittedValues);
              }}
            >
              <h1 className={css.sectionTitle}>
                <FormattedMessage id="InviteSupplierForm.inviteHeader" />
              </h1>
              <div className={css.sectionContainer}>
                <h3 className={css.sectionTitle}>
                  <FormattedMessage id="InviteSupplierForm.uploadOptions" />
                </h3>
                <div className={css.nameContainer}>
                  <FieldTextInput
                    className={css.firstName}
                    type="text"
                    id="firstName"
                    name="firstName"
                    label={firstNameLabel}
                    placeholder={firstNamePlaceholder}
                    validate={firstNameRequired}
                  />
                  <FieldTextInput
                    className={css.lastName}
                    type="text"
                    id="lastName"
                    name="lastName"
                    label={lastNameLabel}
                    placeholder={lastNamePlaceholder}
                    validate={lastNameRequired}
                  />
                </div>
                <h3 className={css.sectionTitle}>
                  <FormattedMessage id="InviteSupplierForm.email" />
                </h3>
                <div className={css.emailContainer}>
                  <FieldTextInput
                    className={css.email}
                    type="text"
                    id="email"
                    name="email"
                    label={emailLabel}
                    placeholder={emailPlaceholder}
                    validate={emailRequired}
                  />
                </div>
                <div className={css.uploadOptionsContainer}>
                  <h3 className={css.sectionTitle}>How do you want to onboard the supplier?</h3>
                  <FieldRadioButton
                    id={`sendToPlatter`}
                    name="sendTo"
                    label="Ask Platter to onboard the supplier"
                    value="platter"
                  />
                  <FieldRadioButton
                    id={`sendToSupplier`}
                    name="sendTo"
                    label="Send invite direct to supplier"
                    value="direct"
                  />{' '}
                </div>
                <div className={css.uploadOptionsContainer}>
                  <h3 className={css.sectionTitle}>
                    <FormattedMessage id="InviteSupplierForm.uploadOptions" />
                  </h3>
                  <FieldCheckbox
                    id="uploadProducts"
                    className={css.uploadOptions}
                    name="uploadProducts"
                    label={uploadProductsLabel}
                    value="true"
                  />
                  <FieldCheckbox
                    id="uploadPrices"
                    className={css.uploadOptions}
                    name="uploadPrices"
                    label={uploadPricesLabel}
                    value="true"
                  />
                  <FieldCheckbox
                    id="uploadImages"
                    className={css.uploadOptions}
                    name="uploadImages"
                    label={uploadImagesLabel}
                    value="true"
                  />
                  <FieldCheckbox
                    id="uploadLogo"
                    className={css.uploadOptions}
                    name="uploadLogo"
                    label={uploadLogoLabel}
                    value="true"
                  />
                  <FieldCheckbox
                    id="uploadDatasheet"
                    className={css.uploadOptions}
                    name="uploadDatasheet"
                    label={uploadDatasheetLabel}
                    value="true"
                  />
                </div>
              </div>

              {submitError}

              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={pristineSinceLastSubmit}
              >
                INVITE SUPPLIER
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

InviteSupplierFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inviteSupplierError: null,
  inviteSupplierReady: false,
};

InviteSupplierFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inviteSupplierError: propTypes.error,
  inviteSupplierReady: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const InviteSupplierForm = compose(injectIntl)(InviteSupplierFormComponent);

InviteSupplierForm.displayName = 'InviteSupplierForm';

export default InviteSupplierForm;
