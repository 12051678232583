// These helpers are calling FTW's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import { types as sdkTypes, transit } from './sdkLoader';
import config from '../config';
import Decimal from 'decimal.js';

export const apiBaseUrl = () => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  // Otherwise, use the same domain and port as the frontend
  return `${window.location.origin}`;
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const serialize = data => {
  return transit.write(data, {
    typeHandlers,
    verbose: config.sdk.transitVerbose,
  });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

const get = (path, body) => {
  const url = `${apiBaseUrl()}${path}?${new URLSearchParams(body)}`;
  const options = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
  };

  return window.fetch(url, options).then(res => {
    const contentTypeHeader = res.headers.get('Content-Type');
    const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

    if (res.status >= 400) {
      return res.json().then(data => {
        let e = new Error();
        e = Object.assign(e, data);

        throw e;
      });
    }
    if (contentType === 'application/transit+json') {
      return res.text().then(deserialize);
    } else if (contentType === 'application/json') {
      return res.json();
    }
    return res.text();
  });
};

const post = (path, body) => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
    body: serialize(body),
  };
  return window.fetch(url, options).then(res => {
    const contentTypeHeader = res.headers.get('Content-Type');
    const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

    if (res.status >= 400) {
      return res.json().then(data => {
        let e = new Error();
        e = Object.assign(e, data);

        throw e;
      });
    }
    if (contentType === 'application/transit+json') {
      return res.text().then(deserialize);
    } else if (contentType === 'application/json') {
      return res.json();
    }
    return res.text();
  });
};

const postUploadForm = (path, body) => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: {},
    body: body.formData,
  };
  return window.fetch(url, options).then(res => {
    const contentTypeHeader = res.headers.get('Content-Type');
    const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

    if (res.status >= 400) {
      return res.json().then(data => {
        let e = new Error();
        e = Object.assign(e, data);

        throw e;
      });
    }
    if (contentType === 'application/transit+json') {
      return res.text().then(deserialize);
    } else if (contentType === 'application/json') {
      return res.json();
    }
    return res.text();
  });
};

export const sendInviteToNewConnection = body => {
  return post('/api/invite/invite', body);
};

export const sendAccountReadyInviteToNewConnection = body => {
  return post('/api/invite/account-ready', body);
};

export const sendBuyerRequestForInfo = body => {
  return post('/api/invite/invite', body);
};

export const convertInvite = body => {
  return post('/api/invite/convert', body);
};

export const buyerRequestForInfoResponse = body => {
  return post('/api/invite/request-for-info', body);
};

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => {
  return post('/api/transaction-line-items', body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the order data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = body => {
  return post('/api/initiate-privileged', body);
};

export const initiateDonationPrivileged = body => {
  return post('/api/initiate-donation-privileged', body);
};

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = body => {
  return post('/api/transition-privileged', body);
};

export const listingsQuery = body => {
  return get('/api/listings/query', body);
};

/* REPLACES */
export const searchPageWithPrices = body => {
  return post('/api/search-page-with-prices', body);
};

export const getTransaction = body => {
  return post('/api/get-transaction-with-line-item-details', body);
};

export const getTransactions = body => {
  return post('/api/get-transactions-with-line-item-details', body);
};

export const getDonations = body => {
  body.transactionType = 'donation';
  return post('/api/get-transactions-with-line-item-details', body);
};

export const addLineItemPrivileged = body => {
  return post('/api/add-line-item-privileged', body);
};

export const listingWithPrices = body => {
  return post('/api/listing-with-prices', body);
};

export const listingWithoutPrices = body => {
  return post('/api/listing-without-prices', body);
};

export const sendToSupplierPrivileged = body => {
  return post('/api/send-to-supplier-privileged', body);
};

export const donationAccepted = body => {
  return post('/api/donation-accepted', body);
};
export const donationAcceptedAfterRejected = body => {
  return post('/api/donation-accepted-after-rejected', body);
};

export const donationRejected = body => {
  return post('/api/donation-rejected', body);
};

export const donationReceived = body => {
  return post('/api/donation-accepted', body);
};

export const updateLineItemsAfterPicking = body => {
  return post('/api/update-line-items-after-picking', body);
};
export const removeLineItemsApi = body => {
  return post('/api/remove-line-items', body);
};
export const addPurchaseOrderNumberApi = body => {
  return post('/api/add-purchase-order-number', body);
};
export const addPurchaseOrderNumberAndFileApi = body => {
  return postUploadForm('/api/add-purchase-order-number-and-file', body);
};

export const addTransportDetailsWithDeliveryNoteApi = body => {
  return postUploadForm('/api/add-transport-details-with-delivery-note', body);
};

export const addInvoiceNumberApi = body => {
  return post('/api/add-invoice-number', body);
};
export const addInvoiceNumberAndFileApi = body => {
  return postUploadForm('/api/add-invoice-number-and-file', body);
};

export const addDeliveryDateApi = body => {
  return post('/api/add-delivery-date', body);
};

export const customerAddDeliveryDateApi = body => {
  return post('/api/customer-add-delivery-date', body);
};

export const addSupplierOrderNumberApi = body => {
  return post('/api/add-supplier-order-number', body);
};

export const addMessageAndFileApi = async body => {
  return postUploadForm('/api/add-message-and-file', body);
};

export const placeOnHoldApi = async body => {
  return post('/api/place-on-hold', body);
};

export const cancelApi = async body => {
  return post('/api/cancel', body);
};

export const orderApproved = body => {
  return post('/api/order-approved-by-supplier-privileged', body);
};

export const onHoldOrderApproved = body => {
  return post('/api/on-hold-order-approved-by-supplier-privileged', body);
};

export const orderMarkedAsPicked = body => {
  return post('/api/order-marked-as-picked', body);
};

export const orderMarkedAsDispatched = body => {
  return post('/api/order-marked-as-dispatched', body);
};

export const orderBookHaulage = body => {
  return post('/api/order-book-haulage', body);
};

export const oneClick = body => {
  return post('/api/oneclick', body);
};

export const oneClickMessage = body => {
  return get('/api/oneclick/message', body);
};

export const oneClickMessageSend = async body => {
  return postUploadForm('/api/oneclick/message', body);
};

export const pluginHaulageOneClick = body => {
  return post('/api/oneclick/haulage', body);
};

export const claimAccount = body => {
  return get('/api/claim-account', body);
};
export const claimAccountSetPassword = body => {
  return post('/api/claim-account', body);
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Flex with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = body => {
  return post('/api/auth/create-user-with-idp', body);
};
