import { denormalisedResponseEntities, ensureOwnListing } from '../util/data';
import { storableError } from '../util/errors';
import { transitionsToRequested } from '../util/transaction';
import { LISTING_STATE_DRAFT } from '../util/types';
import * as log from '../util/log';
import { authInfo } from './Auth.duck';
import { util as sdkUtil } from '../util/sdkLoader';
import { sendInviteToNewConnection, sendAccountReadyInviteToNewConnection } from '../util/api';

// ================ Action types ================ //

export const CURRENT_USER_SHOW_REQUEST = 'app/user/CURRENT_USER_SHOW_REQUEST';
export const CURRENT_USER_SHOW_SUCCESS = 'app/user/CURRENT_USER_SHOW_SUCCESS';
export const CURRENT_USER_SHOW_ERROR = 'app/user/CURRENT_USER_SHOW_ERROR';

export const CLEAR_CURRENT_USER = 'app/user/CLEAR_CURRENT_USER';

export const FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST';
export const FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_LISTINGS_ERROR =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_ERROR';

export const FETCH_CURRENT_USER_CONNECTIONS_DETAILS_REQUEST =
  'app/user/FETCH_CURRENT_USER_CONNECTIONS_DETAILS_REQUEST';
export const FETCH_CURRENT_USER_CONNECTIONS_DETAILS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_CONNECTIONS_DETAILS_SUCCESS';
export const FETCH_CURRENT_USER_CONNECTIONS_DETAILS_ERROR =
  'app/user/FETCH_CURRENT_USER_CONNECTIONS_DETAILS_ERROR';

export const FETCH_CURRENT_USER_ACTIVE_BASKET_REQUEST =
  'app/user/FETCH_CURRENT_USER_ACTIVE_BASKET_REQUEST';
export const FETCH_CURRENT_USER_ACTIVE_BASKET_SUCCESS =
  'app/user/FETCH_CURRENT_USER_ACTIVE_BASKET_SUCCESS';
export const FETCH_CURRENT_USER_ACTIVE_BASKET_ERROR =
  'app/user/FETCH_CURRENT_USER_ACTIVE_BASKET_ERROR';

export const FETCH_CURRENT_USER_ACTIVE_BASKET_DETAILS_REQUEST =
  'app/user/FETCH_CURRENT_USER_ACTIVE_BASKET_DETAILS_REQUEST';
export const FETCH_CURRENT_USER_ACTIVE_BASKET_DETAILS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_ACTIVE_BASKET_DETAILS_SUCCESS';
export const FETCH_CURRENT_USER_ACTIVE_BASKET_DETAILS_ERROR =
  'app/user/FETCH_CURRENT_USER_ACTIVE_BASKET_DETAILS_ERROR';

export const FETCH_CURRENT_USER_PERMISSIONS_REQUEST =
  'app/user/FETCH_CURRENT_USER_PERMISSIONS_REQUEST';
export const FETCH_CURRENT_USER_PERMISSIONS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_PERMISSIONS_SUCCESS';
export const FETCH_CURRENT_USER_PERMISSIONS_ERROR = 'app/user/FETCH_CURRENT_PERMISSIONS_ERROR';

export const FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST';
export const FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS';
export const FETCH_CURRENT_USER_NOTIFICATIONS_ERROR =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_ERROR';

export const FETCH_CURRENT_USER_HAS_ORDERS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_REQUEST';
export const FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_ORDERS_ERROR = 'app/user/FETCH_CURRENT_USER_HAS_ORDERS_ERROR';

export const SEND_VERIFICATION_EMAIL_REQUEST = 'app/user/SEND_VERIFICATION_EMAIL_REQUEST';
export const SEND_VERIFICATION_EMAIL_SUCCESS = 'app/user/SEND_VERIFICATION_EMAIL_SUCCESS';
export const SEND_VERIFICATION_EMAIL_ERROR = 'app/user/SEND_VERIFICATION_EMAIL_ERROR';

export const SEND_INVITE_REQUEST = 'app/user/SEND_INVITE_REQUEST';
export const SEND_INVITE_SUCCESS = 'app/user/SEND_INVITE_SUCCESS';
export const SEND_INVITE_ERROR = 'app/user/SEND_INVITE_ERROR';

export const INVITE_ALREADY_SENT = 'app/user/INVITE_ALREADY_SENT';
// ================ Reducer ================ //

const mergeCurrentUser = (oldCurrentUser, newCurrentUser) => {
  const { id: oId, type: oType, attributes: oAttr, ...oldRelationships } = oldCurrentUser || {};
  const { id, type, attributes, ...relationships } = newCurrentUser || {};

  // Passing null will remove currentUser entity.
  // Only relationships are merged.
  // TODO figure out if sparse fields handling needs a better handling.
  return newCurrentUser === null
    ? null
    : oldCurrentUser === null
    ? newCurrentUser
    : { id, type, attributes, ...oldRelationships, ...relationships };
};

const initialState = {
  currentUser: null,
  currentUserShowError: null,
  currentUserHasListings: false,
  currentUserHasListingsError: null,
  currentUserConnectionsDetails: null,
  currentUserActiveBaskets: null,
  currentUserActiveBasketsDetails: null,
  currentUserPermissions: null,
  currentUserNotificationCount: 0,
  currentUserNotificationCountError: null,
  currentUserHasOrders: null, // This is not fetched unless unverified emails exist
  currentUserHasOrdersError: null,
  sendVerificationEmailInProgress: false,
  sendVerificationEmailError: null,
  sendInviteInProgress: false,
  sendInviteError: false,
  inviteHasAlreadySentError: false,
  sendInviteSuccess: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CURRENT_USER_SHOW_REQUEST:
      return { ...state, currentUserShowError: null };
    case CURRENT_USER_SHOW_SUCCESS:
      return {
        ...state,
        currentUser: mergeCurrentUser(state.currentUser, payload),
      };
    case CURRENT_USER_SHOW_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, currentUserShowError: payload };

    case CLEAR_CURRENT_USER:
      return {
        ...state,
        currentUser: null,
        currentUserShowError: null,
        currentUserHasListings: false,
        currentUserConnectionsDetails: null,
        currentUserActiveBaskets: null,
        currentUserActiveBasketsDetails: null,
        currentUserPermissions: null,
        currentUserHasListingsError: null,
        currentUserNotificationCount: 0,
        currentUserNotificationCountError: null,
      };

    case FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST:
      return { ...state, currentUserHasListingsError: null };
    case FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS:
      return { ...state, currentUserHasListings: payload.hasListings };
    case FETCH_CURRENT_USER_HAS_LISTINGS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasListingsError: payload };

    case FETCH_CURRENT_USER_CONNECTIONS_DETAILS_REQUEST:
      return { ...state, currentUserHasConnectionDetailsError: null };
    case FETCH_CURRENT_USER_CONNECTIONS_DETAILS_SUCCESS:
      return {
        ...state,
        currentUserConnectionsDetails: payload.connectionsWithDetails,
      };
    case FETCH_CURRENT_USER_CONNECTIONS_DETAILS_ERROR:
      return { ...state, currentUserHasListingsError: payload };

    case FETCH_CURRENT_USER_ACTIVE_BASKET_REQUEST:
      return { ...state, currentUserHasActiveBasketsError: null };
    case FETCH_CURRENT_USER_ACTIVE_BASKET_SUCCESS:
      return {
        ...state,
        currentUserActiveBaskets: payload.activeBaskets,
      };
    case FETCH_CURRENT_USER_ACTIVE_BASKET_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasActiveBasketsError: payload };

    case FETCH_CURRENT_USER_ACTIVE_BASKET_DETAILS_REQUEST:
      return { ...state, currentUserHasActiveBasketsDetailsError: null };
    case FETCH_CURRENT_USER_ACTIVE_BASKET_DETAILS_SUCCESS:
      return {
        ...state,
        currentUserActiveBasketsDetails: payload.activeBasketsDetails,
      };
    case FETCH_CURRENT_USER_ACTIVE_BASKET_DETAILS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasActiveBasketsDetailsError: payload };

    case FETCH_CURRENT_USER_PERMISSIONS_REQUEST:
      return { ...state, currentUserPermissionsError: null };
    case FETCH_CURRENT_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        currentUserPermissions: payload.currentUserPermissions,
      };
    case FETCH_CURRENT_USER_PERMISSIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserPermissionsError: payload };

    case FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST:
      return { ...state, currentUserNotificationCountError: null };
    case FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        currentUserNotificationCount: payload.transactions.length,
      };
    case FETCH_CURRENT_USER_NOTIFICATIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserNotificationCountError: payload };

    case FETCH_CURRENT_USER_HAS_ORDERS_REQUEST:
      return { ...state, currentUserHasOrdersError: null };
    case FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS:
      return { ...state, currentUserHasOrders: payload.hasOrders };
    case FETCH_CURRENT_USER_HAS_ORDERS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasOrdersError: payload };

    case SEND_VERIFICATION_EMAIL_REQUEST:
      return {
        ...state,
        sendVerificationEmailInProgress: true,
        sendVerificationEmailError: null,
      };
    case SEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
      };
    case SEND_VERIFICATION_EMAIL_ERROR:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
        sendVerificationEmailError: payload,
      };

    case SEND_INVITE_REQUEST:
      return {
        ...state,
        sendInviteInProgress: true,
        sendInviteError: null,
        sendInviteSuccess: false,
      };

    case INVITE_ALREADY_SENT:
      return {
        ...state,
        inviteHasAlreadySentError: true,
        sendInviteError: false,
      };
    case SEND_INVITE_SUCCESS:
      return {
        ...state,
        sendInviteSuccess: true,
        sendInviteInProgress: false,
      };
    case SEND_INVITE_ERROR:
      console.log('error response');
      console.log(payload);
      return {
        ...state,
        sendInviteInProgress: false,
        sendInviteError: payload,
        sendInviteSuccess: false,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const hasCurrentUserErrors = state => {
  const { user } = state;
  return (
    user.currentUserShowError ||
    user.currentUserHasListingsError ||
    user.currentUserNotificationCountError ||
    user.currentUserHasOrdersError
  );
};

export const verificationSendingInProgress = state => {
  return state.user.sendVerificationEmailInProgress;
};

export const inviteSendingInProgress = state => {
  return state.user.sendInviteInProgress;
};

// ================ Action creators ================ //

export const currentUserShowRequest = () => ({
  type: CURRENT_USER_SHOW_REQUEST,
});

export const currentUserShowSuccess = user => ({
  type: CURRENT_USER_SHOW_SUCCESS,
  payload: user,
});

export const currentUserShowError = e => ({
  type: CURRENT_USER_SHOW_ERROR,
  payload: e,
  error: true,
});

export const clearCurrentUser = () => ({ type: CLEAR_CURRENT_USER });

const fetchCurrentUserHasListingsRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST,
});

export const fetchCurrentUserHasListingsSuccess = hasListings => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS,
  payload: { hasListings },
});

const fetchCurrentUserHasListingsError = e => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserConnectionsDetailsRequest = () => ({
  type: FETCH_CURRENT_USER_CONNECTIONS_DETAILS_REQUEST,
});

export const fetchCurrentUserConnectionsDetailsSuccess = connectionsWithDetails => ({
  type: FETCH_CURRENT_USER_CONNECTIONS_DETAILS_SUCCESS,
  payload: { connectionsWithDetails },
});

const fetchCurrentUserConnectionsDetailsError = e => ({
  type: FETCH_CURRENT_USER_CONNECTIONS_DETAILS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserActiveBasketsRequest = () => ({
  type: FETCH_CURRENT_USER_ACTIVE_BASKET_REQUEST,
});

export const fetchCurrentUserActiveBasketsSuccess = activeBaskets => ({
  type: FETCH_CURRENT_USER_ACTIVE_BASKET_SUCCESS,
  payload: { activeBaskets },
});

const fetchCurrentUserActiveBasketsError = e => ({
  type: FETCH_CURRENT_USER_ACTIVE_BASKET_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserActiveBasketsDetailsRequest = () => ({
  type: FETCH_CURRENT_USER_ACTIVE_BASKET_DETAILS_REQUEST,
});

export const fetchCurrentUserActiveBasketsDetailsSuccess = activeBasketsDetails => ({
  type: FETCH_CURRENT_USER_ACTIVE_BASKET_DETAILS_SUCCESS,
  payload: { activeBasketsDetails },
});

const fetchCurrentUserActiveBasketsDetailsError = e => ({
  type: FETCH_CURRENT_USER_ACTIVE_BASKET_DETAILS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserPermissionsRequest = () => ({
  type: FETCH_CURRENT_USER_PERMISSIONS_REQUEST,
});

export const fetchCurrentUserPermissionsSuccess = currentUserPermissions => ({
  type: FETCH_CURRENT_USER_PERMISSIONS_SUCCESS,
  payload: { currentUserPermissions },
});

const fetchCurrentUserPermissionsError = e => ({
  type: FETCH_CURRENT_CURRENT_USER_PERMISSIONS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserNotificationsRequest = () => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST,
});

export const fetchCurrentUserNotificationsSuccess = transactions => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS,
  payload: { transactions },
});

const fetchCurrentUserNotificationsError = e => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserHasOrdersRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_REQUEST,
});

export const fetchCurrentUserHasOrdersSuccess = hasOrders => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS,
  payload: { hasOrders },
});

const fetchCurrentUserHasOrdersError = e => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_ERROR,
  error: true,
  payload: e,
});

export const sendVerificationEmailRequest = () => ({
  type: SEND_VERIFICATION_EMAIL_REQUEST,
});

export const sendVerificationEmailSuccess = () => ({
  type: SEND_VERIFICATION_EMAIL_SUCCESS,
});

export const sendVerificationEmailError = e => ({
  type: SEND_VERIFICATION_EMAIL_ERROR,
  error: true,
  payload: e,
});

export const sendInviteRequest = () => ({
  type: SEND_INVITE_REQUEST,
});

export const sendInviteSuccess = () => ({
  type: SEND_INVITE_SUCCESS,
});

export const sendInviteError = e => ({
  type: SEND_INVITE_ERROR,
  error: true,
  payload: e,
});

export const inviteAlreadySentError = e => ({
  type: INVITE_ALREADY_SENT,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const fetchCurrentUserHasListings = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasListingsRequest());
  const { currentUser } = getState().user;

  if (!currentUser) {
    dispatch(fetchCurrentUserHasListingsSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    // Since we are only interested in if the user has
    // listings, we only need at most one result.
    page: 1,
    per_page: 1,
  };

  return sdk.ownListings
    .query(params)
    .then(response => {
      const hasListings = response.data.data && response.data.data.length > 0;

      const hasPublishedListings =
        hasListings &&
        ensureOwnListing(response.data.data[0]).attributes.state !== LISTING_STATE_DRAFT;
      dispatch(fetchCurrentUserHasListingsSuccess(!!hasPublishedListings));
    })
    .catch(e => dispatch(fetchCurrentUserHasListingsError(storableError(e))));
};

export const fetchCurrentUserConnectionsDetails = () => async (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserConnectionsDetailsRequest());
  const { currentUser } = getState().user;
  if (!currentUser) {
    dispatch(fetchCurrentUserConnectionsDetailsSuccess(null));
    return Promise.resolve(null);
  }

  const privateData = currentUser.attributes.profile.privateData;
  const connections = !!privateData.connections ? privateData.connections : {};

  let connectionsWithDetails = {};

  const connectionsToDisplay = ['chatsWith', 'sellsTo', 'buysFrom', 'invites'];

  for (let [connectionTypeKey, connectionTypeValue] of Object.entries(connections)) {
    if (!connectionsToDisplay.includes(connectionTypeKey)) {
      continue;
    }
    connectionTypeValue = _.orderBy(connectionTypeValue, ['name'], ['desc']);

    connectionsWithDetails[connectionTypeKey] = [];
    for (const [connectionKey, connectionValue] of Object.entries(connectionTypeValue)) {
      const connectionId = connectionValue.id;

      const exisitsInResults = connectionsWithDetails[connectionTypeKey].find(
        connection => connection?.id?.uuid === connectionId
      );

      if (exisitsInResults) {
        continue;
      }

      await sdk.users
        .show({
          id: connectionId,
          include: ['profileImage'],
          'fields.image': ['variants.square-small', 'variants.square-small2x'],
        })
        .then(res => {
          const connectionDetails = res.data.data;
          const connectionIncludedRelationships = !!res.data.included ? res.data.included : {};

          for (const [relationshipKey, relationshipValue] of Object.entries(
            connectionIncludedRelationships
          )) {
            if (relationshipValue.type == 'image') {
              connectionDetails.profileImage = relationshipValue;
            }
          }
          connectionDetails.agreedRange = !!connectionValue.agreedRange
            ? connectionValue.agreedRange
            : {};

          connectionDetails.donations = !!connectionValue.donationProductId ? connectionValue : {};

          if (connectionTypeKey === 'invites' && !connectionValue.agreedRange) {
            return;
          } else {
            if (connectionTypeKey === 'invites') {
              console.log('adding invited');
            }
            connectionsWithDetails[connectionTypeKey].push(connectionDetails);
          }
        })
        .catch(err => {});
    }
  }

  dispatch(fetchCurrentUserConnectionsDetailsSuccess(connectionsWithDetails));
};

export const fetchCurrentUserActiveBaskets = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserActiveBasketsRequest());
  const { currentUser } = getState().user;
  const activeBasketsResponse = !!currentUser.attributes.profile.privateData.activeBaskets
    ? currentUser.attributes.profile.privateData.activeBaskets
    : [];

  if (!currentUser) {
    dispatch(fetchCurrentUserActiveBasketsSuccess(null));
    return Promise.resolve(null);
  }

  let activeBaskets = [];

  for (const [supplierID, transactionId] of Object.entries(activeBasketsResponse)) {
    activeBaskets[supplierID] = transactionId;
  }
  dispatch(fetchCurrentUserActiveBasketsSuccess(activeBaskets));
};

export const fetchCurrentUserActiveBasketsDetails = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserActiveBasketsDetailsRequest());
  const { currentUser } = getState().user;
  const activeBasketsDetailsResponse = !!currentUser.attributes.profile.privateData
    .activeBasketsDetails
    ? currentUser.attributes.profile.privateData.activeBasketsDetails
    : [];

  if (!currentUser) {
    dispatch(fetchCurrentUserActiveBasketsDetailsSuccess(null));
    return Promise.resolve(null);
  }

  let activeBasketsDetails = [];

  for (const [supplierID, details] of Object.entries(activeBasketsDetailsResponse)) {
    activeBasketsDetails[supplierID] = details;
  }
  dispatch(fetchCurrentUserActiveBasketsDetailsSuccess(activeBasketsDetails));
};

export const fetchCurrentUserPermissions = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserPermissionsRequest());
  const { currentUser } = getState().user;
  const currentUserPermissions = !!currentUser.attributes.profile.privateData.permissions
    ? currentUser.attributes.profile.privateData.permissions
    : {};

  if (!currentUser) {
    dispatch(fetchCurrentUserPermissionsSuccess(null));
    return Promise.resolve(null);
  }
  dispatch(fetchCurrentUserPermissionsSuccess(currentUserPermissions));
};

export const fetchCurrentUserHasOrders = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasOrdersRequest());

  if (!getState().user.currentUser) {
    dispatch(fetchCurrentUserHasOrdersSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    only: 'order',
    page: 1,
    per_page: 1,
  };

  return sdk.transactions
    .query(params)
    .then(response => {
      const hasOrders = response.data.data && response.data.data.length > 0;
      dispatch(fetchCurrentUserHasOrdersSuccess(!!hasOrders));
    })
    .catch(e => dispatch(fetchCurrentUserHasOrdersError(storableError(e))));
};

// Notificaiton page size is max (100 items on page)
const NOTIFICATION_PAGE_SIZE = 100;

export const fetchCurrentUserNotifications = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserNotificationsRequest());

  const apiQueryParams = {
    only: 'sale',
    last_transitions: transitionsToRequested,
    page: 1,
    per_page: NOTIFICATION_PAGE_SIZE,
  };

  sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      const transactions = response.data.data;
      dispatch(fetchCurrentUserNotificationsSuccess(transactions));
    })
    .catch(e => dispatch(fetchCurrentUserNotificationsError(storableError(e))));
};

export const fetchCurrentUser = (params = null) => (dispatch, getState, sdk) => {
  dispatch(currentUserShowRequest());
  const { isAuthenticated } = getState().Auth;

  if (!isAuthenticated) {
    // Make sure current user is null
    dispatch(currentUserShowSuccess(null));
    return Promise.resolve({});
  }

  const parameters = params || {
    include: ['profileImage'],
    'fields.image': [
      'variants.square-small',
      'variants.square-small2x',
      'variants.square-xsmall',
      'variants.square-xsmall2x',
    ],
    'imageVariant.square-xsmall': sdkUtil.objectQueryString({
      w: 40,
      h: 40,
      fit: 'crop',
    }),
    'imageVariant.square-xsmall2x': sdkUtil.objectQueryString({
      w: 80,
      h: 80,
      fit: 'crop',
    }),
  };

  return sdk.currentUser
    .show(parameters)
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.show response');
      }
      const currentUser = entities[0];

      // set current user id to the logger
      log.setUserId(currentUser.id.uuid);
      dispatch(currentUserShowSuccess(currentUser));
      return currentUser;
    })
    .then(currentUser => {
      dispatch(fetchCurrentUserHasListings());
      dispatch(fetchCurrentUserNotifications());
      // dispatch(fetchCurrentUserConnectionsDetails());
      dispatch(fetchCurrentUserActiveBaskets());
      dispatch(fetchCurrentUserActiveBasketsDetails());
      dispatch(fetchCurrentUserPermissions());
      dispatch(fetchCurrentUserConnectionsDetails());
      if (!currentUser.attributes.emailVerified) {
        dispatch(fetchCurrentUserHasOrders());
      }

      // Make sure auth info is up to date
      dispatch(authInfo());
    })
    .catch(e => {
      // Make sure auth info is up to date
      dispatch(authInfo());
      log.error(e, 'fetch-current-user-failed');
      dispatch(currentUserShowError(storableError(e)));
    });
};

export const sendVerificationEmail = () => (dispatch, getState, sdk) => {
  if (verificationSendingInProgress(getState())) {
    return Promise.reject(new Error('Verification email sending already in progress'));
  }
  dispatch(sendVerificationEmailRequest());
  return sdk.currentUser
    .sendVerificationEmail()
    .then(() => dispatch(sendVerificationEmailSuccess()))
    .catch(e => dispatch(sendVerificationEmailError(storableError(e))));
};

export const sendInvite = body => (dispatch, getState, sdk) => {
  if (inviteSendingInProgress(getState())) {
    return Promise.reject(new Error('Invite sending already in progress'));
  }

  dispatch(sendInviteRequest());
  sendInviteToNewConnection(body)
    // return sdk.currentUser
    //   .sendVerificationEmail()
    .then(() => dispatch(sendInviteSuccess()))
    .catch(e => {
      if (e.status === 403) {
        dispatch(inviteAlreadySentError());
        dispatch(sendInviteSuccess());
      } else {
        dispatch(sendInviteError(storableError(e)));
      }
    });
};

export const sendAccountReadyInvite = body => (dispatch, getState, sdk) => {
  if (inviteSendingInProgress(getState())) {
    return Promise.reject(new Error('Invite sending already in progress'));
  }
  dispatch(sendInviteRequest());
  sendAccountReadyInviteToNewConnection(body)
    // return sdk.currentUser
    //   .sendVerificationEmail()
    .then(() => window.location.reload(false))
    .catch(e => {
      if (e.status === 403) {
        dispatch(inviteAlreadySentError());
        dispatch(sendInviteSuccess());
      } else {
        dispatch(sendInviteError(storableError(e)));
      }
    });

  return;
};
