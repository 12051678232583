import React, { Component } from 'react';
import { string, bool, func } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { formatMoney } from '../../../util/currency';
import { FieldCheckbox } from '../../../components';

import {
  getSoldByMethod,
  getTotalWeightForWeightedProduct,
  getTotalPickedWeight,
  convertToHumanReadableKgString,
} from '../../../util/data';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { LINE_ITEMS, LINE_ITEM_LISTING, propTypes } from '../../../util/types';

import { Form, FieldTextInput, SecondaryButton, InlineTextButton } from '../..';

import css from './LineItemListingCustomerEditableForm.module.css';

const BLUR_TIMEOUT_MS = 100;
class LineItemListingEditableFormComponent extends Component {
  constructor(props) {
    super(props);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.blurTimeoutId = null;
  }

  handleFocus() {
    this.props.onFocus();
    window.clearTimeout(this.blurTimeoutId);
  }

  handleBlur() {
    // We only trigger a blur if another focus event doesn't come
    // within a timeout. This enables keeping the focus synced when
    // focus is switched between the message area and the submit
    // button.
    this.blurTimeoutId = window.setTimeout(() => {
      this.props.onBlur();
    }, BLUR_TIMEOUT_MS);
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            messagePlaceholder,
            handleSubmit,
            inProgress,
            sendMessageError,
            invalid,
            form,
            formId,
            lineItems,
            isProvider,
            intl,
            transaction,
          } = formRenderProps;

          // resolve unknown non-reversal line items
          const allItems = lineItems.filter(item => item.code.indexOf(LINE_ITEM_LISTING) > -1);

          const items = isProvider
            ? allItems.filter(item => item.includeFor.includes('provider'))
            : allItems.filter(item => item.includeFor.includes('customer'));

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = inProgress;
          const submitDisabled = invalid || submitInProgress;

          return (
            <div>
              <Form
                key="editLineItems"
                className={classes}
                onSubmit={values => handleSubmit(values, form)}
              >
                {items.map((item, i) => {
                  const quantity = item.quantity;
                  const listing = item.listing;
                  const soldByMethod = getSoldByMethod(item);
                  const formattedUnitPrice = formatMoney(intl, item.unitPrice);
                  const itemLabel = quantity && quantity >= 1 ? `${listing?.attributes.title}` : '';
                  const quantityLabel = quantity && quantity >= 1 ? `${quantity}` : '';

                  const convertWeightToOtherForDisplay = listing.attributes.publicData.pricing
                    .method?.convertWeightToOtherForDisplay
                    ? listing.attributes.publicData.pricing.method.convertWeightToOtherForDisplay
                    : false;

                  const quantityLabelDisplay = convertWeightToOtherForDisplay
                    ? Math.floor(
                        quantityLabel /
                          (listing.attributes.publicData.pricing.method.unitWeight / 1000)
                      )
                    : quantityLabel;
                  const unitType = !!listing.attributes?.publicData?.pricing?.method?.unitType
                    ? listing.attributes.publicData.pricing.method.unitType
                    : '-';

                  const formattedTotal = formatMoney(intl, item.lineTotal);
                  return (
                    <div key={`${i}-item.code`} className={css.lineItem}>
                      <div className={css.itemLabel}>
                        {itemLabel}
                        {soldByMethod.nickName == 'by-weight' ? (
                          <div>
                            <div className={css.lineItemSubLabel}>
                              <span className={css.lineItemOrderedWeight}>
                                <FormattedMessage id="TransactionPanel.orderedWeight" />
                              </span>
                              <span className={css.lineItemOrderedWeight}>
                                {' '}
                                -&nbsp;
                                {convertToHumanReadableKgString(
                                  getTotalWeightForWeightedProduct(transaction, item)
                                )}
                              </span>
                            </div>
                          </div>
                        ) : null}
                        <span className={css.itemLabel}>
                          <FieldCheckbox
                            id={`${i}-item.code`}
                            name={`${item.code}`}
                            label="Remove"
                            value="true"
                            dataCy="checkboxRemove"
                          />
                        </span>
                      </div>
                      <div className={css.itemQuantity}>{quantityLabelDisplay}</div>

                      <div className={css.itemUnits}>{unitType}</div>
                      <div className={css.itemUnitCost}>{formattedUnitPrice}</div>

                      <div>
                        <span className={css.itemValue}>{formattedTotal}</span>
                      </div>
                    </div>
                  );
                })}

                <InlineTextButton
                  rootClassName={css.removeButton}
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  onFocus={this.handleFocus}
                  onBlur={this.handleBlur}
                  data-cy="linkRemoveSelected"
                >
                  remove selected
                </InlineTextButton>
              </Form>
            </div>
          );
        }}
      />
    );
  }
}

LineItemListingEditableFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  messagePlaceholder: null,
  onFocus: () => null,
  onBlur: () => null,
  sendMessageError: null,
};

LineItemListingEditableFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,

  messagePlaceholder: string,
  onSubmit: func.isRequired,
  onFocus: func,
  onBlur: func,
  sendMessageError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const LineItemListingEditableForm = compose(injectIntl)(LineItemListingEditableFormComponent);

LineItemListingEditableForm.displayName = 'LineItemListingEditableForm';

export default LineItemListingEditableForm;
