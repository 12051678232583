import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import {convertToHumanReadableKgString} from '../../../util/data';
import { richText } from '../../../util/richText';

import css from '../ListingPage.module.css';
const MIN_LENGTH_FOR_LONG_WORDS = 10;
const Price = props => { 
  const {
    priceTitle,
    formattedPrice,
    formattedPricePerCase,
    formattedPricePerKG,
    formattedPricePerUnit,
    pricing,
    user,
  } = props;
  const soldByMethod = pricing.method.nickName;
  const isSoldByWeight = soldByMethod == 'by-weight'
  const isSoldByUnit = soldByMethod == 'by-unit'

  const unitType = !!pricing?.method?.unitType && pricing.method.unitType != '' ? pricing.method.unitType : null ;

  let soldByTanslationKey,
    MoQTranslationKey
  = null;
  let pricingMethod = {}

  if (isSoldByWeight) {
    soldByTanslationKey = 'ListingPage.byWeight';
    pricingMethod.weight = convertToHumanReadableKgString(pricing.method.unitWeight);
    MoQTranslationKey = 'ListingPage.byWieghtMoQ';
    pricingMethod.MoQ = pricing.method.MoQ;
  }

  if (isSoldByUnit) {
    soldByTanslationKey = 'ListingPage.byUnit';
    MoQTranslationKey = 'ListingPage.byUnitMoQ';
    pricingMethod.MoQ = pricing.method.MoQ;
  }


  if (unitType && unitType != '') {
    soldByTanslationKey = `ListingPage.per${unitType}`;
  }


  return (
    <div className={css.sectionHeading}>
      <div className={css.desktopPriceContainer}>
        <div className={css.desktopPriceValue} title={priceTitle} data-cy="productDetailsPagePriceContainer">
          {formattedPrice}
       
          <div className={css.desktopPerUnit} >
          <FormattedMessage id={soldByTanslationKey}  values={ pricingMethod }  />
        </div>
        </div>
        
        <div className={css.desktopMoQ}>
          <FormattedMessage id={MoQTranslationKey}  values={ pricingMethod } />
        </div>

        <div className={css.subTitle}>
            {richText(formattedPricePerCase, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
            {richText(formattedPricePerKG, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
            {richText(formattedPricePerUnit, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
        <div className={css.soldByHidden} data-cy="soldByMethod">
{ pricing.method.nickName }
          </div>
        

      </div>
    </div>
  );
};

export default Price;
