import { parse } from '../../util/urlHelpers';

import { claimAccount, claimAccountSetPassword } from '../../util/api';
import { googleChat } from '../../util/googleChat';

// ================ Action types ================ //

export const CLAIMACCOUNT_REQUEST = 'app/ClaimAccountPage/CLAIMACCOUNT_REQUEST';
export const CLAIMACCOUNT_SET_PASSWORD_IN_PROGRESS =
  'app/ClaimAccountPage/CLAIMACCOUNT_SET_PASSWORD_IN_PROGRESS';
export const CLAIMACCOUNT_SET_PASSWORD_SUCCESS =
  'app/ClaimAccountPage/CLAIMACCOUNT_SET_PASSWORD_SUCCESS';
export const CLAIMACCOUNT_SECURITY_CHECK = 'app/ClaimAccountPage/CLAIMACCOUNT_SECURITY_CHECK';
export const CLAIMACCOUNT_SECURITY_CHECK_ERROR =
  'app/ClaimAccountPage/CLAIMACCOUNT_SECURITY_CHECK_ERROR';
export const CLAIMACCOUNT_SECURITY_CHECK_SUCCESS =
  'app/ClaimAccountPage/CLAIMACCOUNT_SECURITY_CHECK_SUCCESS';
export const CLAIMACCOUNT_SUCCESS = 'app/ClaimAccountPage/CLAIMACCOUNT_SUCCESS';
export const CLAIMACCOUNT_ERROR = 'app/ClaimAccountPage/CLAIMACCOUNT_ERROR';
export const CLAIMACCOUNT_ALREADY_ACCEPTED_ERROR =
  'app/ClaimAccountPage/CLAIMACCOUNT_ALREADY_ACCEPTED_ERROR';

// ================ Reducer ================ //

const initialState = {
  claimAccountSecurityCheckInProgress: true,
  claimAccountSecurityCheckError: false,
  claimAccountSuccess: false,
  claimAccountSetPasswordInProgress: false,
  claimAccountSecurityCheckSuccess: true,
  claimAccountInProgress: false,
  orderAccepted: false,
  claimAccountError: false,
  claimAccountAlreadyAcceptedError: false,
  setPasswordInProgress: false,
  guest: { email: '' },
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CLAIMACCOUNT_SECURITY_CHECK:
      return {
        ...state,
        claimAccountSecurityCheckInProgress: true,
      };
    case CLAIMACCOUNT_SECURITY_CHECK_ERROR:
      return {
        ...state,
        claimAccountSecurityCheckInProgress: false,
        claimAccountSecurityCheckSuccess: false,
        claimAccountSecurityCheckError: true,
      };
    case CLAIMACCOUNT_SECURITY_CHECK_SUCCESS:
      return {
        ...state,
        claimAccountSecurityCheckInProgress: false,
        claimAccountSecurityCheckSuccess: true,
        ...payload,
      };

    case CLAIMACCOUNT_REQUEST:
      return {
        ...state,
        claimAccountInProgress: true,
      };

    case CLAIMACCOUNT_SET_PASSWORD_IN_PROGRESS:
      return {
        ...state,
        claimAccountSetPasswordInProgress: true,
      };
    case CLAIMACCOUNT_SET_PASSWORD_SUCCESS:
      return {
        ...state,
        claimAccountInProgress: false,
        claimAccountSuccess: true,
      };

    case CLAIMACCOUNT_SUCCESS:
      return {
        ...state,
        claimAccountInProgress: false,
        claimAccountSuccess: true,
      };

    case CLAIMACCOUNT_ERROR:
      return {
        ...state,
        claimAccountInProgress: false,
        claimAccountError: true,
      };

    case CLAIMACCOUNT_ALREADY_ACCEPTED_ERROR:
      return {
        ...state,
        claimAccountInProgress: false,
        claimAccountError: true,
        claimAccountAlreadyAcceptedError: true,
      };

    case CLAIMACCOUNT_REQUEST:
      return {
        ...state,
        claimAccountInProgress: true,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const claimAccountSecurityCheck = () => ({ type: CLAIMACCOUNT_SECURITY_CHECK });
export const claimAccountSecurityCheckError = () => ({ type: CLAIMACCOUNT_SECURITY_CHECK_ERROR });

export const claimAccountSecurityCheckSuccess = payload => ({
  type: CLAIMACCOUNT_SECURITY_CHECK_SUCCESS,
  payload,
});

export const claimAccountRequest = () => ({ type: CLAIMACCOUNT_REQUEST });
export const claimAccountSuccess = email => ({ type: CLAIMACCOUNT_SET_PASSWORD_SUCCESS });
export const claimAccountSetPasswordInProgress = email => ({
  type: CLAIMACCOUNT_SET_PASSWORD_IN_PROGRESS,
});
export const claimAccountError = email => ({ type: CLAIMACCOUNT_ERROR });
export const claimAccountAlreadyAcceptedError = email => ({
  type: CLAIMACCOUNT_ALREADY_ACCEPTED_ERROR,
});

// ================ Thunks ================ //

export const setPassword = (params, search) => async (dispatch, getState, sdk) => {
  dispatch(claimAccountSetPasswordInProgress());
  const payload = { ...params, a: getState().ClaimAccountPage.a };
  const claimAccountStatus = await claimAccountSetPassword(payload);
  if (claimAccountStatus.code === 'claim-success') {
    dispatch(claimAccountSuccess());
    googleChat('claimAccount.success', {
      ...claimAccountStatus.guest,
    });
  } else {
    dispatch(claimAccountSecurityCheckError());
  }
};

export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  if (typeof window !== 'undefined') {
    const claimAccountSecurityCheck = await claimAccount(parse(search));

    if (claimAccountSecurityCheck.code === 'valid_security_check') {
      dispatch(
        claimAccountSecurityCheckSuccess({
          ...parse(search),
          guest: claimAccountSecurityCheck.guest,
        })
      );
      googleChat('claimAccount.view', {
        ...claimAccountSecurityCheck.guest,
      });
    } else {
      console.log('error');
      dispatch(claimAccountSecurityCheckError());
    }
  }
};
