import React from 'react';
import { intlShape } from '../../util/reactIntl';

import LineItemListingSupplierEditableForm from './LineItemListingSupplierEditableForm/LineItemListingSupplierEditableForm';
import LineItemListingCustomerEditableForm from './LineItemListingCustomerEditableForm/LineItemListingCustomerEditableForm';

import { LINE_ITEM_LISTING, propTypes } from '../../util/types';

const LineItemListingMaybe = (props) => {
  const { lineItems, isProvider, isCustomer, formRenderProps } = props;
  const { handleSubmit } = formRenderProps;

  // resolve unknown non-reversal line items
  const allItems = lineItems.filter(
    (item) => item.code.indexOf(LINE_ITEM_LISTING) > -1
  );


  const items = isProvider
    ? allItems.filter((item) => item.includeFor.includes('provider'))
    : allItems.filter((item) => item.includeFor.includes('customer'));

  return isCustomer ? (
    <LineItemListingCustomerEditableForm onSubmit={handleSubmit} {...props} />
  ) : (
    <LineItemListingSupplierEditableForm onSubmit={handleSubmit} {...props} />
  );
};

LineItemListingMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemListingMaybe;
