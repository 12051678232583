import React, { useRef } from 'react';
import _ from 'lodash';
import { bool, func, number, string } from 'prop-types';
import { Form as FinalForm, FormSpy } from 'react-final-form';

import config from '../../../config';
import { FormattedMessage, useIntl } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { orderQuantityIncrements } from '../../../util/data';
import { numberAtLeast, required } from '../../../util/validators';
import Price from '../../../containers/ListingPage/ListingDetails/Price';
import {
  Form,
  FieldSelect,
  FieldTextInput,
  InlineTextButton,
  PrimaryButton,
} from '../../../components';

import EstimatedCustomerBreakdownMaybe from '../EstimatedCustomerBreakdownMaybe';

import css from './ProductOrderForm.module.css';

const renderForm = formRenderProps => {
  const {
    // FormRenderProps from final-form
    handleSubmit,
    form: formApi,

    // Custom props passed to the form component
    intl,
    formId,
    currentStock,
    hasMultipleDeliveryMethods,
    listingId,
    isOwnListing,
    onFetchTransactionLineItems,
    onContactUser,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    values,
    pricing,
    formattedPricePerCase,
    formattedPricePerUnit,
    formattedPricePerKG,
    formattedPrice,
    description,
    productSpecPdfUrl,
    productDescription,
  } = formRenderProps;
  const handleOnChange = formValues => {
    return;
    const { quantity: quantityRaw, deliveryMethod } = formValues.values;
    const quantity = Number.parseInt(quantityRaw, 10);
    const isBrowser = typeof window !== 'undefined';
    if (isBrowser && quantity && deliveryMethod && !fetchLineItemsInProgress) {
      onFetchTransactionLineItems({
        orderData: { quantity, deliveryMethod },
        listingId,
        isOwnListing,
      });
    }
  };

  // In case quantity and deliveryMethod are missing focus on that select-input.
  // Otherwise continue with the default handleSubmit function.
  const handleFormSubmit = e => {
    const { quantity, deliveryMethod } = values || {};
    if (!quantity || quantity < 1) {
      e.preventDefault();
      // Blur event will show validator message
      formApi.blur('quantity');
      formApi.focus('quantity');
    } else {
      handleSubmit(e);
    }
  };

  const showContactUser = typeof onContactUser === 'function';

  const onClickContactUser = e => {
    e.preventDefault();
    onContactUser();
  };

  const quantityRequiredMsg = intl.formatMessage({
    id: 'ProductOrderForm.quantityRequired',
  });

  const hasStock = currentStock && currentStock > 0;
  const unitType = pricing.method.convertWeightToOtherForDisplayName
    ? pricing.method.convertWeightToOtherForDisplayName.toLowerCase()
    : pricing.method.unitType.toLowerCase();

  const quantities = pricing.method.MoQ ? _.range(pricing.method.MoQ, currentStock) : [];

  const incrementQuantities = orderQuantityIncrements(pricing, currentStock);

  const hasNoStockLeft = typeof currentStock != null && currentStock === 0;
  const hasOneItemLeft = typeof currentStock != null && currentStock === 1;

  const submitInProgress = fetchLineItemsInProgress;
  const submitDisabled = !hasStock;

  const productSpecContent = productSpecPdfUrl ? (
    <div className={css.productSpecPdf}>
      <a href={productSpecPdfUrl} target="_blank">
        Product Spec PDF
        <img src="https://platter-product-assets-live.s3.eu-west-1.amazonaws.com/dl2.webp" />
      </a>
    </div>
  ) : null;

  return (
    <div>
      {!isOwnListing || isOwnListing ? (
        <Price
          formattedPrice={formattedPrice}
          pricing={pricing}
          formattedPricePerCase={formattedPricePerCase}
          formattedPricePerUnit={formattedPricePerUnit}
          formattedPricePerKG={formattedPricePerKG}
        />
      ) : (
        ''
      )}

      <div className={css.description}>{description}</div>
      {productSpecContent}
      {!isOwnListing ? (
        <Form onSubmit={handleFormSubmit}>
          <FormSpy subscription={{ values: true }} onChange={handleOnChange} />
          {hasNoStockLeft ? null : hasOneItemLeft ? (
            <FieldTextInput
              id={`${formId}.quantity`}
              className={css.quantityField}
              name="quantity"
              type="hidden"
              validate={numberAtLeast(quantityRequiredMsg, 1)}
            />
          ) : (
            <FieldSelect
              id={`${formId}.quantity`}
              className={css.quantityField}
              name="quantity"
              disabled={!hasStock}
              label={intl.formatMessage({ id: 'ProductOrderForm.quantityLabel' })}
              validate={numberAtLeast(quantityRequiredMsg, 1)}
            >
              <option disabled value="">
                {intl.formatMessage({
                  id: 'ProductOrderForm.selectQuantityOption',
                })}
              </option>
              {incrementQuantities.map(({ value, weight, displayValue }) => (
                <option key={value} value={value}>
                  {intl.formatMessage(
                    { id: 'ProductOrderForm.quantityOption' + unitType },
                    { displayValue, weight }
                  )}
                </option>
              ))}
            </FieldSelect>
          )}

          <div className={css.submitButton}>
            <PrimaryButton
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              data-cy="buttonAddToOrder"
            >
              {hasStock ? (
                <FormattedMessage id="ProductOrderForm.ctaButton" />
              ) : (
                <FormattedMessage id="ProductOrderForm.ctaButtonNoStock" />
              )}
            </PrimaryButton>
          </div>
        </Form>
      ) : (
        ''
      )}
      {productDescription != null ? (
        <div className={css.productDescription}>
          <h3>Product Description</h3>
          <p>{productDescription.split('.')[0]}.</p>
          <p>{productDescription.split('.')[1]}.</p>
          <p>{productDescription.split('.')[2]}.</p>
          <p>{productDescription.split('.')[3]}.</p>
        </div>
      ) : null}
    </div>
  );
};

const ProductOrderForm = props => {
  const intl = useIntl();
  const {
    price,
    currentStock,
    pickupEnabled,
    shippingEnabled,
    pricing,
    formattedPrice,
    formattedPricePerCase,
    formattedPricePerKG,
    formattedPricePerUnit,
  } = props;

  // Should not happen for listings that go through EditListingWizard.
  // However, this might happen for imported listings.
  if (!pickupEnabled && !shippingEnabled) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.noDeliveryMethodSet" />
      </p>
    );
  }

  if (!price) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.listingPriceMissing" />
      </p>
    );
  }

  const hasOneItemLeft = currentStock && currentStock === 1;
  const quantityMaybe = hasOneItemLeft ? { quantity: '1' } : {};
  const singleDeliveryMethodAvailableMaybe =
    shippingEnabled && !pickupEnabled
      ? { deliveryMethod: 'shipping' }
      : !shippingEnabled && pickupEnabled
      ? { deliveryMethod: 'pickup' }
      : {};
  const hasMultipleDeliveryMethods = pickupEnabled && shippingEnabled;
  const initialValues = {
    ...quantityMaybe,
    ...singleDeliveryMethodAvailableMaybe,
  };

  return (
    <FinalForm
      initialValues={initialValues}
      hasMultipleDeliveryMethods={hasMultipleDeliveryMethods}
      {...props}
      intl={intl}
      render={renderForm}
    />
  );
};

ProductOrderForm.defaultProps = {
  rootClassName: null,
  className: null,
  price: null,
  currentStock: null,
  listingId: null,
  isOwnListing: false,
  lineItems: null,
  fetchLineItemsError: null,
  pricing: null,
  formattedPrice: null,
  description: null,
};

ProductOrderForm.propTypes = {
  rootClassName: string,
  className: string,

  // form
  formId: string.isRequired,
  onSubmit: func.isRequired,

  // listing
  listingId: propTypes.uuid,
  price: propTypes.money,
  currentStock: number,
  isOwnListing: bool,

  // line items
  lineItems: propTypes.lineItems,
  onFetchTransactionLineItems: func.isRequired,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // other
  onContactUser: func,
};

export default ProductOrderForm;
