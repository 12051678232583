import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import config from '../../config';
import routeConfiguration from '../../routing/routeConfiguration';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { metaTagProps } from '../../util/seo';
import { canonicalRoutePath } from '../../util/routes';

import { CookieConsent } from '../../components';

import css from './Page.module.css';
import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider';

const preventDefault = e => {
  e.preventDefault();
};

class PageComponent extends Component {
  constructor(props) {
    super(props);
    // Keeping scrollPosition out of state reduces rendering cycles (and no bad states rendered)
    this.scrollPosition = 0;
    this.contentDiv = null;
    this.scrollingDisabledChanged = this.scrollingDisabledChanged.bind(this);
  }

  componentDidMount() {
    // By default a dropped file is loaded in the browser window as a
    // file URL. We want to prevent this since it might loose a lot of
    // data the user has typed but not yet saved. Preventing requires
    // handling both dragover and drop events.
    document.addEventListener('dragover', preventDefault);
    document.addEventListener('drop', preventDefault);

    // Remove duplicated server-side rendered page schema.
    // It's in <body> to improve initial rendering performance,
    // but after web app is initialized, react-helmet-async operates with <head>
    const pageSchema = document.getElementById('page-schema');
    if (pageSchema) {
      pageSchema.remove();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('dragover', preventDefault);
    document.removeEventListener('drop', preventDefault);
  }

  scrollingDisabledChanged(currentScrollingDisabled) {
    if (currentScrollingDisabled && currentScrollingDisabled !== this.scrollingDisabled) {
      // Update current scroll position, if scrolling is disabled (e.g. modal is open)
      this.scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      this.scrollingDisabled = currentScrollingDisabled;
    } else if (currentScrollingDisabled !== this.scrollingDisabled) {
      this.scrollingDisabled = currentScrollingDisabled;
    }
  }

  render() {
    const {
      className,
      rootClassName,
      children,
      location,
      intl,
      scrollingDisabled,
      referrer,
      author,
      contentType,
      description,
      published,
      schema,
      tags,
      title,
      updated,
      currentUser,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className, {
      [css.scrollingDisabled]: scrollingDisabled,
    });

    this.scrollingDisabledChanged(scrollingDisabled);
    const referrerMeta = referrer ? <meta name="referrer" content={referrer} /> : null;

    const canonicalRootURL = config.canonicalRootURL;
    const shouldReturnPathOnly = referrer && referrer !== 'unsafe-url';
    const canonicalPath = canonicalRoutePath(routeConfiguration(), location, shouldReturnPathOnly);
    const canonicalUrl = `${canonicalRootURL}${canonicalPath}`;

    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'Page.schemaTitle' }, { siteTitle });
    const schemaDescription = intl.formatMessage({
      id: 'Page.schemaDescription',
    });
    const metaTitle = title || schemaTitle;
    const metaDescription = description || schemaDescription;

    const metaToHead = metaTagProps({
      author,
      contentType,
      description: metaDescription,
      published,
      tags,
      title: metaTitle,
      updated,
      url: canonicalUrl,
      locale: intl.locale,
    });

    const scrollPositionStyles = scrollingDisabled
      ? { marginTop: `${-1 * this.scrollPosition}px` }
      : {};

    // If scrolling is not disabled, but content element has still scrollPosition set
    // in style attribute, we scrollTo scrollPosition.
    const hasMarginTopStyle = this.contentDiv && this.contentDiv.style.marginTop;
    if (!scrollingDisabled && hasMarginTopStyle) {
      window.requestAnimationFrame(() => {
        window.scrollTo(0, this.scrollPosition);
      });
    }
    const sendBirdAppId = process.env.REACT_APP_SEND_BIRD_APP_ID;
    const sendBirdUserId = currentUser?.attributes?.profile?.publicData?.sendBirdUserId
      ? currentUser.attributes.profile.publicData.sendBirdUserId
      : null;

    const sendBirdAccessToken = currentUser?.attributes?.profile?.privateData?.sendBirdAccessToken
      ? currentUser.attributes.profile.privateData.sendBirdAccessToken
      : null;

    if (sendBirdAppId && sendBirdUserId && sendBirdAccessToken) {
      return (
        <SendbirdProvider
          appId={sendBirdAppId}
          userId={sendBirdUserId}
          accessToken={sendBirdAccessToken}
        >
          <div className={classes}>
            <Helmet
              htmlAttributes={{
                lang: intl.locale,
              }}
            >
              <title>{title}</title>
              {referrerMeta}
              <link rel="canonical" href={canonicalUrl} />
              <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
              <meta httpEquiv="Content-Language" content={intl.locale} />
            </Helmet>
            <CookieConsent />
            <div
              className={css.content}
              style={scrollPositionStyles}
              ref={c => {
                this.contentDiv = c;
              }}
            >
              {children}
            </div>
          </div>
        </SendbirdProvider>
      );
    }

    return (
      <div className={classes}>
        <Helmet
          htmlAttributes={{
            lang: intl.locale,
          }}
        >
          <title>{title}</title>
          {referrerMeta}
          <link rel="canonical" href={canonicalUrl} />
          <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
          <meta httpEquiv="Content-Language" content={intl.locale} />
        </Helmet>
        <CookieConsent />
        <div
          className={css.content}
          style={scrollPositionStyles}
          ref={c => {
            this.contentDiv = c;
          }}
        >
          {children}
        </div>
      </div>
    );
  }
}

const { any, array, arrayOf, bool, func, number, object, oneOfType, shape, string } = PropTypes;

PageComponent.defaultProps = {
  className: null,
  rootClassName: null,
  children: null,
  author: null,
  contentType: 'website',
  description: null,
  published: null,
  referrer: null,
  schema: null,
  tags: null,
  updated: null,
  currentUser: {},
};

PageComponent.propTypes = {
  className: string,
  rootClassName: string,
  children: any,
  scrollingDisabled: bool.isRequired,

  // Handle referrer policy
  referrer: string,

  // SEO related props
  author: string,
  contentType: string, // og:type
  description: string, // page description

  published: string, // article:published_time
  schema: oneOfType([object, array]), // http://schema.org
  tags: string, // article:tag
  title: string.isRequired, // page title
  updated: string, // article:modified_time

  // from withRouter
  history: shape({
    listen: func.isRequired,
  }).isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser,
  };
};

const Page = compose(withRouter, connect(mapStateToProps), injectIntl)(PageComponent);

export default Page;
