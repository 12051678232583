/**
 * Renders non-reversal line items that are not listed in the
 * `LINE_ITEMS` array in util/types.js
 *
 * The line items are rendered so that the line item code is formatted to human
 * readable form and the line total is printed as price.
 *
 * If you require another kind of presentation for your line items, add them to
 * the `LINE_ITEMS` array in util/types.js and create a specific line item
 * component for them that can be used in the `OrderBreakdown` component.
 */
import React from 'react';
import { intlShape, FormattedMessage } from '../../util/reactIntl';

import { formatMoney } from '../../util/currency';
import {
  getSoldByMethod,
  getTotalWeightForWeightedProduct,
  getTitleWithSoldByMethodFromLineItem,
  convertToHumanReadableKgString,
  getTotalPickedWeight,
} from '../../util/data';
import { LINE_ITEM_LISTING, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemListingMaybe = props => {
  const { lineItems, isProvider, transaction, intl } = props;

  // resolve unknown non-reversal line items
  const allItems = lineItems.filter(item => item.code.indexOf(LINE_ITEM_LISTING) > -1);

  const items = isProvider
    ? allItems.filter(item => item.includeFor.includes('provider'))
    : allItems.filter(item => item.includeFor.includes('customer'));
  return items.length > 0 ? (
    <React.Fragment>
      {items.map((item, i) => {
        const quantity = item.quantity;
        const listing = item.listing;
        const soldByMethod = getSoldByMethod(item);
        const formattedUnitPrice = formatMoney(intl, item.unitPrice);

        const itemLabel = quantity && quantity > 0 ? `${listing?.attributes.title}` : '';

        const quantityLabel = quantity && quantity > 0 ? `${quantity}` : '';

        const convertWeightToOtherForDisplay = listing.attributes.publicData.pricing.method
          ?.convertWeightToOtherForDisplay
          ? listing.attributes.publicData.pricing.method.convertWeightToOtherForDisplay
          : false;

        const quantityLabelDisplay = convertWeightToOtherForDisplay
          ? Math.floor(
              quantityLabel / (listing.attributes.publicData.pricing.method.unitWeight / 1000)
            )
          : quantityLabel;

        const formattedTotal = formatMoney(intl, item.lineTotal);
        const pickedWeight = getTotalPickedWeight(transaction, item);

        const unitType = !!listing.attributes?.publicData?.pricing?.method?.unitType
          ? listing.attributes.publicData.pricing.method.unitType
          : '-';

        return (
          <div key={`${i}-item.code`} className={css.lineItem}>
            <div className={css.itemLabel}>
              {itemLabel}

              {soldByMethod.nickName == 'by-weight' ? (
                <div>
                  <div>
                    <div className={css.lineItemOrderedWeight}>
                      {' '}
                      <span className={css.lineItemOrderedWeight}>
                        <FormattedMessage id="TransactionPanel.orderedWeight" />
                      </span>
                      <span className={css.lineItemOrderedWeight}>
                        {' '}
                        - &nbsp;
                        {convertToHumanReadableKgString(
                          getTotalWeightForWeightedProduct(transaction, item)
                        )}
                      </span>
                    </div>
                    {pickedWeight ? (
                      <div>
                        <span className={css.lineItemOrderedWeight}>
                          <FormattedMessage id="TransactionPanel.pickedWeight" />
                        </span>
                        <span className={css.lineItemOrderedWeight}>
                          {' '}
                          - &nbsp;
                          {convertToHumanReadableKgString(pickedWeight)}
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
            <div className={css.itemQuantity}>{quantityLabelDisplay}</div>
            <div className={css.itemUnits}>{unitType}</div>
            <div className={css.itemUnitCost}>{formattedUnitPrice}</div>

            <div className={css.itemValue}>{formattedTotal}</div>
          </div>
        );
      })}
    </React.Fragment>
  ) : null;
};

LineItemListingMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemListingMaybe;
