import React from 'react';
import { useHistory } from 'react-router-dom';

import css from './GoBackLink.module.css';

const GoBackLink = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const referer = urlParams.get('ref') ? urlParams.get('ref') : null;

  const hide = referer && referer == 'login' ? true : false;

  const history = useHistory();
  if (!hide) {
    return (
      <button className={css.goback} onClick={() => history.goBack()}>
        &larr; Go Back
      </button>
    );
  } else {
    return   <button className={css.gobackInactive} onClick={() =>  {}}>
    &larr; Go Back
  </button>;
  }
};

GoBackLink.defaultProps = {};

GoBackLink.propTypes = {};

export default GoBackLink;
