import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';

const TermsOfService = (props) => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: Jan, 12th 2023</p>




      <h1>Welcome to Platter!</h1>
<p>These terms of service (the "Terms") govern your use of Platter, a software as a service (SaaS) startup based in the United Kingdom (the "Service"). By using the Service, you agree to be bound by these Terms. If you do not agree to these Terms, you may not use the Service.</p>

<h2>Use of the Service</h2>
<p>You may use the Service only for lawful purposes and in accordance with these Terms. You may not use the Service for any illegal or unauthorized purpose. You may not use the Service to transmit any viruses, malware, or other harmful code or content. You may not use the Service to harass or harm other users.</p>

<h2>Accounts</h2>
<p>In order to use the Service, you must create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. You agree to immediately notify Platter of any unauthorized use of your account or any other breach of security.</p>

<h2>Content</h2>
<p>You are solely responsible for the content that you upload or otherwise transmit to or through the Service. You agree not to upload or transmit any content that is illegal, offensive, or harmful. You agree not to upload or transmit any content that infringes on the rights of any third party, including intellectual property rights.</p>

<h2>Platter's Intellectual Property</h2>
<p>The Service and all intellectual property rights associated with it, including but not limited to patents, trademarks, trade secrets, and copyrights, are the property of Platter and are protected by applicable laws and regulations. You may not use the Service or any of its intellectual property for any purpose other than as expressly permitted by these Terms.</p>

<h2>Limitations of Liability</h2>
<p>PLATTER SHALL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND, INCLUDING BUT NOT LIMITED TO, DIRECT, INDIRECT, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SERVICE, WHETHER OR NOT PLATTER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>

<h2>Changes to the Service and these Terms</h2>
<p>Platter reserves the right to make changes to the Service and these Terms at any time. If we make changes to these Terms, we will provide notice of the changes by posting an updated version of the Terms on our website. Your continued use of the Service following the posting of changes to these Terms constitutes your acceptance of those changes.</p>

<h2>Governing Law</h2>
<p>These Terms and your use of the Service shall be governed by and construed in accordance with the laws of the United Kingdom.</p>

<h2>Contact Us</h2>
<p>If you have any questions about these Terms or the Service, please contact us at support@platter.co.uk.</p>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
