import { parse } from '../../util/urlHelpers';

import { oneClickMessageSend, oneClickMessage } from '../../util/api';
import { googleChat } from '../../util/googleChat';

// ================ Action types ================ //

export const SEND_MESSAGE_REQUEST = 'app/OneClickMessagePage/SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_IN_PROGRESS = 'app/OneClickMessagePage/SEND_MESSAGE_IN_PROGRESS';
export const SEND_MESSAGE_SUCCESS = 'app/OneClickMessagePage/SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_SECURITY_CHECK = 'app/OneClickMessagePage/SEND_MESSAGE_SECURITY_CHECK';
export const SEND_MESSAGE_SECURITY_CHECK_ERROR =
  'app/OneClickMessagePage/SEND_MESSAGE_SECURITY_CHECK_ERROR';
export const SEND_MESSAGE_SECURITY_CHECK_SUCCESS =
  'app/OneClickMessagePage/CLAIMACCOUNT_SECURITY_CHECK_SUCCESS';
export const SEND_MESSAGE_ERROR = 'app/OneClickMessagePage/SEND_MESSAGE_ERROR';
export const SEND_MESSAGE_ALREADY_ACCEPTED_ERROR =
  'app/OneClickMessagePage/SEND_MESSAGE_ALREADY_ACCEPTED_ERROR';
export const ADD_MESSAGE_FILE_PENDING_SUCCESS =
  'app/TransactionPage/ADD_MESSAGE_FILE_PENDING_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  sendMessageSecurityCheckInProgress: true,
  sendMessageSecurityCheckError: false,
  sendMessageSuccess: false,
  sendMessageInProgress: false,
  sendMessageSecurityCheckSuccess: true,
  messageSent: false,
  sendMessageError: false,
  guest: { email: '' },
  buyerDisplayName: '',
  addMessageFilePendingSuccess: false,
  addMessageFilePending: false,
  addMessageFilePendingSuccess: false,
  addMessageFilePendingFilename: null,
  addMessageFilePendingFilecontents: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SEND_MESSAGE_SECURITY_CHECK:
      return {
        ...state,
        sendMessageSecurityCheckInProgress: true,
      };
    case SEND_MESSAGE_SECURITY_CHECK_ERROR:
      return {
        ...state,
        sendMessageSecurityCheckInProgress: false,
        sendMessageSecurityCheckSuccess: false,
        sendMessageSecurityCheckError: true,
      };
    case SEND_MESSAGE_SECURITY_CHECK_SUCCESS:
      return {
        ...state,
        sendMessageSecurityCheckInProgress: false,
        sendMessageSecurityCheckSuccess: true,
        ...payload,
      };

    case SEND_MESSAGE_REQUEST:
      return {
        ...state,
        sendMessageInProgress: true,
      };
    case ADD_MESSAGE_FILE_PENDING_SUCCESS:
      return {
        ...state,

        addMessageFilePending: true,
        addMessageFilePendingFilename: payload.addMessageFilePendingFilename,
        addMessageFilePendingFilecontents: payload.addMessageFilePendingFilecontents,
      };

    case SEND_MESSAGE_IN_PROGRESS:
      return {
        ...state,
        sendMessageInProgress: true,
      };
    case SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        sendMessageInProgress: false,
        sendMessageSuccess: true,
      };

    case SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        sendMessageInProgress: false,
        sendMessageSuccess: true,
      };

    case SEND_MESSAGE_ERROR:
      return {
        ...state,
        sendMessageInProgress: false,
        sendMessageError: true,
      };

    case SEND_MESSAGE_REQUEST:
      return {
        ...state,
        sendMessageInProgress: true,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const sendMessageSecurityCheck = () => ({ type: SEND_MESSAGE_SECURITY_CHECK });
export const sendMessageSecurityCheckError = () => ({ type: SEND_MESSAGE_SECURITY_CHECK_ERROR });

export const sendMessageSecurityCheckSuccess = payload => ({
  type: SEND_MESSAGE_SECURITY_CHECK_SUCCESS,
  payload,
});

export const sendMessageRequest = () => ({ type: SEND_MESSAGE_REQUEST });
export const sendMessageSuccess = email => ({ type: SEND_MESSAGE_SUCCESS });
export const sendMessageInProgress = email => ({
  type: SEND_MESSAGE_IN_PROGRESS,
});
export const sendMessageError = email => ({ type: SEND_MESSAGE_ERROR });
export const sendMessageAlreadyAcceptedError = email => ({
  type: SEND_MESSAGE_ALREADY_ACCEPTED_ERROR,
});

const addMessageFilePendingSuccess = e => ({
  type: ADD_MESSAGE_FILE_PENDING_SUCCESS,
  payload: e,
});

// ================ Thunks ================ //

export const addMessageFilePending = pdf => (dispatch, getState, sdk) => {
  const state = getState().OnceClickMessagePage;

  const pdfPending = {
    addMessageFilePending: true,
    addMessageFilePendingFilename: 'messagePdf',
    addMessageFilePendingFilecontents: pdf,
  };
  dispatch(addMessageFilePendingSuccess(pdfPending));
};

export const sendMessage = (params, search) => async (dispatch, getState, sdk) => {
  dispatch(sendMessageInProgress());
  const { message = '-', messageTitle = 'PDF' } = params;
  const state = getState().OneClickMessagePage;
  const formData = new FormData();
  formData.append('messageTitle', messageTitle);
  formData.append('message', message);
  formData.append('id', getState().OneClickMessagePage.id);

  if (state.addMessageFilePending) {
    formData.append('messagePdf', state.addMessageFilePendingFilecontents, 'messagePdf');
  }

  const payload = { formData };

  const sendMessageStatus = await oneClickMessageSend({ transactionId: 'txId', formData });

  if (sendMessageStatus.code === 'message-sent') {
    dispatch(sendMessageSuccess());
    googleChat('sendMessage.success', {
      ...sendMessageStatus.guest,
    });
  } else {
    dispatch(sendMessageSecurityCheckError());
  }
};

export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  if (typeof window !== 'undefined') {
    const sendMessageSecurityCheck = await oneClickMessage(parse(search));

    if (sendMessageSecurityCheck.code === 'valid_security_check') {
      dispatch(
        sendMessageSecurityCheckSuccess({
          ...parse(search),
          guest: sendMessageSecurityCheck.guest,
          buyerDisplayName: sendMessageSecurityCheck.buyerDisplayName,
        })
      );
      googleChat('sendMessage.view', {
        ...sendMessageSecurityCheck.guest,
      });
    } else {
      console.log('error');
      dispatch(sendMessageSecurityCheckError());
    }
  }
};
