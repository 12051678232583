export const googleChat = async function googleChat(type, msg) {
  const url = process.env.REACT_APP_GOOGLE_CHAT_WEBHOOK;

  const res = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json; charset=UTF-8' },
    body: JSON.stringify({
      cardsV2: [
        {
          cardId: 'createCardMessage',
          card: {
            header: getHeader(type, msg),
            sections: [
              {
                collapsible: true,
                uncollapsibleWidgetsCount: 1,
                widgets: [getWidgets(type, msg)],
              },
            ],
          },
        },
      ],
    }),
  });

  return await res.json();
};

const getHeader = (type, msg) => {
  if (['user-visit.invited'].includes(type)) {
    return {
      title: 'User Visited Invite Page',
    };
  }
  if (['user-action.login'].includes(type)) {
    return {
      title: 'User Logged In',
    };
  }
  if (['user-action.signup'].includes(type)) {
    return {
      title: 'User Signup',
    };
  }

  if (['user-action.signup-by-invite'].includes(type)) {
    return {
      title: 'User Signup From Invite',
    };
  }

  if (['oneclick.success'].includes(type)) {
    return {
      title: 'Order Accepted With One Click',
    };
  }
  if (['claimAccount.success'].includes(type)) {
    return {
      title: 'Guest claimed account',
    };
  }
  if (['claimAccount.view'].includes(type)) {
    return {
      title: 'Guest account page viewed with valid claim',
    };
  }
};

const getText = (type, msg) => {
  if (['user-visit.invited'].includes(type)) {
    return `A user visited the invited page:<br>Invited company <b>${msg.invitedEmail}</b><br>Invited by <b>${msg.refererEmail}</b>`;
  }

  if (['user-action.login'].includes(type)) {
    return `A user logged in! <b>${msg.username}</b>`;
  }

  if (['user-action.signup'].includes(type)) {
    return `A user signed up! <b>${msg.firstName} ${msg.lastName}</b><br><b>${msg.email}</b>`;
  }

  if (['user-action.signup-by-invite'].includes(type)) {
    return `An <b>invited</b> user signed up! <br><b>${msg.firstName} ${msg.lastName}</b> <br><b>${msg.email}<br><b>${msg.invitedCompanyName}</b>`;
  }

  if (['oneclick.success'].includes(type)) {
    return `An <b>order</b> has been accepted with a one - click <b>${msg.transactionId}</b>`;
  }

  if (['claimAccount.success'].includes(type)) {
    return `A guest user claimed their account <b>${msg.email}</b>`;
  }
  if (['claimAccount.view'].includes(type)) {
    return `A guest user viewed the claim account page with a valid claim <b>${msg.email}</b>`;
  }
};

const getWidgets = (type, msg) => {
  if (
    [
      'user-visit.invited',
      'user-action.login',
      'user-action.signup-by-invite',
      'user-action.signup',
      'oneclick.success',
      'claimAccount.success',
      'claimAccount.view',
    ].includes(type)
  ) {
    return {
      textParagraph: {
        text: getText(type, msg),
      },
    };
  }
};
