import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './TabNav.module.css';

const Tab = props => {
  const {
    className,
    id,
    disabled,
    text,
    selected,
    linkProps,
    notificationDot,
    handleClick,
  } = props;
  const linkClasses = classNames(css.link, {
    [css.selectedLink]: selected,
    [css.disabled]: disabled,
  });

  const searchString = linkProps.params?.search ? linkProps.params.search : '';
  const linkTestForCyDataId = linkProps.params?.dataCyId ? linkProps.params.dataCyId : '';

  const cyDataId = !!linkProps?.params?.tab
    ? `sideTab-${linkProps.params.tab}-${linkTestForCyDataId}`
    : `sideTab-`;

  const rowNotificationDot = notificationDot ? <div className={css.notificationDot} /> : null;

  return handleClick ? (
    <div id={id} className={className} data-cy={cyDataId}>
      <a onClick={handleClick} className={linkClasses} {...linkProps}>
        {text}
      </a>
      {rowNotificationDot}
    </div>
  ) : (
    <div id={id} className={className} data-cy={cyDataId}>
      <NamedLink className={linkClasses} {...linkProps}>
        {text}
      </NamedLink>
      {rowNotificationDot}
    </div>
  );
};

Tab.defaultProps = { className: null, disabled: false, selected: false };

const { arrayOf, bool, node, object, string } = PropTypes;

Tab.propTypes = {
  id: string.isRequired,
  className: string,
  text: node.isRequired,
  disabled: bool,
  selected: bool,
  linkProps: object.isRequired,
};

const TabNav = props => {
  const { className, rootClassName, tabRootClassName, tabs } = props;
  const classes = classNames(rootClassName || css.root, className);
  const tabClasses = tabRootClassName || css.tab;

  const [subNavVisible, setSubNavVisible] = useState(() => {
    if (typeof window !== 'undefined') {
      const savedSubNavVisible = JSON.parse(localStorage.getItem('subNavVisible'));
      return savedSubNavVisible || Array(3).fill(true);
    }
  });

  const toggleSubNav = index => {
    setSubNavVisible(prev => {
      const newSubNavVisible = [...(prev || [])];
      newSubNavVisible[index] = !newSubNavVisible[index];
      localStorage.setItem('subNavVisible', JSON.stringify(newSubNavVisible));

      return newSubNavVisible;
    });
  };

  return (
    <nav className={classes}>
      {tabs.map((tab, index) => {
        const id = typeof tab.id === 'string' ? tab.id : `${index}`;
        const subNav = tab.subNav;
        const showSubNavClasses = classNames(css.subNavTabs, css.show);
        const hideSubNavClasses = classNames(css.subNavTabs, css.hide);

        if (subNav) {
          const visible =
            subNavVisible[index] === undefined ? toggleSubNav(index) : subNavVisible[index];
          const toggleClassNames = visible
            ? classNames(css.navToggle, css.hide)
            : classNames(css.navToggle, css.show);
          return (
            <div key={id} id={id} className={tabClasses}>
              <div className={css.subNav}>
                <Tab key={id} id={id} className={tabClasses} {...tab} />
                <button onClick={() => toggleSubNav(index)} className={toggleClassNames}></button>

                <div className={visible ? showSubNavClasses : hideSubNavClasses}>
                  {subNav.map((subTab, subIndex) => {
                    const subId = typeof subTab.id === 'string' ? subTab.id : `${subIndex}`;

                    return subTab?.visible !== false ? (
                      <span>
                        <Tab
                          key={Math.floor(Math.random() * 1000000)}
                          id={subId}
                          className={tabClasses}
                          {...subTab}
                        />
                      </span>
                    ) : (
                      ''
                    );
                  })}
                </div>
              </div>
            </div>
          );
        }

        return <Tab key={id} id={id} className={tabClasses} {...tab} />;
      })}
    </nav>
  );
};

TabNav.defaultProps = {
  className: null,
  rootClassName: null,
  tabRootClassName: null,
  tabClassName: null,
};

TabNav.propTypes = {
  className: string,
  rootClassName: string,
  tabRootClassName: string,
  tabs: arrayOf(object).isRequired,
};

export default TabNav;
