import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';

import config from '../../config';
import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import {
  sendToSupplierPrivileged,
  orderApproved,
  onHoldOrderApproved,
  donationAccepted,
  donationAcceptedAfterRejected,
  donationRejected,
  donationReceived,
  transactionLineItems,
  updateLineItemsAfterPicking,
  removeLineItemsApi,
  getTransaction,
  addPurchaseOrderNumberApi,
  addPurchaseOrderNumberAndFileApi,
  addTransportDetailsWithDeliveryNoteApi,
  addInvoiceNumberApi,
  addInvoiceNumberAndFileApi,
  addDeliveryDateApi,
  customerAddDeliveryDateApi,
  addSupplierOrderNumberApi,
  addMessageAndFileApi,
  placeOnHoldApi,
  cancelApi,
  orderMarkedAsPicked,
  orderMarkedAsDispatched,
  orderBookHaulage,
} from '../../util/api';
import { formatDateIntoPartials } from '../../util/dates';
import { storableError } from '../../util/errors';
import {
  TRANSITION_PURCHASED_PAYMENT_BY_INVOICE,
  TRANSITION_SUPPLIER_ACCEPTS_ORDER,
  TRANSITION_REMOVE_ON_HOLD,
  TRANSITION_MARK_AS_PICKED,
  TRANSITION_GO_BACK_TO_ACCEPTED,
  TRANSITION_GO_BACK_TO_SUBMITTED,
  TRANSITION_GO_BACK_TO_READY_FOR_DISPATCH,
  TRANSITION_MARK_AS_DISPATCHED,
  TRANSITION_SUPLIER_REQUESTS_HAULAGE,
  TRANSITION_MARK_AS_DELIVERED,
} from '../../util/transaction';

import {
  TRANSITION_DONEE_ACCEPTS_OFFER,
  TRANSITION_DONEE_ACCEPTS_OFFER_AFTER_REJECTED,
  TRANSITION_DONEE_REJECTS_OFFER,
  TRANSITION_SEND_NEW_DONATION,
  TRANSITION_DONEE_MARKS_AS_RECEIVED,
} from '../../util/donation';

import * as log from '../../util/log';
import {
  updatedEntities,
  denormalisedEntities,
  denormalisedResponseEntities,
} from '../../util/data';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUserNotifications } from '../../ducks/user.duck';
import { findConfigForSelectFilter } from '../../util/search';

const { UUID } = sdkTypes;

const MESSAGES_PAGE_SIZE = 100;
const CUSTOMER = 'customer';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/TransactionPage/SET_INITIAL_VALUES';

export const FETCH_TRANSACTION_REQUEST = 'app/TransactionPage/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS = 'app/TransactionPage/FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_ERROR = 'app/TransactionPage/FETCH_TRANSACTION_ERROR';

export const FETCH_TRANSITIONS_REQUEST = 'app/TransactionPage/FETCH_TRANSITIONS_REQUEST';
export const FETCH_TRANSITIONS_SUCCESS = 'app/TransactionPage/FETCH_TRANSITIONS_SUCCESS';
export const FETCH_TRANSITIONS_ERROR = 'app/TransactionPage/FETCH_TRANSITIONS_ERROR';

export const MARK_DELIVERED_REQUEST = 'app/TransactionPage/MARK_DELIVERED_REQUEST';
export const MARK_DELIVERED_SUCCESS = 'app/TransactionPage/MARK_DELIVERED_SUCCESS';
export const MARK_DELIVERED_ERROR = 'app/TransactionPage/MARK_DELIVERED_ERROR';

export const MARK_RECEIVED_REQUEST = 'app/TransactionPage/MARK_RECEIVED_REQUEST';
export const MARK_RECEIVED_SUCCESS = 'app/TransactionPage/MARK_RECEIVED_SUCCESS';
export const MARK_RECEIVED_ERROR = 'app/TransactionPage/MARK_RECEIVED_ERROR';

export const PURCHASED_PAYMENT_BY_INVOICE_REQUEST =
  'app/TransactionPage/PURCHASED_PAYMENT_BY_INVOICE_REQUEST';
export const PURCHASED_PAYMENT_BY_INVOICE_SUCCESS =
  'app/TransactionPage/PURCHASED_PAYMENT_BY_INVOICE_SUCCESS';
export const PURCHASED_PAYMENT_BY_INVOICE_ERROR =
  'app/TransactionPage/PURCHASED_PAYMENT_BY_INVOICE_ERROR';

export const DONEE_ACCEPTS_DONATION_REQUEST = 'app/TransactionPage/DONEE_ACCEPTS_DONATION_REQUEST';
export const DONEE_ACCEPTS_DONATION_SUCCESS = 'app/TransactionPage/DONEE_ACCEPTS_DONATION_SUCCESS';
export const DONEE_ACCEPTS_DONATION_ERROR = 'app/TransactionPage/DONEE_ACCEPTS_DONATION_ERROR';

export const DONEE_ACCEPTS_DONATION_AFTER_REJECTED_REQUEST =
  'app/TransactionPage/DONEE_ACCEPTS_DONATION_AFTER_REJECTED_REQUEST';
export const DONEE_ACCEPTS_DONATION_AFTER_REJECTED_SUCCESS =
  'app/TransactionPage/DONEE_ACCEPTS_DONATION_AFTER_REJECTED_SUCCESS';
export const DONEE_ACCEPTS_DONATION_AFTER_REJECTED_ERROR =
  'app/TransactionPage/DONEE_ACCEPTS_DONATION_AFTER_REJECTED_ERROR';

export const DONEE_REJECTS_DONATION_REQUEST = 'app/TransactionPage/DONEE_ACCEPTS_REJECTS_REQUEST';
export const DONEE_REJECTS_DONATION_SUCCESS = 'app/TransactionPage/DONEE_ACCEPTS_REJECTS_SUCCESS';
export const DONEE_REJECTS_DONATION_ERROR = 'app/TransactionPage/DONEE_ACCEPTS_REJECTS_ERROR';

export const DONEE_MARKS_AS_RECEIVED_REQUEST =
  'app/TransactionPage/DONEE_MARKS_AS_RECEIVED_REQUEST';
export const DONEE_MARKS_AS_RECEIVED_SUCCESS =
  'app/TransactionPage/DONEE_MARKS_AS_RECEIVED_SUCCESS';
export const DONEE_MARKS_AS_RECEIVED_ERROR = 'app/TransactionPage/DONEE_MARKS_AS_RECEIVED_ERROR';

export const ORDER_APPROVED_BY_SUPPLIER_REQUEST =
  'app/TransactionPage/ORDER_APPROVED_BY_SUPPLIER_REQUEST';
export const ORDER_APPROVED_BY_SUPPLIER_SUCCESS =
  'app/TransactionPage/ORDER_APPROVED_BY_SUPPLIER_SUCCESS';
export const ORDER_APPROVED_BY_SUPPLIER_ERROR =
  'app/TransactionPage/ORDER_APPROVED_BY_SUPPLIER_ERROR';

export const ORDER_PICKED_BY_SUPPLIER_REQUEST =
  'app/TransactionPage/ORDER_PICKED_BY_SUPPLIER_REQUEST';
export const ORDER_PICKED_BY_SUPPLIER_SUCCESS =
  'app/TransactionPage/ORDER_PICKED_BY_SUPPLIER_SUCCESS';
export const ORDER_PICKED_BY_SUPPLIER_ERROR = 'app/TransactionPage/ORDER_PICKED_BY_SUPPLIER_ERROR';

export const GO_BACK_TO_ACCEPTED_REQUEST = 'app/TransactionPage/GO_BACK_TO_ACCEPTED_REQUEST';
export const GO_BACK_TO_ACCEPTED_SUCCESS = 'app/TransactionPage/GO_BACK_TO_ACCEPTED_SUCCESS';
export const GO_BACK_TO_ACCEPTED_ERROR = 'app/TransactionPage/GO_BACK_TO_ACCEPTED_ERROR';

export const LAUNCH_ON_HOLD_MODAL = 'app/TransactionPage/LAUNCH_ON_HOLD_MODAL';
export const CLOSE_ON_HOLD_MODAL = 'app/TransactionPage/CLOSE_ON_HOLD_MODAL';

export const LAUNCH_CANCEL_MODAL = 'app/TransactionPage/LAUNCH_CANCEL_MODAL';
export const CLOSE_CANCEL_MODAL = 'app/TransactionPage/CLOSE_CANCEL_MODAL';

export const GO_BACK_TO_SUBMITTED_REQUEST = 'app/TransactionPage/GO_BACK_TO_SUBMITTED_REQUEST';
export const GO_BACK_TO_SUBMITTED_SUCCESS = 'app/TransactionPage/GO_BACK_TO_SUBMITTED_SUCCESS';
export const GO_BACK_TO_SUBMITTED_ERROR = 'app/TransactionPage/GO_BACK_TO_SUBMITTED_ERROR';

export const GO_BACK_TO_READY_FOR_DISPATCH_REQUEST =
  'app/TransactionPage/GO_BACK_TO_READY_FOR_DISPATCH_REQUEST';
export const GO_BACK_TO_READY_FOR_DISPATCH_SUCCESS =
  'app/TransactionPage/GO_BACK_TO_READY_FOR_DISPATCH_SUCCESS';
export const GO_BACK_TO_READY_FOR_DISPATCH_ERROR =
  'app/TransactionPage/GO_BACK_TO_READY_FOR_DISPATCH_ERROR';

export const ORDER_DISPATCHED_BY_SUPPLIER_REQUEST =
  'app/TransactionPage/ORDER_DISPATCHED_BY_SUPPLIER_REQUEST';
export const ORDER_DISPATCHED_BY_SUPPLIER_SUCCESS =
  'app/TransactionPage/ORDER_DISPATCHED_BY_SUPPLIER_SUCCESS';
export const ORDER_DISPATCHED_BY_SUPPLIER_ERROR =
  'app/TransactionPage/ORDER_DISPATCHED_BY_SUPPLIER_ERROR';

export const BOOK_HAULAGE_BY_SUPPLIER_REQUEST =
  'app/TransactionPage/BOOK_HAULAGE_BY_SUPPLIER_REQUEST';
export const BOOK_HAULAGE_BY_SUPPLIER_SUCCESS =
  'app/TransactionPage/BOOK_HAULAGE_BY_SUPPLIER_SUCCESS';
export const BOOK_HAULAGE_BY_SUPPLIER_ERROR = 'app/TransactionPage/BOOK_HAULAGE_BY_SUPPLIER_ERROR';

export const ORDER_DISPATCHED_BY_SUPPLIER_GO_BACK_REQUEST =
  'app/TransactionPage/ORDER_DISPATCHED_BY_SUPPLIER_GO_BACK_REQUEST';
export const ORDER_DISPATCHED_BY_SUPPLIER_GO_BACK_SUCCESS =
  'app/TransactionPage/ORDER_DISPATCHED_BY_SUPPLIER_GO_BACK_SUCCESS';
export const ORDER_DISPATCHED_BY_SUPPLIER_GO_BACK_ERROR =
  'app/TransactionPage/ORDER_DISPATCHED_BY_SUPPLIER_GO_BACK_ERROR';

export const ORDER_RECEIVED_BY_CUSTOMER_REQUEST =
  'app/TransactionPage/ORDER_RECEIVED_BY_CUSTOMER_REQUEST';
export const ORDER_RECEIVED_BY_CUSTOMER_SUCCESS =
  'app/TransactionPage/ORDER_RECEIVED_BY_CUSTOMER_SUCCESS';
export const ORDER_RECEIVED_BY_CUSTOMER_ERROR =
  'app/TransactionPage/ORDER_RECEIVED_BY_CUSTOMER_ERROR';

export const FETCH_MESSAGES_REQUEST = 'app/TransactionPage/FETCH_MESSAGES_REQUEST';
export const FETCH_MESSAGES_SUCCESS = 'app/TransactionPage/FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_ERROR = 'app/TransactionPage/FETCH_MESSAGES_ERROR';

export const SEND_MESSAGE_REQUEST = 'app/TransactionPage/SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'app/TransactionPage/SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_ERROR = 'app/TransactionPage/SEND_MESSAGE_ERROR';

export const EDIT_LINE_ITEMS_REQUEST = 'app/TransactionPage/EDIT_LINE_ITEMS_REQUEST';
export const EDIT_LINE_ITEMS_SUCCESS = 'app/TransactionPage/EDIT_LINE_ITEMS_SUCCESS';
export const EDIT_LINE_ITEMS_ERROR = 'app/TransactionPage/EDIT_LINE_ITEMS_ERROR';

export const PLACE_ON_HOLD_REQUEST = 'app/TransactionPage/PLACE_ON_HOLD_REQUEST';
export const PLACE_ON_HOLD_SUCCESS = 'app/TransactionPage/PLACE_ON_HOLD_SUCCESS';
export const PLACE_ON_HOLD_ERROR = 'app/TransactionPage/PLACE_ON_HOLD_ERROR';

export const CANCEL_REQUEST = 'app/TransactionPage/CANCEL_REQUEST';
export const CANCEL_SUCCESS = 'app/TransactionPage/CANCEL_SUCCESS';
export const CANCEL_ERROR = 'app/TransactionPage/CANCEL_ERROR';

export const REMOVE_LINE_ITEMS_REQUEST = 'app/TransactionPage/REMOVE_LINE_ITEMS_REQUEST';
export const REMOVE_LINE_ITEMS_SUCCESS = 'app/TransactionPage/REMOVE_LINE_ITEMS_SUCCESS';
export const REMOVE_LINE_ITEMS_ERROR = 'app/TransactionPage/REMOVE_LINE_ITEMS_ERROR';

export const ADD_PURCHASE_ORDER_NUMBER_REQUEST =
  'app/TransactionPage/ADD_PURCHASE_ORDER_NUMBER_REQUEST';
export const ADD_PURCHASE_ORDER_NUMBER_SUCCESS =
  'app/TransactionPage/ADD_PURCHASE_ORDER_NUMBER_SUCCESS';
export const ADD_PURCHASE_ORDER_NUMBER_ERROR =
  'app/TransactionPage/ADD_PURCHASE_ORDER_NUMBER_ERROR';

export const ADD_PURCHASE_ORDER_FILE_PENDING_SUCCESS =
  'app/TransactionPage/ADD_PURCHASE_ORDER_FILE_PENDING_SUCCESS';

export const ADD_DELIVERY_NOTE_FILE_PENDING_SUCCESS =
  'app/TransactionPage/ADD_DELIVERY_NOTE_FILE_PENDING_SUCCESS';

export const ADD_INVOICE_NUMBER_REQUEST = 'app/TransactionPage/ADD_INVOICE_NUMBER_REQUEST';
export const ADD_INVOICE_NUMBER_SUCCESS = 'app/TransactionPage/ADD_INVOICE_NUMBER_SUCCESS';
export const ADD_INVOICE_NUMBER_ERROR = 'app/TransactionPage/ADD_INVOICE_NUMBER_ERROR';

export const ADD_INVOICE_FILE_PENDING_SUCCESS =
  'app/TransactionPage/ADD_INVOICE_FILE_PENDING_SUCCESS';
export const ADD_MESSAGE_FILE_PENDING_SUCCESS =
  'app/TransactionPage/ADD_MESSAGE_FILE_PENDING_SUCCESS';

export const ADD_DELIVERY_DATE_REQUEST = 'app/TransactionPage/ADD_DELIVERY_DATE_REQUEST';
export const ADD_DELIVERY_DATE_SUCCESS = 'app/TransactionPage/ADD_DELIVERY_DATE_SUCCESS';
export const ADD_DELIVERY_DATE_ERROR = 'app/TransactionPage/ADD_DELIVERY_DATE_ERROR';

export const ADD_SUPPLIER_ORDER_NUMBER_REQUEST =
  'app/TransactionPage/ADD_SUPPLIER_ORDER_NUMBER_REQUEST';
export const ADD_SUPPLIER_ORDER_NUMBER_SUCCESS =
  'app/TransactionPage/ADD_SUPPLIER_ORDER_NUMBER_SUCCESS';
export const ADD_SUPPLIER_ORDER_NUMBER_ERROR =
  'app/TransactionPage/ADD_SUPPLIER_ORDER_NUMBER_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/TransactionPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/TransactionPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/TransactionPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_LINE_ITEMS_REQUEST = 'app/TransactionPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/TransactionPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/TransactionPage/FETCH_LINE_ITEMS_ERROR';

export const SUPPLIER_IS_GUEST = 'app/TransactionPage/SUPPLIER_IS_GUEST';
export const BASKET_IS_EMPTY = 'app/TransactionPage/BASKET_IS_EMPTY';

export const MARK_HAS_MINIMUM_ORDER_WEIGHT = 'app/TransactionPage/MARK_HAS_MINIMUM_ORDER_WEIGHT';
export const MARK_HAS_ORDER_WEIGHT = 'app/TransactionPage/MARK_HAS_ORDER_WEIGHT';
export const MARK_HAS_LOW_ORDER_WEIGHT = 'app/TransactionPage/MARK_HAS_LOW_ORDER_WEIGHT';

export const MARK_HAS_MINIMUM_ORDER_COST = 'app/TransactionPage/MARK_HAS_MINIMUM_ORDER_COST';
export const MARK_HAS_ORDER_COST = 'app/TransactionPage/MARK_HAS_ORDER_COST';
export const MARK_HAS_LOW_ORDER_COST = 'app/TransactionPage/MARK_HAS_LOW_ORDER_COST';

export const MARK_HAS_SHIPMENT_UNIT_INCREMENTS =
  'app/TransactionPage/MARK_HAS_SHIPMENT_UNIT_INCREMENTS';

export const MARK_HAS_SHIPMENT_TOTAL_UNITS = 'app/TransactionPage/MARK_HAS_SHIPMENT_TOTAL_UNITS';
export const MARK_HAS_SHIPMENT_UNIT_INCREMENTS_ERROR =
  'app/TransactionPage/MARK_HAS_SHIPMENT_UNIT_INCREMENTS_ERROR';
export const PLUGIN_AUTO_GENERATE_INVOICE_PDF_ENABLED =
  'app/TransactionPage/PLUGIN_AUTO_GENERATE_INVOICE_PDF_ENABLED';
export const PLUGIN_AUTO_GENERATE_DELIVERY_NOTE_PDF_ENABLED =
  'app/TransactionPage/PLUGIN_AUTO_GENERATE_DELIVERY_NOTE_PDF_ENABLED';
export const PLUGIN_HAULAGE_ENABLED = 'app/TransactionPage/PLUGIN_HAULAGE_ENABLED';

// ================ Reducer ================ //

const initialState = {
  isViewableTransaction: false,

  fetchTransactionInProgress: false,
  fetchTransactionError: null,
  transactionRef: null,
  purchasedPaymentByInvoiceInProgress: false,
  purchasedPaymentByInvoiceError: null,
  doneeAcceptsDonationInProgress: false,
  doneeAcceptsDonationError: null,
  doneeAcceptsDonationAfterRejectedInProgress: false,
  doneeAcceptsDonationAfterRejectedError: null,
  doneeRejectsDonationInProgress: false,
  doneeRejectsDonationError: null,
  doneeMarksAsReceivedInProgress: false,
  doneeMarksAsReceivedError: null,

  orderApprovedBySupplierInProgress: false,
  orderApprovedBySupplierError: null,
  orderPickedBySupplierInProgress: false,
  orderPickedBySupplierError: null,
  orderDispatchedBySupplierError: null,

  goBackToAcceptedInProgress: false,
  goBackToAcceptedError: null,

  goBackToSubmittedInProgress: false,
  goBackToSubmittedError: null,

  goBackToReadyForDispatchInProgress: false,
  goBackToReadyForDispacthError: null,

  markDispatchedInProgress: false,
  markDispatchedError: null,
  bookHaulageInProgress: false,
  bookHaulageError: null,
  markReceivedInProgress: false,
  markReceivedError: null,

  markDeliveredInProgress: false,
  markDeliveredError: null,
  fetchMessagesInProgress: false,
  fetchMessagesError: null,
  totalMessages: 0,
  totalMessagePages: 0,
  oldestMessagePageFetched: 0,
  messages: [],
  initialMessageFailedToTransaction: null,
  sendMessageInProgress: false,
  sendMessageError: null,
  editLineItemsInProgress: false,
  editLineItemsError: null,
  removeLineItemsInProgress: false,
  removeLineItemsError: null,
  addPurchaseOrderNumberInProgress: false,
  addPurchaseOrderNumberError: null,
  addPurchaseOrderFilePendingSuccess: false,
  addPurchaseOrderFilePending: false,
  addPurchaseOrderFilePendingSuccess: false,
  addPurchaseOrderFilePendingFilename: null,
  addPurchaseOrderFilePendingFilecontents: null,

  addDeliveryNoteFilePendingSuccess: false,
  addDeliveryNoteFilePending: false,
  addDeliveryNoteFilePendingSuccess: false,
  addDeliveryNoteFilePendingFilename: null,
  addDeliveryNoteFilePendingFilecontents: null,

  addInvoiceNumberInProgress: false,
  addInvoiceNumberError: null,
  addInvoiceFilePendingSuccess: false,
  addInvoiceFilePending: false,
  addInvoiceFilePendingSuccess: false,
  addInvoiceFilePendingFilename: null,
  addInvoiceFilePendingFilecontents: null,

  addMessageFilePendingSuccess: false,
  addMessageFilePending: false,
  addMessageFilePendingSuccess: false,
  addMessageFilePendingFilename: null,
  addMessageFilePendingFilecontents: null,

  addDeliveryDateInProgress: false,
  addDeliveryDateError: null,
  addSupplierOrderNumberInProgress: false,
  addSupplierOrderNumberError: null,
  fetchTransitionsInProgress: false,
  fetchTransitionsError: null,
  processTransitions: null,
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  isSupplierGuest: false,
  hasMinimumOrderWeight: false,
  minimumOrderWeight: null,
  hasMinimumOrderCost: false,
  minimumOrderCost: null,
  orderWeight: null,
  orderCost: null,
  hasLowOrderWeight: false,
  hasLowOrderCost: false,
  hasShipmenUnitIncrements: false,
  shipmentUnitIncrements: null,
  shipmentTotalUnits: null,
  hasShipmentUnitIncrementsError: false,

  placeOnHoldModalVisible: false,
  placeOnHoldInProgress: false,
  placeOnHoldError: null,
  pluginAutoGenerateInvoicePdf: false,
  pluginAutoGenerateDeliveryNotePdf: false,
  pluginHaulage: false,
  cancelModalVisible: false,
  cancelProgress: false,
  cancelError: null,
};

// Merge entity arrays using ids, so that conflicting items in newer array (b) overwrite old values (a).
// const a = [{ id: { uuid: 1 } }, { id: { uuid: 3 } }];
// const b = [{ id: : { uuid: 2 } }, { id: : { uuid: 1 } }];
// mergeEntityArrays(a, b)
// => [{ id: { uuid: 3 } }, { id: : { uuid: 2 } }, { id: : { uuid: 1 } }]
const mergeEntityArrays = (a, b) => {
  return a.filter(aEntity => !b.find(bEntity => aEntity.id.uuid === bEntity.id.uuid)).concat(b);
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_TRANSACTION_REQUEST:
      return {
        ...state,
        fetchTransactionInProgress: true,
        fetchTransactionError: null,
      };
    case FETCH_TRANSACTION_SUCCESS: {
      const transactionRef = { id: payload.data.data.id, type: 'transaction' };
      return { ...state, fetchTransactionInProgress: false, transactionRef };
    }
    case FETCH_TRANSACTION_ERROR:
      return {
        ...state,
        fetchTransactionInProgress: false,
        fetchTransactionError: payload,
      };

    case FETCH_TRANSITIONS_REQUEST:
      return {
        ...state,
        fetchTransitionsInProgress: true,
        fetchTransitionsError: null,
      };
    case FETCH_TRANSITIONS_SUCCESS:
      return {
        ...state,
        fetchTransitionsInProgress: false,
        processTransitions: payload,
      };
    case FETCH_TRANSITIONS_ERROR:
      return {
        ...state,
        fetchTransitionsInProgress: false,
        fetchTransitionsError: payload,
      };

    case MARK_DELIVERED_REQUEST:
      return {
        ...state,
        markDeliveredInProgress: true,
        markDeliveredError: null,
      };
    case MARK_DELIVERED_SUCCESS:
      return { ...state, markDeliveredInProgress: false };
    case MARK_DELIVERED_ERROR:
      return {
        ...state,
        markDeliveredInProgress: false,
        markDeliveredError: payload,
      };

    case PURCHASED_PAYMENT_BY_INVOICE_SUCCESS:
      return { ...state, purchasedPaymentByInvoiceInProgress: false };
    case PURCHASED_PAYMENT_BY_INVOICE_ERROR:
      return {
        ...state,
        purchasedPaymentByInvoiceInProgress: false,
        purchasedPaymentByInvoiceError: payload,
      };
    case PURCHASED_PAYMENT_BY_INVOICE_REQUEST:
      return {
        ...state,
        purchasedPaymentByInvoiceInProgress: true,
        purchasedPaymentByInvoiceError: null,
      };

    case DONEE_ACCEPTS_DONATION_SUCCESS:
      return { ...state, doneesAcceptsDonationInProgress: false };
    case DONEE_ACCEPTS_DONATION_ERROR:
      return {
        ...state,
        doneeAcceptsDonationInProgress: false,
        doneeAcceptsDonationError: payload,
      };
    case DONEE_ACCEPTS_DONATION_REQUEST:
      return {
        ...state,
        doneeAcceptsDonationInProgress: true,
        doneeAcceptsDonationError: null,
      };

    case DONEE_ACCEPTS_DONATION_AFTER_REJECTED_SUCCESS:
      return { ...state, doneesAcceptsDonationAfterRejectedInProgress: false };
    case DONEE_ACCEPTS_DONATION_AFTER_REJECTED_ERROR:
      return {
        ...state,
        doneeAcceptsDonationAfterRejectedInProgress: false,
        doneeAcceptsDonationAfterRejectedError: payload,
      };
    case DONEE_ACCEPTS_DONATION_AFTER_REJECTED_REQUEST:
      return {
        ...state,
        doneeAcceptsDonationAfterRejectedInProgress: true,
        doneeAcceptsDonationAfterRejectedError: null,
      };

    case DONEE_REJECTS_DONATION_SUCCESS:
      return { ...state, doneesRejectsDonationInProgress: false };
    case DONEE_REJECTS_DONATION_ERROR:
      return {
        ...state,
        doneeRejectsDonationInProgress: false,
        doneeRejectsDonationError: payload,
      };
    case DONEE_REJECTS_DONATION_REQUEST:
      return {
        ...state,
        doneeRejectsDonationInProgress: true,
        doneeRejectsDonationError: null,
      };

    case DONEE_MARKS_AS_RECEIVED_SUCCESS:
      return { ...state, doneesMarksAsReceivedInProgress: false };
    case DONEE_MARKS_AS_RECEIVED_ERROR:
      return {
        ...state,
        doneeMarksAsReceivedInProgress: false,
        doneeMarksAsReceivedError: payload,
      };
    case DONEE_MARKS_AS_RECEIVED_REQUEST:
      return {
        ...state,
        doneeMarksAsReceivedInProgress: true,
        doneeMarksAsReceivedError: null,
      };

    case ORDER_APPROVED_BY_SUPPLIER_SUCCESS:
      return { ...state, orderApprovedBySupplierInProgress: false };
    case ORDER_APPROVED_BY_SUPPLIER_ERROR:
      return {
        ...state,
        orderApprovedBySupplierInProgress: false,
        orderApprovedBySupplierError: payload,
      };
    case ORDER_APPROVED_BY_SUPPLIER_REQUEST:
      return {
        ...state,
        purchasedPaymentByInvoiceInProgress: true,
        orderApprovedBySupplierrror: null,
      };

    case ORDER_PICKED_BY_SUPPLIER_SUCCESS:
      return {
        ...state,
        purchasedPaymentByInvoiceInProgress: false,
      };

    case ORDER_PICKED_BY_SUPPLIER_ERROR:
      return {
        ...state,
        purchasedPaymentByInvoiceInProgress: false,
        orderPickedBySupplierError: payload,
      };

    case ORDER_DISPATCHED_BY_SUPPLIER_ERROR:
      return {
        ...state,
        purchasedPaymentByInvoiceInProgress: false,
        orderDispatchedBySupplierError: payload,
      };

    case ORDER_PICKED_BY_SUPPLIER_REQUEST:
      return {
        ...state,
        purchasedPaymentByInvoiceInProgress: true,
        orderPickedBySupplierError: null,
      };

    case ORDER_DISPATCHED_BY_SUPPLIER_SUCCESS:
      return { ...state, markDispatchedInProgress: false };
    case ORDER_DISPATCHED_BY_SUPPLIER_ERROR:
      return {
        ...state,
        markDispatchedInProgress: false,
        orderDispatchedBySupplierError: payload,
      };
    case ORDER_DISPATCHED_BY_SUPPLIER_REQUEST:
      return {
        ...state,
        purchasedPaymentByInvoiceInProgress: true,
        orderDispatchedBySupplierError: null,
      };

    case GO_BACK_TO_ACCEPTED_SUCCESS:
      return { ...state, goBackToAcceptednProgress: false };
    case GO_BACK_TO_ACCEPTED_ERROR:
      return {
        ...state,
        goBackToAcceptedInProgress: false,
        goBackToAcceptedError: payload,
      };
    case GO_BACK_TO_ACCEPTED_REQUEST:
      return {
        ...state,
        purchasedPaymentByInvoiceInProgress: true,
        goBackToAcceptedError: null,
      };

    case LAUNCH_ON_HOLD_MODAL:
      return {
        ...state,
        placeOnHoldModalVisible: true,
      };

    case CLOSE_ON_HOLD_MODAL:
      return {
        ...state,
        placeOnHoldModalVisible: false,
      };

    case LAUNCH_CANCEL_MODAL:
      return {
        ...state,
        cancelModalVisible: true,
      };

    case CLOSE_CANCEL_MODAL:
      return {
        ...state,
        cancelModalVisible: false,
      };

    case GO_BACK_TO_SUBMITTED_SUCCESS:
      return { ...state, goBackToSubmittednProgress: false };
    case GO_BACK_TO_SUBMITTED_ERROR:
      return {
        ...state,
        goBackToSubmittedInProgress: false,
        goBackToSubmittedError: payload,
      };
    case GO_BACK_TO_SUBMITTED_REQUEST:
      return {
        ...state,
        purchasedPaymentByInvoiceInProgress: true,
        goBackToSubmittedError: null,
      };

    case GO_BACK_TO_READY_FOR_DISPATCH_SUCCESS:
      return { ...state, goBackToReadyForDispatchnProgress: false };
    case GO_BACK_TO_READY_FOR_DISPATCH_ERROR:
      return {
        ...state,
        goBackToReadyForDispatchInProgress: false,
        goBackToReadyForDispatchError: payload,
      };
    case GO_BACK_TO_READY_FOR_DISPATCH_REQUEST:
      return {
        ...state,
        purchasedPaymentByInvoiceInProgress: true,
        goBackToReadyForDispatchError: null,
      };

    case ORDER_RECEIVED_BY_CUSTOMER_SUCCESS:
      return { ...state, markReceivedInProgress: false };
    case ORDER_RECEIVED_BY_CUSTOMER_ERROR:
      return {
        ...state,
        markReceivedInProgress: false,
        markReceivedError: payload,
      };
    case ORDER_RECEIVED_BY_CUSTOMER_REQUEST:
      return {
        ...state,
        markReceivedInProgress: true,
        markReceivedError: null,
      };

    case FETCH_MESSAGES_REQUEST:
      return {
        ...state,
        fetchMessagesInProgress: true,
        fetchMessagesError: null,
      };
    case FETCH_MESSAGES_SUCCESS: {
      const oldestMessagePageFetched =
        state.oldestMessagePageFetched > payload.page
          ? state.oldestMessagePageFetched
          : payload.page;
      return {
        ...state,
        fetchMessagesInProgress: false,
        messages: mergeEntityArrays(state.messages, payload.messages),
        totalMessages: payload.totalItems,
        totalMessagePages: payload.totalPages,
        oldestMessagePageFetched,
      };
    }
    case FETCH_MESSAGES_ERROR:
      return {
        ...state,
        fetchMessagesInProgress: false,
        fetchMessagesError: payload,
      };

    case SEND_MESSAGE_REQUEST:
      return {
        ...state,
        sendMessageInProgress: true,
        sendMessageError: null,
        initialMessageFailedToTransaction: null,
      };
    case SEND_MESSAGE_SUCCESS:
      return { ...state, sendMessageInProgress: false, addMessageFilePending: false };
    case SEND_MESSAGE_ERROR:
      return {
        ...state,
        sendMessageInProgress: false,
        sendMessageError: payload,
      };

    case EDIT_LINE_ITEMS_REQUEST:
      return {
        ...state,
        editLineItemsInProgress: true,
        editLineItemsError: null,
        //    initialMessageFailedToTransaction: null,
      };
    case EDIT_LINE_ITEMS_SUCCESS:
      return { ...state, editLineItemsInProgress: false };
    case EDIT_LINE_ITEMS_ERROR:
      return {
        ...state,
        editLineItemsInProgress: false,
        editLineItemsError: payload,
      };

    case PLACE_ON_HOLD_REQUEST:
      return {
        ...state,
        placeOnHoldInProgress: true,
        placeOnHoldError: null,
        //    initialMessageFailedToTransaction: null,
      };
    case PLACE_ON_HOLD_SUCCESS:
      return { ...state, placeOnHoldInProgress: false };
    case PLACE_ON_HOLD_ERROR:
      return {
        ...state,
        placeOnHoldInProgress: false,
        placeOnHoldError: payload,
      };

    case CANCEL_REQUEST:
      return {
        ...state,
        cencelInProgress: true,
        cancelError: null,
        //    initialMessageFailedToTransaction: null,
      };
    case CANCEL_SUCCESS:
      return { ...state, cancelInProgress: false };
    case CANCEL_ERROR:
      return {
        ...state,
        cancelInProgress: false,
        cancelError: payload,
      };

    case REMOVE_LINE_ITEMS_REQUEST:
      return {
        ...state,
        removeLineItemsInProgress: true,
        removeLineItemsError: null,
        //    initialMessageFailedToTransaction: null,
      };
    case REMOVE_LINE_ITEMS_SUCCESS:
      return { ...state, removeLineItemsInProgress: false };
    case REMOVE_LINE_ITEMS_ERROR:
      return {
        ...state,
        removeLineItemsInProgress: false,
        removeLineItemsError: payload,
      };

    case ADD_PURCHASE_ORDER_NUMBER_REQUEST:
      return {
        ...state,
        addPurchaseOrderNumberInProgress: true,
        addPurchaseOrderNumberError: null,
        //    initialMessageFailedToTransaction: null,
      };
    case ADD_PURCHASE_ORDER_NUMBER_SUCCESS:
      return { ...state, addPurchaseOrderNumberInProgress: false };
    case ADD_PURCHASE_ORDER_NUMBER_ERROR:
      return {
        ...state,
        addPurchaseOrderNumberProgress: false,
        addPurchaseOrderNumberError: payload,
      };
    case ADD_PURCHASE_ORDER_FILE_PENDING_SUCCESS:
      return {
        ...state,

        addPurchaseOrderFilePending: true,
        addPurchaseOrderFilePendingFilename: payload.addPurchaseOrderFilePendingFilename,
        addPurchaseOrderFilePendingFilecontents: payload.addPurchaseOrderFilePendingFilecontents,
      };

    case ADD_DELIVERY_NOTE_FILE_PENDING_SUCCESS:
      return {
        ...state,

        addDeliveryNoteFilePending: true,
        addDeliveryNoteFilePendingFilename: payload.addDeliveryNoteFilePendingFilename,
        addDeliveryNoteFilePendingFilecontents: payload.addDeliveryNoteFilePendingFilecontents,
      };

    case ADD_INVOICE_NUMBER_REQUEST:
      return {
        ...state,
        addInvoiceNumberInProgress: true,
        addInvoiceNumberError: null,
        //    initialMessageFailedToTransaction: null,
      };
    case ADD_INVOICE_NUMBER_SUCCESS:
      return { ...state, addInvoiceNumberInProgress: false };
    case ADD_INVOICE_NUMBER_ERROR:
      return {
        ...state,
        addInvoiceNumberProgress: false,
        addInvoiceNumberError: payload,
      };
    case ADD_INVOICE_FILE_PENDING_SUCCESS:
      return {
        ...state,

        addInvoiceFilePending: true,
        addInvoiceFilePendingFilename: payload.addInvoiceFilePendingFilename,
        addInvoiceFilePendingFilecontents: payload.addInvoiceFilePendingFilecontents,
      };

    case ADD_MESSAGE_FILE_PENDING_SUCCESS:
      return {
        ...state,

        addMessageFilePending: true,
        addMessageFilePendingFilename: payload.addMessageFilePendingFilename,
        addMessageFilePendingFilecontents: payload.addMessageFilePendingFilecontents,
      };

    case ADD_DELIVERY_DATE_REQUEST:
      return {
        ...state,
        addDeliveryDateInProgress: true,
        addDeliveryDateError: null,
        //    initialMessageFailedToTransaction: null,
      };
    case ADD_DELIVERY_DATE_SUCCESS:
      return { ...state, addDeliveryDateInProgress: false };
    case ADD_DELIVERY_DATE_ERROR:
      return {
        ...state,
        addDeliveryDateProgress: false,
        addDeliveryDateError: payload,
      };
    case ADD_SUPPLIER_ORDER_NUMBER_REQUEST:
      return {
        ...state,
        addSupplierOrderNumnerInProgress: true,
        addSupplierOrderNumberError: null,
        //    initialMessageFailedToTransaction: null,
      };
    case ADD_SUPPLIER_ORDER_NUMBER_SUCCESS:
      return { ...state, adSupplierOrderNumberInProgress: false };
    case ADD_SUPPLIER_ORDER_NUMBER_ERROR:
      return {
        ...state,
        addSupplierOrderNumberProgress: false,
        addSupplierOrderNumberError: payload,
      };
    case FETCH_LINE_ITEMS_REQUEST:
      return {
        ...state,
        fetchLineItemsInProgress: true,
        fetchLineItemsError: null,
      };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return {
        ...state,
        fetchLineItemsInProgress: false,
        fetchLineItemsError: payload,
      };

    case SUPPLIER_IS_GUEST:
      return {
        ...state,
        isSupplierGuest: true,
      };

    case BASKET_IS_EMPTY:
      return {
        ...state,
        isViewableTransaction: true,
      };

    case MARK_HAS_MINIMUM_ORDER_WEIGHT:
      return {
        ...state,
        hasMinimumOrderWeight: true,
        minimumOrderWeight: payload,
      };

    case MARK_HAS_ORDER_WEIGHT:
      return {
        ...state,
        orderWeight: payload,
      };
    case MARK_HAS_LOW_ORDER_WEIGHT:
      return {
        ...state,
        hasLowOrderWeight: payload,
      };

    case MARK_HAS_MINIMUM_ORDER_COST:
      return {
        ...state,
        hasMinimumOrderCost: true,
        minimumOrderCost: payload,
      };

    case MARK_HAS_ORDER_COST:
      return {
        ...state,
        orderCost: payload,
      };
    case MARK_HAS_LOW_ORDER_COST:
      return {
        ...state,
        hasLowOrderCost: payload,
      };

    case MARK_HAS_SHIPMENT_UNIT_INCREMENTS:
      return {
        ...state,
        hasShipmentUnitIncrements: true,
        shipmentUnitIncrements: payload,
      };

    case MARK_HAS_SHIPMENT_TOTAL_UNITS:
      return {
        ...state,
        shipmentTotalUnits: payload,
      };
    case MARK_HAS_SHIPMENT_UNIT_INCREMENTS_ERROR:
      return {
        ...state,
        hasShipmentUnitIncrementError: payload,
      };

    case PLUGIN_AUTO_GENERATE_INVOICE_PDF_ENABLED:
      return {
        ...state,
        pluginAutoGenerateInvoicePdf: true,
      };
    case PLUGIN_AUTO_GENERATE_DELIVERY_NOTE_PDF_ENABLED:
      return {
        ...state,
        pluginAutoGenerateDeliveryNotePdf: true,
      };
    case PLUGIN_HAULAGE_ENABLED:
      return {
        ...state,
        pluginHaulage: true,
      };
    default:
      return state;
  }
}

// ================ Selectors ================ //

export const transitionInProgress = state => {
  const pageState = state.TransactionPage;
  return pageState.markReceivedFromPurchasedInProgress || pageState.markDeliveredInProgress;
};

// ================ Action creators ================ //
export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const fetchTransactionRequest = () => ({ type: FETCH_TRANSACTION_REQUEST });
const fetchTransactionSuccess = response => ({
  type: FETCH_TRANSACTION_SUCCESS,
  payload: response,
});
const fetchTransactionError = e => ({
  type: FETCH_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

const fetchTransitionsRequest = () => ({ type: FETCH_TRANSITIONS_REQUEST });
const fetchTransitionsSuccess = response => ({
  type: FETCH_TRANSITIONS_SUCCESS,
  payload: response,
});
const fetchTransitionsError = e => ({
  type: FETCH_TRANSITIONS_ERROR,
  error: true,
  payload: e,
});

const markDeliveredRequest = () => ({ type: MARK_DELIVERED_REQUEST });
const markDeliveredSuccess = () => ({ type: MARK_DELIVERED_SUCCESS });
const markDeliveredError = e => ({
  type: MARK_DELIVERED_ERROR,
  error: true,
  payload: e,
});

const purchasedPaymentByInvoiceRequest = () => ({
  type: PURCHASED_PAYMENT_BY_INVOICE_REQUEST,
});
const purchasedPaymentByInvoiceSuccess = () => ({
  type: PURCHASED_PAYMENT_BY_INVOICE_SUCCESS,
});
const purchasedPaymentByInvoiceError = e => ({
  type: PURCHASED_PAYMENT_BY_INVOICE_ERROR,
  error: true,
  payload: e,
});

const doneeAcceptsDonationRequest = () => ({
  type: DONEE_ACCEPTS_DONATION_REQUEST,
});
const doneAcceptsDonationSuccess = () => ({
  type: DONEE_ACCEPTS_DONATION_SUCCESS,
});
const doneAcceptsDonationError = e => ({
  type: DONEE_ACCEPTS_DONATION_ERROR,
  error: true,
  payload: e,
});

const doneeAcceptsDonationAfterRejectedRequest = () => ({
  type: DONEE_ACCEPTS_DONATION_AFTER_REJECTED_REQUEST,
});
const doneAcceptsDonationAfterRejectedSuccess = () => ({
  type: DONEE_ACCEPTS_DONATION_AFTER_REJECTED_SUCCESS,
});
const doneAcceptsDonationAfterRejectedError = e => ({
  type: DONEE_ACCEPTS_DONATION_AFTER_REJECTED_ERROR,
  error: true,
  payload: e,
});

const doneeRejectsDonationRequest = () => ({
  type: DONEE_REJECTS_DONATION_REQUEST,
});
const doneRejectsDonationSuccess = () => ({
  type: DONEE_REJECTS_DONATION_SUCCESS,
});
const doneRejectsDonationError = e => ({
  type: DONEE_REJECTS_DONATION_ERROR,
  error: true,
  payload: e,
});

const doneeMarksAsReceivedRequest = () => ({
  type: DONEE_MARKS_AS_RECEIVED_REQUEST,
});
const doneeMarksAsReceivedSuccess = () => ({
  type: DONEE_MARKS_AS_RECEIVED_SUCCESS,
});
const doneeMarksAsReceivedError = e => ({
  type: DONEE_MARKS_AS_RECEIVED_ERROR,
  error: true,
  payload: e,
});

const orderApprovedBySupplierRequest = () => ({
  type: ORDER_APPROVED_BY_SUPPLIER_REQUEST,
});
const orderApprovedBySupplierSuccess = () => ({
  type: ORDER_APPROVED_BY_SUPPLIER_SUCCESS,
});
const orderApprovedBySupplierError = e => ({
  type: ORDER_APPROVED_BY_SUPPLIER_ERROR,
  error: true,
  payload: e,
});

const orderPickedBySupplierRequest = () => ({
  type: ORDER_PICKED_BY_SUPPLIER_REQUEST,
});
const orderPickedBySupplierSuccess = () => ({
  type: ORDER_PICKED_BY_SUPPLIER_SUCCESS,
});
const orderPickedBySupplierError = e => ({
  type: ORDER_PICKED_BY_SUPPLIER_ERROR,
  error: true,
  payload: e,
});

const orderDispatchedBySupplierError = e => ({
  type: ORDER_DISPATCHED_BY_SUPPLIER_ERROR,
  error: true,
  payload: e,
});

const goBackToAcceptedRequest = () => ({
  type: GO_BACK_TO_ACCEPTED_REQUEST,
});
const goBackToAcceptedSuccess = () => ({
  type: GO_BACK_TO_ACCEPTED_SUCCESS,
});
const goBackToAcceptedError = e => ({
  type: GO_BACK_TO_ACCEPTED_ERROR,
  error: true,
  payload: e,
});

const launchOnHoldModalRequest = () => ({
  type: LAUNCH_ON_HOLD_MODAL,
});

const closeOnHoldModalRequest = () => ({
  type: CLOSE_ON_HOLD_MODAL,
});

const launchCancelModalRequest = () => ({
  type: LAUNCH_CANCEL_MODAL,
});

const closeCancelModalRequest = () => ({
  type: CLOSE_CANCEL_MODAL,
});

const goBackToSubmittedRequest = () => ({
  type: GO_BACK_TO_SUBMITTED_REQUEST,
});
const goBackToSubmittedSuccess = () => ({
  type: GO_BACK_TO_SUBMITTED_SUCCESS,
});
const goBackToSubmittedError = e => ({
  type: GO_BACK_TO_SUBMITTED_ERROR,
  error: true,
  payload: e,
});

const goBackToReadyForDispatchRequest = () => ({
  type: GO_BACK_TO_READY_FOR_DISPATCH_REQUEST,
});
const goBackToReadyForDispatchSuccess = () => ({
  type: GO_BACK_TO_READY_FOR_DISPATCH_SUCCESS,
});
const goBackToReadyForDispatchError = e => ({
  type: GO_BACK_TO_READY_FOR_DISPATCH_ERROR,
  error: true,
  payload: e,
});

const markDispatchedRequest = () => ({
  type: ORDER_DISPATCHED_BY_SUPPLIER_REQUEST,
});
const markDispatchedSuccess = () => ({
  type: ORDER_DISPATCHED_BY_SUPPLIER_SUCCESS,
});
const markDispatchedError = e => ({
  type: ORDER_DISPATCHED_BY_SUPPLIER_ERROR,
  error: true,
  payload: e,
});

const bookHaulageRequest = () => ({
  type: BOOK_HAULAGE_BY_SUPPLIER_REQUEST,
});
const bookHaulageSuccess = () => ({
  type: BOOK_HAULAGE_BY_SUPPLIER_SUCCESS,
});
const bookHaulageError = e => ({
  type: BOOK_HAULAGE_BY_SUPPLIER_ERROR,
  error: true,
  payload: e,
});

const markReceivedRequest = () => ({
  type: ORDER_RECEIVED_BY_CUSTOMER_REQUEST,
});
const markReceivedSuccess = () => ({
  type: ORDER_RECEIVED_BY_CUSTOMER_SUCCESS,
});
const markReceivedError = e => ({
  type: ORDER_RECEIVED_BY_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

const fetchMessagesRequest = () => ({ type: FETCH_MESSAGES_REQUEST });
const fetchMessagesSuccess = (messages, pagination) => ({
  type: FETCH_MESSAGES_SUCCESS,
  payload: { messages, ...pagination },
});
const fetchMessagesError = e => ({
  type: FETCH_MESSAGES_ERROR,
  error: true,
  payload: e,
});

const sendMessageRequest = () => ({ type: SEND_MESSAGE_REQUEST });
const sendMessageSuccess = () => ({ type: SEND_MESSAGE_SUCCESS });
const sendMessageError = e => ({
  type: SEND_MESSAGE_ERROR,
  error: true,
  payload: e,
});

const editLineItemsRequest = () => ({ type: EDIT_LINE_ITEMS_REQUEST });
const editLineItemsSuccess = () => ({ type: EDIT_LINE_ITEMS_SUCCESS });
const editLineItemsError = e => ({
  type: EDIT_LINE_ITEMS_ERROR,
  error: true,
  payload: e,
});

const placeOnHoldRequest = () => ({ type: PLACE_ON_HOLD_REQUEST });
const placeOnHoldSuccess = () => ({ type: PLACE_ON_HOLD_SUCCESS });
const placeOnHoldError = e => ({
  type: PLACE_ON_HOLD_ERROR,
  error: true,
  payload: e,
});

const cancelRequest = () => ({ type: CANCEL_REQUEST });
const cancelSuccess = () => ({ type: CANCEL_SUCCESS });
const cancelError = e => ({
  type: CANCEL_ERROR,
  error: true,
  payload: e,
});

const removeLineItemsRequest = () => ({
  type: REMOVE_LINE_ITEMS_REQUEST,
});
const removeLineItemsSuccess = () => ({
  type: REMOVE_LINE_ITEMS_SUCCESS,
});
const removeLineItemsError = e => ({
  type: REMOVE_LINE_ITEMS_ERROR,
  error: true,
  payload: e,
});

const addPurchaseOrderNumberRequest = () => ({
  type: ADD_PURCHASE_ORDER_NUMBER_REQUEST,
});
const addPurchaseOrderNumberSuccess = () => ({
  type: ADD_PURCHASE_ORDER_NUMBER_SUCCESS,
});
const addPurchaseOrderNumberError = e => ({
  type: ADD_PURCHASE_ORDER_NUMBER_ERROR,
  error: true,
  payload: e,
});

const addPurchaseOrderFilePendingSuccess = e => ({
  type: ADD_PURCHASE_ORDER_FILE_PENDING_SUCCESS,
  payload: e,
});

const addDeliveryNoteFilePendingSuccess = e => ({
  type: ADD_DELIVERY_NOTE_FILE_PENDING_SUCCESS,
  payload: e,
});

const addInvoiceNumberRequest = () => ({
  type: ADD_INVOICE_NUMBER_REQUEST,
});
const addInvoiceNumberSuccess = () => ({
  type: ADD_INVOICE_NUMBER_SUCCESS,
});
const addInvoiceNumberError = e => ({
  type: ADD_INVOICE_NUMBER_ERROR,
  error: true,
  payload: e,
});

const addInvoiceFilePendingSuccess = e => ({
  type: ADD_INVOICE_FILE_PENDING_SUCCESS,
  payload: e,
});
const addMessageFilePendingSuccess = e => ({
  type: ADD_MESSAGE_FILE_PENDING_SUCCESS,
  payload: e,
});

const addSupplierOrderNumberRequest = () => ({ type: ADD_SUPPLIER_ORDER_NUMBER_REQUEST });
const addSupplierOrderNumberSuccess = () => ({ type: ADD_SUPPLIER_ORDER_NUMBER_SUCCESS });
const addSupplierOrderNumberError = e => ({
  type: ADD_SUPPLIER_ORDER_NUMBER_ERROR,
  error: true,
  payload: e,
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

export const supplierIsGuest = () => ({
  type: SUPPLIER_IS_GUEST,
  payload: true,
});

export const basketIsEmpty = () => ({
  type: BASKET_IS_EMPTY,
  payload: true,
});

export const markHasMinimumOrderWeight = weight => ({
  type: MARK_HAS_MINIMUM_ORDER_WEIGHT,
  payload: weight,
});

export const markHasOrderWeight = weight => ({
  type: MARK_HAS_ORDER_WEIGHT,
  payload: weight,
});
export const markLowOrderWeight = weight => ({
  type: MARK_HAS_LOW_ORDER_WEIGHT,
  payload: true,
});

export const markHasMinimumOrderCost = cost => ({
  type: MARK_HAS_MINIMUM_ORDER_COST,
  payload: cost,
});

export const markHasOrderCost = cost => ({
  type: MARK_HAS_ORDER_COST,
  payload: cost,
});
export const markLowOrderCost = cost => ({
  type: MARK_HAS_LOW_ORDER_COST,
  payload: true,
});

export const markHasShipmentUnitIncrements = incremnets => ({
  type: MARK_HAS_SHIPMENT_UNIT_INCREMENTS,
  payload: incremnets,
});

export const markHasShipmentTotalUnits = total => ({
  type: MARK_HAS_SHIPMENT_TOTAL_UNITS,
  payload: total,
});
export const markHasShipmentUnitIncrementsError = total => ({
  type: MARK_HAS_SHIPMENT_UNIT_INCREMENTS_ERROR,
  payload: true,
});

export const pluginAutoGenerateInvoicePdfEnabled = pluginAutoGenerateInvoicePdfEnabled => ({
  type: PLUGIN_AUTO_GENERATE_INVOICE_PDF_ENABLED,
  payload: true,
});

export const pluginAutoGenerateDeliveryNotePdfEnabled = pluginAutoGenerateDeliveryNotePdfEnabled => ({
  type: PLUGIN_AUTO_GENERATE_DELIVERY_NOTE_PDF_ENABLED,
  payload: true,
});

export const pluginHaulageEnabled = pluginHaulageEnabled => ({
  type: PLUGIN_HAULAGE_ENABLED,
  payload: true,
});

// ================ Thunks ================ //

// Helper to fetch correct image variants for different thunk calls
const getImageVariants = () => {
  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;
  return {
    'fields.image': [
      // Profile images
      'variants.square-small',
      'variants.square-small2x',

      // Listing images:
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
  };
};

const listingRelationship = txResponse => {
  return txResponse.data.data.relationships.listing.data;
};

export const fetchTransaction = (id, txRole) => (dispatch, getState, sdk) => {
  dispatch(fetchTransactionRequest());
  let txResponse = null;

  return getTransaction(
    {
      id,
      include: [
        'customer',
        'customer.profileImage',
        'provider',
        'provider.profileImage',
        'listing',
        'listing.currentStock',
        'booking',
        'reviews',
        'reviews.author',
        'reviews.subject',
      ],
      ...getImageVariants(),
    },
    { expand: true }
  )
    .then(response => {
      txResponse = response;
      const listingId = listingRelationship(response).id;
      const entities = updatedEntities({}, response.data);
      const listingRef = { id: listingId, type: 'listing' };
      const transactionRef = { id, type: 'transaction' };
      const denormalised = denormalisedEntities(entities, [listingRef, transactionRef]);
      const listing = denormalised[0];
      const transaction = denormalised[1];

      // Fetch time slots for transactions that are in enquired state
      const canFetchTimeslots =
        txRole === 'customer' &&
        config.listingManagementType === 'availability' &&
        transaction &&
        txIsEnquired(transaction);

      if (canFetchTimeslots) {
        dispatch(fetchTimeSlots(listingId));
      }

      const canFetchListing = listing && listing.attributes && !listing.attributes.deleted;
      if (canFetchListing) {
        return sdk.listings.show({
          id: listingId,
          include: ['author', 'author.profileImage', 'images'],
          ...getImageVariants(),
        });
      } else {
        return response;
      }
    })
    .then(response => {
      dispatch(addMarketplaceEntities(txResponse));
      dispatch(addMarketplaceEntities(response));

      const tx = txResponse.data.data;
      const { lineItems } = tx.attributes;

      //empty basket check
      if (lineItems.length === 1 && lineItems[0].code.includes('empty-active-basket-holder')) {
        dispatch(basketIsEmpty());
      }

      const isSupplierGuest = txResponse.data.data.attributes.protectedData?.isGuest;

      if (isSupplierGuest) {
        dispatch(supplierIsGuest());
      }

      const minimumOrderWeight = txResponse.data.data.attributes.protectedData?.minimumOrderWeight;
      const orderWeight = txResponse.data.data.attributes.protectedData?.orderWeight;

      if (minimumOrderWeight) {
        dispatch(markHasMinimumOrderWeight(minimumOrderWeight));
      }

      if (orderWeight) {
        dispatch(markHasOrderWeight(orderWeight));
      }

      if (minimumOrderWeight && orderWeight) {
        minimumOrderWeight > orderWeight ? dispatch(markLowOrderWeight()) : null;
      }

      const minimumOrderCost = txResponse.data.data.attributes.protectedData?.minimumOrderCost;
      const orderCost = txResponse.data.data.attributes.protectedData?.orderCost;

      if (minimumOrderCost) {
        dispatch(markHasMinimumOrderCost(minimumOrderCost));
      }

      if (orderCost) {
        dispatch(markHasOrderCost(orderCost));
      }

      if (minimumOrderCost && orderCost) {
        minimumOrderCost > orderCost ? dispatch(markLowOrderCost()) : null;
      }

      const shipmentUnitIncrements =
        txResponse.data.data.attributes.protectedData?.shipmentUnitIncrements;
      const shipmentTotalUnits = txResponse.data.data.attributes.protectedData?.totalUnits;

      if (shipmentUnitIncrements) {
        dispatch(markHasShipmentUnitIncrements(shipmentUnitIncrements));
      }

      if (shipmentTotalUnits) {
        dispatch(markHasShipmentTotalUnits(shipmentTotalUnits));
      }

      if (shipmentUnitIncrements && shipmentTotalUnits) {
        const shipmentUnitsMatches = shipmentUnitIncrements.map(increment => {
          const incrementCheck = shipmentTotalUnits / increment;
          return incrementCheck - Math.floor(incrementCheck) === 0;
        });
        !shipmentUnitsMatches.includes(true)
          ? dispatch(markHasShipmentUnitIncrementsError())
          : null;
      }

      const autoGenerateInvoicePdf = txResponse.data.data.attributes.protectedData?.plugins
        ?.invoicing?.config?.autoGenerateInvoicePdf
        ? txResponse.data.data.attributes.protectedData.plugins.invoicing.config
            .autoGenerateInvoicePdf
        : null;

      if (autoGenerateInvoicePdf) {
        dispatch(pluginAutoGenerateInvoicePdfEnabled(autoGenerateInvoicePdf));
      }

      const autoGenerateDeliveryNotePdf = txResponse.data.data.attributes.protectedData?.plugins
        ?.shipping?.config?.autoGenerateDeliveryNotePdf
        ? txResponse.data.data.attributes.protectedData.plugins.shipping.config
            .autoGenerateDeliveryNotePdf.active
        : null;
      if (autoGenerateDeliveryNotePdf) {
        dispatch(pluginAutoGenerateDeliveryNotePdfEnabled(autoGenerateDeliveryNotePdf));
      }

      const haulage = txResponse.data.data.attributes.protectedData?.plugins?.haulage?.active
        ? txResponse.data.data.attributes.protectedData.plugins.haulage
        : null;

      if (haulage) {
        dispatch(pluginHaulageEnabled(haulage));
      }

      dispatch(fetchTransactionSuccess(txResponse));
      return response;
    })
    .catch(e => {
      dispatch(fetchTransactionError(storableError(e)));
      throw e;
    });
};

export const markDelivered = id => (dispatch, getState, sdk) => {
  if (transitionInProgress(getState())) {
    return Promise.reject(new Error('Transition already in progress'));
  }
  dispatch(markDeliveredRequest());

  return sdk.transactions
    .transition({ id, transition: TRANSITION_MARK_DELIVERED, params: {} }, { expand: true })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(markDeliveredSuccess());
      dispatch(fetchCurrentUserNotifications());
      return response;
    })
    .catch(e => {
      dispatch(markDeliveredError(storableError(e)));
      log.error(e, 'mark-delivered-failed', {
        txId: id,
        transition: TRANSITION_MARK_DELIVERED,
      });
      throw e;
    });
};

export const purchasedPaymentByInvoice = id => (dispatch, getState, sdk) => {
  if (transitionInProgress(getState())) {
    return Promise.reject(new Error('Transition already in progress'));
  }
  dispatch(purchasedPaymentByInvoiceRequest());
  //isSpeculative: false, orderData, bodyParams, queryParams
  const orderData = {};
  const queryParams = {
    include: ['booking', 'provider', 'buyer'],
    expand: true,
  };

  const bodyParams = {
    id: id.uuid,
    transition: TRANSITION_PURCHASED_PAYMENT_BY_INVOICE,
    params: {
      listingId: '634d49da-ad6c-4820-9d0a-3d98be56fa2f',
      stockReservationQuantity: 10,
    },
  };
  //({ isSpeculative: false, orderData, bodyParams, queryParams })

  return sendToSupplierPrivileged(
    { isSpeculative: false, bodyParams, orderData, queryParams },
    { expand: true }
  )
    .then(response => {
      window.location.reload(false);
      return response;
    })
    .catch(e => {
      dispatch(purchasedPaymentByInvoiceError(storableError(e)));
      log.error(e, 'purchased-payment-by-invoice-failed', {
        txId: id,
        transition: TRANSITION_PURCHASED_PAYMENT_BY_INVOICE,
      });
      throw e;
    });
};

export const doneeAcceptsDonation = id => (dispatch, getState, sdk) => {
  if (transitionInProgress(getState())) {
    return Promise.reject(new Error('Transition already in progress'));
  }

  dispatch(doneeAcceptsDonationRequest());
  //isSpeculative: false, orderData, bodyParams, queryParams
  const orderData = {};
  const queryParams = {
    include: ['booking', 'provider', 'buyer'],
    expand: true,
  };

  const bodyParams = {
    id: id.uuid,
    transition: TRANSITION_DONEE_ACCEPTS_OFFER,
    params: {
      listingId: '634d49da-ad6c-4820-9d0a-3d98be56fa2f',
      stockReservationQuantity: 10,
    },
  };
  //({ isSpeculative: false, orderData, bodyParams, queryParams })

  return donationAccepted(
    { isSpeculative: false, bodyParams, orderData, queryParams },
    { expand: true }
  )
    .then(response => {
      window.location.reload(false);
      return response;
    })
    .catch(e => {
      dispatch(doneeAcceptsDonationError(storableError(e)));
      log.error(e, 'purchased-payment-by-invoice-failed', {
        txId: id,
        transition: TRANSITION_PURCHASED_PAYMENT_BY_INVOICE,
      });
      throw e;
    });
};

export const doneeAcceptsDonationAfterRejected = id => (dispatch, getState, sdk) => {
  if (transitionInProgress(getState())) {
    return Promise.reject(new Error('Transition already in progress'));
  }

  dispatch(doneeAcceptsDonationAfterRejectedRequest());
  //isSpeculative: false, orderData, bodyParams, queryParams
  const orderData = {};
  const queryParams = {
    include: ['booking', 'provider', 'buyer'],
    expand: true,
  };

  const bodyParams = {
    id: id.uuid,
    transition: TRANSITION_DONEE_ACCEPTS_OFFER_AFTER_REJECTED,
    params: {
      listingId: '634d49da-ad6c-4820-9d0a-3d98be56fa2f',
      stockReservationQuantity: 10,
    },
  };
  //({ isSpeculative: false, orderData, bodyParams, queryParams })

  return donationAcceptedAfterRejected(
    { isSpeculative: false, bodyParams, orderData, queryParams },
    { expand: true }
  )
    .then(response => {
      window.location.reload(false);
      return response;
    })
    .catch(e => {
      dispatch(doneeAcceptsDonationError(storableError(e)));
      log.error(e, 'purchased-payment-by-invoice-failed', {
        txId: id,
        transition: TRANSITION_PURCHASED_PAYMENT_BY_INVOICE,
      });
      throw e;
    });
};

export const doneeRejectsDonation = id => (dispatch, getState, sdk) => {
  if (transitionInProgress(getState())) {
    return Promise.reject(new Error('Transition already in progress'));
  }
  dispatch(doneeRejectsDonationRequest());
  //isSpeculative: false, orderData, bodyParams, queryParams
  const orderData = {};
  const queryParams = {
    include: ['booking', 'provider', 'buyer'],
    expand: true,
  };

  const bodyParams = {
    id: id.uuid,
    transition: TRANSITION_DONEE_REJECTS_OFFER,
    params: {
      listingId: '634d49da-ad6c-4820-9d0a-3d98be56fa2f',
      stockReservationQuantity: 10,
    },
  };
  //({ isSpeculative: false, orderData, bodyParams, queryParams })

  return donationRejected(
    { isSpeculative: false, bodyParams, orderData, queryParams },
    { expand: true }
  )
    .then(response => {
      window.location.reload(false);
      return response;
    })
    .catch(e => {
      dispatch(doneeRejectssDonationError(storableError(e)));
      log.error(e, 'purchased-payment-by-invoice-failed', {
        txId: id,
        transition: TRANSITION_PURCHASED_PAYMENT_BY_INVOICE,
      });
      throw e;
    });
};

export const doneeMarksAsReceived = id => (dispatch, getState, sdk) => {
  if (transitionInProgress(getState())) {
    return Promise.reject(new Error('Transition already in progress'));
  }
  dispatch(doneeMarksAsReceivedRequest());
  //isSpeculative: false, orderData, bodyParams, queryParams
  const orderData = {};
  const queryParams = {
    include: ['booking', 'provider', 'buyer'],
    expand: true,
  };

  const bodyParams = {
    id: id.uuid,
    transition: TRANSITION_DONEE_MARKS_AS_RECEIVED,
    params: {
      listingId: '634d49da-ad6c-4820-9d0a-3d98be56fa2f',
      stockReservationQuantity: 10,
    },
  };
  //({ isSpeculative: false, orderData, bodyParams, queryParams })

  return donationReceived(
    { isSpeculative: false, bodyParams, orderData, queryParams },
    { expand: true }
  )
    .then(response => {
      window.location.reload(false);
      return response;
    })
    .catch(e => {
      dispatch(doneeMarksAsReceivedError(storableError(e)));
      log.error(e, 'donee-marks-as-received-failed', {
        txId: id,
        transition: TRANSITION_DONEE_MARKS_AS_RECEIVED,
      });
      throw e;
    });
};

export const orderApprovedBySupplier = id => (dispatch, getState, sdk) => {
  if (transitionInProgress(getState())) {
    return Promise.reject(new Error('Transition already in progress'));
  }
  dispatch(orderApprovedBySupplierRequest());
  //isSpeculative: false, orderData, bodyParams, queryParams
  const orderData = {};
  const queryParams = {
    include: ['booking', 'provider', 'buyer'],
    expand: true,
  };

  const bodyParams = {
    id: id.uuid,
    transition: TRANSITION_SUPPLIER_ACCEPTS_ORDER,
    params: {
      listingId: '634d49da-ad6c-4820-9d0a-3d98be56fa2f',
      stockReservationQuantity: 10,
    },
  };
  //({ isSpeculative: false, orderData, bodyParams, queryParams })

  return orderApproved(
    { isSpeculative: false, bodyParams, orderData, queryParams },
    { expand: true }
  )
    .then(response => {
      window.location.reload(false);

      return response;
    })
    .catch(e => {
      dispatch(orderApprovedBySupplierError(storableError(e)));
      log.error(e, 'purchased-payment-by-invoice-failed', {
        txId: id,
        transition: TRANSITION_PURCHASED_PAYMENT_BY_INVOICE,
      });
      throw e;
    });
};

export const onHoldOrderApprovedBySupplier = id => (dispatch, getState, sdk) => {
  if (transitionInProgress(getState())) {
    return Promise.reject(new Error('Transition already in progress'));
  }
  dispatch(orderApprovedBySupplierRequest());
  //isSpeculative: false, orderData, bodyParams, queryParams
  const orderData = {};
  const queryParams = {
    include: ['booking', 'provider', 'buyer'],
    expand: true,
  };

  const bodyParams = {
    id: id.uuid,
    transition: TRANSITION_REMOVE_ON_HOLD,
    params: {
      listingId: '634d49da-ad6c-4820-9d0a-3d98be56fa2f',
      stockReservationQuantity: 10,
    },
  };
  //({ isSpeculative: false, orderData, bodyParams, queryParams })

  return onHoldOrderApproved(
    { isSpeculative: false, bodyParams, orderData, queryParams },
    { expand: true }
  )
    .then(response => {
      window.location.reload(false);

      return response;
    })
    .catch(e => {
      dispatch(orderApprovedBySupplierError(storableError(e)));
      log.error(e, 'purchased-payment-by-invoice-failed', {
        txId: id,
        transition: TRANSITION_PURCHASED_PAYMENT_BY_INVOICE,
      });
      throw e;
    });
};

export const orderPickedBySupplier = id => (dispatch, getState, sdk) => {
  dispatch(orderPickedBySupplierRequest());

  if (transitionInProgress(getState())) {
    return Promise.reject(new Error('Transition already in progress'));
  }

  //isSpeculative: false, orderData, bodyParams, queryParams
  const orderData = {};
  const queryParams = {
    include: ['booking', 'provider', 'buyer'],
    expand: true,
  };

  const bodyParams = {
    id: id.uuid,
    transition: TRANSITION_MARK_AS_PICKED,
  };
  //({ isSpeculative: false, orderData, bodyParams, queryParams })

  return orderMarkedAsPicked(
    { isSpeculative: false, bodyParams, orderData, queryParams },
    { expand: true }
  )
    .then(response => {
      window.location.reload(false);

      return response;
    })
    .catch(e => {
      dispatch(orderPickedBySupplierError(storableError(e)));
      log.error(e, 'purchased-payment-by-invoice-failed', {
        txId: id,
        transition: TRANSITION_PURCHASED_PAYMENT_BY_INVOICE,
      });
      throw e;
    });
};

export const orderPickedBySupplierXX = id => async (dispatch, getState, sdk) => {
  if (transitionInProgress(getState())) {
    return Promise.reject(new Error('Transition already in progress'));
  }
  dispatch(orderPickedBySupplierRequest());

  orderMarkedAsPicked({ transactionId: id })
    .then(response => {
      window.location.reload(false);
      return response;
    })
    .catch(e => {
      dispatch(sendReviewError(storableError(e)));
      throw e;
    });

  // const response = await sdk.transactions.transition(
  //   { id, transition: TRANSITION_MARK_AS_PICKED, params: {} },
  //   { expand: true }
  // );
  window.location.reload(false);

  return response;
};

export const goBackToAccepted = id => async (dispatch, getState, sdk) => {
  if (transitionInProgress(getState())) {
    return Promise.reject(new Error('Transition already in progress'));
  }
  dispatch(goBackToAcceptedRequest());

  const response = await sdk.transactions.transition(
    { id, transition: TRANSITION_GO_BACK_TO_ACCEPTED, params: {} },
    { expand: true }
  );
  window.location.reload(false);

  return response;
};

export const goBackToSubmitted = id => async (dispatch, getState, sdk) => {
  if (transitionInProgress(getState())) {
    return Promise.reject(new Error('Transition already in progress'));
  }
  dispatch(goBackToSubmittedRequest());

  const response = await sdk.transactions.transition(
    { id, transition: TRANSITION_GO_BACK_TO_SUBMITTED, params: {} },
    { expand: true }
  );
  window.location.reload(false);

  return response;
};

export const goBackToReadyForDispatch = id => async (dispatch, getState, sdk) => {
  if (transitionInProgress(getState())) {
    return Promise.reject(new Error('Transition already in progress'));
  }
  dispatch(goBackToReadyForDispatchRequest());

  const response = await sdk.transactions.transition(
    { id, transition: TRANSITION_GO_BACK_TO_READY_FOR_DISPATCH, params: {} },
    { expand: true }
  );
  window.location.reload(false);

  return response;
};

export const markDispatchedxx = id => async (dispatch, getState, sdk) => {
  if (transitionInProgress(getState())) {
    return Promise.reject(new Error('Transition already in progress'));
  }
  dispatch(markDispatchedRequest());

  const response = await sdk.transactions.transition(
    { id, transition: TRANSITION_MARK_AS_DISPATCHED, params: {} },
    { expand: true }
  );
  window.location.reload(false);
  return response;
};

export const markDispatched = id => (dispatch, getState, sdk) => {
  if (transitionInProgress(getState())) {
    return Promise.reject(new Error('Transition already in progress'));
  }
  dispatch(markDispatchedRequest());
  //isSpeculative: false, orderData, bodyParams, queryParams
  const orderData = {};
  const queryParams = {
    include: ['booking', 'provider', 'buyer'],
    expand: true,
  };

  const bodyParams = {
    id: id.uuid,
    transition: TRANSITION_MARK_AS_DISPATCHED,
  };
  //({ isSpeculative: false, orderData, bodyParams, queryParams })

  return orderMarkedAsDispatched(
    { isSpeculative: false, bodyParams, orderData, queryParams },
    { expand: true }
  )
    .then(response => {
      window.location.reload(false);

      return response;
    })
    .catch(e => {
      dispatch(orderDispatchedBySupplierError(storableError(e)));
      log.error(e, 'purchased-payment-by-invoice-failed', {
        txId: id,
        transition: TRANSITION_MARK_AS_DISPATCHED,
      });
      throw e;
    });
};

export const bookHaulage = id => (dispatch, getState, sdk) => {
  if (transitionInProgress(getState())) {
    return Promise.reject(new Error('Transition already in progress'));
  }
  dispatch(bookHaulageRequest());
  //isSpeculative: false, orderData, bodyParams, queryParams
  const orderData = {};
  const queryParams = {
    include: ['booking', 'provider', 'buyer'],
    expand: true,
  };

  const bodyParams = {
    id: id.uuid,
    transition: TRANSITION_SUPLIER_REQUESTS_HAULAGE,
  };
  //({ isSpeculative: false, orderData, bodyParams, queryParams })

  return orderBookHaulage(
    { isSpeculative: false, bodyParams, orderData, queryParams },
    { expand: true }
  )
    .then(response => {
      window.location.reload(false);

      return response;
    })
    .catch(e => {
      dispatch(orderDispatchedBySupplierError(storableError(e)));
      log.error(e, 'purchased-payment-by-invoice-failed', {
        txId: id,
        transition: TRANSITION_SUPLIER_REQUESTS_HAULAGE,
      });
      throw e;
    });
};

export const markReceived = id => async (dispatch, getState, sdk) => {
  if (transitionInProgress(getState())) {
    return Promise.reject(new Error('Transition already in progress'));
  }
  dispatch(markReceivedRequest());

  const response = await sdk.transactions.transition(
    { id, transition: TRANSITION_MARK_AS_DELIVERED, params: {} },
    { expand: true }
  );
  window.location.reload(false);
  return response;
};

export const launchOnHoldModal = () => async (dispatch, getState, sdk) => {
  dispatch(launchOnHoldModalRequest());

  return null;
};
export const closeOnHoldModal = () => async (dispatch, getState, sdk) => {
  dispatch(closeOnHoldModalRequest());

  return null;
};

export const launchCancelModal = () => async (dispatch, getState, sdk) => {
  dispatch(launchCancelModalRequest());

  return null;
};
export const closeCancelModal = () => async (dispatch, getState, sdk) => {
  dispatch(closeCancelModalRequest());

  return null;
};

const fetchMessages = (txId, page) => (dispatch, getState, sdk) => {
  const paging = { page, per_page: MESSAGES_PAGE_SIZE };
  dispatch(fetchMessagesRequest());

  return sdk.messages
    .query({
      transaction_id: txId,
      include: ['sender', 'sender.profileImage'],
      ...getImageVariants(),
      ...paging,
    })
    .then(response => {
      const messages = denormalisedResponseEntities(response);
      const { totalItems, totalPages, page: fetchedPage } = response.data.meta;
      const pagination = { totalItems, totalPages, page: fetchedPage };
      const totalMessages = getState().TransactionPage.totalMessages;

      // Original fetchMessages call succeeded
      dispatch(fetchMessagesSuccess(messages, pagination));

      // Check if totalItems has changed between fetched pagination pages
      // if totalItems has changed, fetch first page again to include new incoming messages.
      // TODO if there're more than 100 incoming messages,
      // this should loop through most recent pages instead of fetching just the first one.
      if (totalItems > totalMessages && page > 1) {
        dispatch(fetchMessages(txId, 1))
          .then(() => {
            // Original fetch was enough as a response for user action,
            // this just includes new incoming messages
          })
          .catch(() => {
            // Background update, no need to to do anything atm.
          });
      }
    })
    .catch(e => {
      dispatch(fetchMessagesError(storableError(e)));
      throw e;
    });
};

export const fetchMoreMessages = txId => (dispatch, getState, sdk) => {
  const state = getState();
  const { oldestMessagePageFetched, totalMessagePages } = state.TransactionPage;
  const hasMoreOldMessages = totalMessagePages > oldestMessagePageFetched;

  // In case there're no more old pages left we default to fetching the current cursor position
  const nextPage = hasMoreOldMessages ? oldestMessagePageFetched + 1 : oldestMessagePageFetched;

  return dispatch(fetchMessages(txId, nextPage));
};

export const editLineItems = formValues => (dispatch, getState, sdk) => {
  const state = getState().TransactionPage;
  const txId = state.transactionRef.id.uuid;
  const lineItems = formValues.lineItems;

  updateLineItemsAfterPicking({ transactionId: txId, lineItems: lineItems })
    .then(response => {
      window.location.reload(false);
      return response;
    })
    .catch(e => {
      dispatch(sendReviewError(storableError(e)));
      throw e;
    });
};

export const placeOnHold = formValues => (dispatch, getState, sdk) => {
  const state = getState().TransactionPage;
  const { reason } = formValues;
  const txId = state.transactionRef.id.uuid;

  placeOnHoldApi({ transactionId: txId, reason })
    .then(response => {
      window.location.reload(false);
      return response;
    })
    .catch(e => {
      dispatch(sendReviewError(storableError(e)));
      throw e;
    });
};

export const cancel = formValues => (dispatch, getState, sdk) => {
  const state = getState().TransactionPage;
  const { reason, transition } = formValues;
  const txId = state.transactionRef.id.uuid;

  cancelApi({ transactionId: txId, reason, transition })
    .then(response => {
      window.location.reload(false);
      return response;
    })
    .catch(e => {
      dispatch(sendReviewError(storableError(e)));
      throw e;
    });
};

export const removeLineItems = formValues => (dispatch, getState, sdk) => {
  const state = getState().TransactionPage;
  const txId = state.transactionRef.id.uuid;
  const lineItems = formValues;

  let lineItemCodes = [];
  _.each(lineItems, function(value, key) {
    lineItemCodes.push(key);
  });

  removeLineItemsApi({ transactionId: txId, lineItemCodes: lineItemCodes })
    .then(response => {
      window.location.reload(false);
      return response;
    })
    .catch(e => {
      dispatch(sendReviewError(storableError(e)));
      throw e;
    });
};

export const addPurchaseOrderFilePending = pdf => (dispatch, getState, sdk) => {
  const state = getState().TransactionPage;
  const txId = state.transactionRef.id.uuid;
  const pdfPending = {
    addPurchaseOrderFilePending: true,
    addPurchaseOrderFilePendingFilename: 'poPdf',
    addPurchaseOrderFilePendingFilecontents: pdf,
  };
  dispatch(addPurchaseOrderFilePendingSuccess(pdfPending));
};

export const addPurchaseOrderNumber = (formValues, form) => (dispatch, getState, sdk) => {
  const state = getState().TransactionPage;

  const txId = state.transactionRef.id.uuid;
  const { purchaseOrderNumber, purchaseOrderPDF, poPdf } = formValues;

  // const formData = new FormData(formValues);

  // Update the formData object
  const formData = new FormData();
  formData.append('transactionId', txId);
  formData.append('purchaseOrderNumber', purchaseOrderNumber);
  if (state.addPurchaseOrderFilePending) {
    formData.append('poPdf', state.addPurchaseOrderFilePendingFilecontents, 'poPdf');
  }

  if (state.addPurchaseOrderFilePending) {
    addPurchaseOrderNumberAndFileApi({ transactionId: txId, formData })
      .then(response => {
        window.location.reload(false);
      })
      .catch(e => {
        dispatch(sendReviewError(storableError(e)));
        throw e;
      });
  } else {
    addPurchaseOrderNumberApi({ transactionId: txId, purchaseOrderNumber })
      .then(response => {
        window.location.reload(false);
      })
      .catch(e => {
        dispatch(sendReviewError(storableError(e)));
        throw e;
      });
  }
};

export const addDeliveryNoteFilePending = pdf => (dispatch, getState, sdk) => {
  const state = getState().TransactionPage;
  const txId = state.transactionRef.id.uuid;
  const pdfPending = {
    addDeliveryNoteFilePending: true,
    addDeliveryNoteFilePendingFilename: 'deliveryNotePdf',
    addDeliveryNoteFilePendingFilecontents: pdf,
  };
  dispatch(addDeliveryNoteFilePendingSuccess(pdfPending));
};

export const addTransportDetails = (formValues, form) => (dispatch, getState, sdk) => {
  const state = getState().TransactionPage;

  const txId = state.transactionRef.id.uuid;
  const { delivertNotePdf } = formValues;

  // const formData = new FormData(formValues);

  // Update the formData object
  const formData = new FormData();
  formData.append('transactionId', txId);
  if (state.addDeliveryNoteFilePending) {
    formData.append(
      'deliveryNotePdf',
      state.addDeliveryNoteFilePendingFilecontents,
      'deliveryNotePdf'
    );
  }

  if (state.addDeliveryNoteFilePending) {
    addTransportDetailsWithDeliveryNoteApi({ transactionId: txId, formData })
      .then(response => {
        window.location.reload(false);
      })
      .catch(e => {
        dispatch(sendReviewError(storableError(e)));
        throw e;
      });
  } else {
    addPurchaseOrderNumberApi({ transactionId: txId, purchaseOrderNumber })
      .then(response => {
        window.location.reload(false);
      })
      .catch(e => {
        dispatch(sendReviewError(storableError(e)));
        throw e;
      });
  }
};

export const addInvoiceFilePending = pdf => (dispatch, getState, sdk) => {
  const state = getState().TransactionPage;
  const txId = state.transactionRef.id.uuid;
  const pdfPending = {
    addInvoiceFilePending: true,
    addInvoiceFilePendingFilename: 'invoicePdf',
    addInvoiceFilePendingFilecontents: pdf,
  };
  dispatch(addInvoiceFilePendingSuccess(pdfPending));
};

export const addMessageFilePending = pdf => (dispatch, getState, sdk) => {
  const state = getState().TransactionPage;
  const txId = state.transactionRef.id.uuid;

  const pdfPending = {
    addMessageFilePending: true,
    addMessageFilePendingFilename: 'messagePdf',
    addMessageFilePendingFilecontents: pdf,
  };
  dispatch(addMessageFilePendingSuccess(pdfPending));
};
export const addInvoiceNumber = (formValues, form) => (dispatch, getState, sdk) => {
  const state = getState().TransactionPage;

  const txId = state.transactionRef.id.uuid;
  const { invoiceNumber, invoicePDF, iPdf } = formValues;
  const formData = new FormData();
  formData.append('transactionId', txId);
  formData.append('invoiceNumber', invoiceNumber);
  if (state.addInvoiceFilePending) {
    formData.append('invoicePdf', state.addInvoiceFilePendingFilecontents, 'invoicePdf');
  }

  if (state.addInvoiceFilePending) {
    addInvoiceNumberAndFileApi({ transactionId: txId, formData })
      .then(response => {
        window.location.reload(false);
      })
      .catch(e => {
        dispatch(sendReviewError(storableError(e)));
        throw e;
      });
  } else {
    addInvoiceNumberApi({ transactionId: txId, invoiceNumber })
      .then(response => {
        window.location.reload(false);
      })
      .catch(e => {
        dispatch(sendReviewError(storableError(e)));
        throw e;
      });
  }
};
export const addMessage = (formValues, form) => async (dispatch, getState, sdk) => {
  const state = getState().TransactionPage;

  const txId = state.transactionRef.id.uuid;
  const { message = '-', messageTitle = 'PDF', messagePDF, iPdf } = formValues;
  const formData = new FormData();
  formData.append('transactionId', txId);
  formData.append('messageTitle', messageTitle);
  formData.append('message', message);
  if (state.addMessageFilePending) {
    formData.append('messagePdf', state.addMessageFilePendingFilecontents, 'messagePdf');
  }
  dispatch(sendMessageRequest());
  return addMessageAndFileApi({ transactionId: txId, formData })
    .then(res => {
      return dispatch(fetchMessages(txId, 1)).then(() => {
        dispatch(sendMessageSuccess());
        return res.messageId;
      });
    })
    .catch(e => {
      dispatch(sendReviewError(storableError(e)));
      throw e;
    });
};

export const addDeliveryDate = formValues => (dispatch, getState, sdk) => {
  const state = getState().TransactionPage;
  const txId = state.transactionRef.id.uuid;
  const { shipping } = formValues;
  addDeliveryDateApi({ transactionId: txId, shipping })
    .then(response => {
      window.location.reload(false);
    })
    .catch(e => {
      dispatch(sendReviewError(storableError(e)));
      throw e;
    });
};

export const addCustomerDeliveryDate = formValues => (dispatch, getState, sdk) => {
  const state = getState().TransactionPage;
  const txId = state.transactionRef.id.uuid;
  const { shipping } = formValues;

  customerAddDeliveryDateApi({ transactionId: txId, shipping })
    .then(response => {
      window.location.reload(false);
    })
    .catch(e => {
      dispatch(sendReviewError(storableError(e)));
      throw e;
    });
};

export const addSupplierOrderNumber = formValues => (dispatch, getState, sdk) => {
  const state = getState().TransactionPage;
  const txId = state.transactionRef.id.uuid;
  const { supplierOrderNumber } = formValues;

  addSupplierOrderNumberApi({ transactionId: txId, supplierOrderNumber: supplierOrderNumber })
    .then(response => {
      window.location.reload(false);
    })
    .catch(e => {
      dispatch(sendReviewError(storableError(e)));
      throw e;
    });
};

export const sendMessage = (txId, message) => (dispatch, getState, sdk) => {
  dispatch(sendMessageRequest());

  return sdk.messages
    .send({ transactionId: txId, content: message })
    .then(response => {
      const messageId = response.data.data.id;

      // We fetch the first page again to add sent message to the page data
      // and update possible incoming messages too.
      // TODO if there're more than 100 incoming messages,
      // this should loop through most recent pages instead of fetching just the first one.
      return dispatch(fetchMessages(txId, 1))
        .then(() => {
          dispatch(sendMessageSuccess());
          return messageId;
        })
        .catch(() => dispatch(sendMessageSuccess()));
    })
    .catch(e => {
      dispatch(sendMessageError(storableError(e)));
      // Rethrow so the page can track whether the sending failed, and
      // keep the message in the form for a retry.
      throw e;
    });
};

const isNonEmpty = value => {
  return typeof value === 'object' || Array.isArray(value) ? !isEmpty(value) : !!value;
};

export const fetchNextTransitions = id => (dispatch, getState, sdk) => {
  dispatch(fetchTransitionsRequest());

  return sdk.processTransitions
    .query({ transactionId: id })
    .then(res => {
      dispatch(fetchTransitionsSuccess(res.data.data));
    })
    .catch(e => {
      dispatch(fetchTransitionsError(storableError(e)));
    });
};

export const fetchTransactionLineItems = ({ orderData, listingId, isOwnListing }) => dispatch => {
  dispatch(fetchLineItemsRequest());
  transactionLineItems({ orderData, listingId, isOwnListing })
    .then(response => {
      const lineItems = response.data;
      dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        orderData,
      });
    });
};

// loadData is a collection of async calls that need to be made
// before page has all the info it needs to render itself
export const loadData = params => (dispatch, getState) => {
  const txId = new UUID(params.id);
  const state = getState().TransactionPage;
  const txRef = state.transactionRef;
  const txRole = params.transactionRole;

  // In case a transaction reference is found from a previous
  // data load -> clear the state. Otherwise keep the non-null
  // and non-empty values which may have been set from a previous page.
  const initialValues = txRef ? {} : pickBy(state, isNonEmpty);
  dispatch(setInitialValues(initialValues));

  // Sale / order (i.e. transaction entity in API)
  return Promise.all([
    dispatch(fetchTransaction(txId, txRole)),
    dispatch(fetchMessages(txId, 1)),
    dispatch(fetchNextTransitions(txId)),
  ]);
};
