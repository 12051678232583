import React, { useRef } from 'react';
import _ from 'lodash';
import { bool, func, number, string } from 'prop-types';
import { Form as FinalForm, FormSpy } from 'react-final-form';

import config from '../../../config';
import { FormattedMessage, useIntl } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { orderQuantityIncrements } from '../../../util/data';
import { numberAtLeast, required } from '../../../util/validators';
import Price from '../../../containers/ListingPage/ListingDetails/Price';
import {
  Form,
  FieldSelect,
  FieldTextInput,
  InlineTextButton,
  PrimaryButton,
} from '../../../components';


import css from './DonateOrderForm.module.css';

const renderForm = (formRenderProps) => {
  const {
    // FormRenderProps from final-form
    handleSubmit,
    form: formApi,

    // Custom props passed to the form component
    intl,
    formId,
    currentStock,
    hasMultipleDeliveryMethods,
    listingId,
    isOwnListing,
    onFetchTransactionLineItems,
    onContactUser,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    values,
    pricing,
    formattedPricePerCase,
    formattedPricePerUnit,
    formattedPricePerKG,
    formattedPrice,
    description
  } = formRenderProps;
  const handleOnChange = (formValues) => {
    return;
    const { quantity: quantityRaw, deliveryMethod } = formValues.values;
    const quantity = Number.parseInt(quantityRaw, 10);
    const isBrowser = typeof window !== 'undefined';
    if (isBrowser && quantity && deliveryMethod && !fetchLineItemsInProgress) {
      onFetchTransactionLineItems({
        orderData: { quantity, deliveryMethod },
        listingId,
        isOwnListing,
      });
    }
  };

  // In case quantity and deliveryMethod are missing focus on that select-input.
  // Otherwise continue with the default handleSubmit function.
  const handleFormSubmit = (e) => {
    const { quantity, deliveryMethod, protectedData } = values || {};
    if (!quantity || quantity < 1) {
      e.preventDefault();
      // Blur event will show validator message
      formApi.blur('quantity');
      formApi.focus('quantity');
    } else {
      handleSubmit(e);
    }
  };

  const showContactUser = typeof onContactUser === 'function';

  const onClickContactUser = (e) => {
    e.preventDefault();
    onContactUser();
  };

  const quantityRequiredMsg = intl.formatMessage({
    id: 'ProductOrderForm.quantityRequired',
  });

  const hasStock = currentStock && currentStock > 0;

  const quantities = pricing.method.MoQ
    ? _.range(pricing.method.MoQ, currentStock)
    : [];

  const incrementQuantities = orderQuantityIncrements(pricing, currentStock);




  const hasNoStockLeft = typeof currentStock != null && currentStock === 0;
  const hasOneItemLeft = typeof currentStock != null && currentStock === 1;

  const submitInProgress = fetchLineItemsInProgress;
  const submitDisabled = !hasStock;
  
  return (
    <div>
      <div className={css.donationItemsInstructions}>
<h3>Thank you for donating.</h3>
<div>To create your donation offer: 
<ol><li>Add a list of items below, one item per line.</li>
<li>Click the green 'Send Donation Offer' button, we will let your charity know.</li>
<li>On the donation details page you can arrange collection or delivery via messaging.</li>
</ol></div></div>

<br></br>
      <div className={css.description}>
      Add your donaiton details below. 
      </div>

     {!isOwnListing ? 
      <Form onSubmit={handleFormSubmit}>
        <FormSpy subscription={{ values: true }} onChange={handleOnChange} />
        <FieldTextInput
            id={`${formId}.donationItems`}
            className={css.quantityField}
            name="donationItems"
            type="textarea"
            placeholder="List items here, one line per item."
            data-cy="textareaDonationItems"
            //validate={numberAtLeast(quantityRequiredMsg, 1)}
          />




          <FieldTextInput
            id={`${formId}.quantity`}
            className={css.quantityField}
            name="quantity"
            type="hidden"
            validate={numberAtLeast(quantityRequiredMsg, 1)}
            defaultValue={1}
          />
      

        <div className={css.submitButton}>
          <PrimaryButton
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            data-cy="buttonSendDonationOffer"
          >
            {hasStock ? (
              <FormattedMessage id="DonationOrderForm.ctaButton" />
            ) : (
              <FormattedMessage id="ProductOrderForm.ctaButtonNoStock" />
            )}
          </PrimaryButton>
        </div>
      </Form>
      : ''}
    </div>
  );
};

const DonateOrderForm = (props) => {
  const intl = useIntl();
  const {
    price,
    currentStock,
    pickupEnabled,
    shippingEnabled,
    pricing,
    formattedPrice,
    formattedPricePerCase,
    formattedPricePerKG,
    formattedPricePerUnit,
  } = props;

  // Should not happen for listings that go through EditListingWizard.
  // However, this might happen for imported listings.
  if (!pickupEnabled && !shippingEnabled) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.noDeliveryMethodSet" />
      </p>
    );
  }

  if (!price) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.listingPriceMissing" />
      </p>
    );
  }
  if (price.currency !== config.currency) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.listingCurrencyInvalid" />
      </p>
    );
  }
  const hasOneItemLeft = currentStock && currentStock === 1;
  const quantityMaybe = hasOneItemLeft ? { quantity: '1' } : {};
  const singleDeliveryMethodAvailableMaybe =
    shippingEnabled && !pickupEnabled
      ? { deliveryMethod: 'shipping' }
      : !shippingEnabled && pickupEnabled
      ? { deliveryMethod: 'pickup' }
      : {};
  const hasMultipleDeliveryMethods = pickupEnabled && shippingEnabled;
  const initialValues = {
    ...quantityMaybe,
    ...singleDeliveryMethodAvailableMaybe,
  };

  return (
    <FinalForm
      initialValues={initialValues}
      hasMultipleDeliveryMethods={hasMultipleDeliveryMethods}
      {...props}
      intl={intl}
      render={renderForm}
    />
  );
};

DonateOrderForm.defaultProps = {
  rootClassName: null,
  className: null,
  price: null,
  currentStock: null,
  listingId: null,
  isOwnListing: false,
  fetchLineItemsError: null,
  pricing: null,
  formattedPrice: null,
  description: null
};

DonateOrderForm.propTypes = {
  rootClassName: string,
  className: string,

  // form
  formId: string.isRequired,
  onSubmit: func.isRequired,

  // listing
  listingId: propTypes.uuid,
  price: propTypes.money,
  currentStock: number,
  isOwnListing: bool,

  // line items

  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // other
  onContactUser: func,
};

export default DonateOrderForm;
