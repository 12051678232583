import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TabNav, UnreadMessagesCounter, NotificationBadge } from '..';
import { parse } from '../../util/urlHelpers';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import css from './SideNavigation.module.css';

const SideNavigation = props => {
  const {
    className,
    rootClassName,
    tabRootClassName,
    providerNotificationCount,
    currentUserPermissions,
    ordersOnHold,
    isSuppliers,
    isCustomers,
    isOrders,
    isSales,
    isYourListings,
    setIsInviteSupplierModalOpen,
    setIsInviteBuyerModalOpen,
    isMessaging,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const sideTabs = [];

  const exisitingStatusFilters = {};
  const searchParams = location.search ? parse(location.search) : null;
  const sid = searchParams && searchParams?.sid ? parse(location.search).sid : null;
  const UnreadMessagesCount = <UnreadMessagesCounter></UnreadMessagesCounter>;
  const onHoldOrdersCount = ordersOnHold ? ordersOnHold.length : 0;

  const providerNotificationBadge =
    providerNotificationCount > 0 ? <NotificationBadge count={providerNotificationCount} /> : null;

  const onHoldNotificationBadge =
    onHoldOrdersCount > 0 ? (
      <NotificationBadge className={css.subNav} count={onHoldOrdersCount} />
    ) : null;

  if (sid) {
    exisitingStatusFilters['sid'] = sid;
  }

  if (currentUserPermissions?.buying?.isBuyer) {
    const statusTabs = [];
    const supplierTabs = [];
    supplierTabs.push(
      {
        text: <span>All</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'suppliers' },
        },
      },
      {
        text: <span class={css.onHoldSubNav}>Add Supplier</span>,

        handleClick: () => {
          setIsInviteSupplierModalOpen(true);
        },
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'suppliers' },
        },
      }
    );

    statusTabs.push(
      {
        text: <span>All</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'orders', dataCyId: 'all' },
          to: {
            search: `?${new URLSearchParams({
              last_transition:
                'transition/create-new-active-basket,transition/add-line-item-to-active-basket,transition/update-line-item-quantity-in-active-basket,transition/add-purchase-order-number,transition/purchased-payment-by-invoice,transition/system-accept-order,transition/submit-to-supplier,transition/supplier-accepts-order,transition/go-back-to-submitted,transition/place-on-hold,transition/remove-on-hold,transition/supplier-accepts-order-via-operator,transition/supplier-accepts-order-via-oneclick,transition/customer-add-delivery-date,transition/add-delivery-date,transition/add-supplier-order-number,transition/update-line-item-picked-details,transition/mark-as-picked,transition/go-back-to-ready-for-dispatch,transition/mark-as-dispatched,transition/add-transport-details,transition/go-back-to-accepted,transition/add-invoice-number-and-file,transition/cancel-from-dispatched-by-operator,transition/mark-as-delivered,transition/cancel-from-delivered-by-operator,transition/supplier-send-to-haulier,transition/haulier-accepts-order-via-oneclick-by-operator',
              ...exisitingStatusFilters,
            })}`,
          },
        },
      },
      {
        text: <span class={css.onHoldSubNav}>On Hold {onHoldNotificationBadge}</span>,

        visible: ordersOnHold.length > 0,
        linkProps: {
          name: 'InboxPage',

          params: { tab: 'orders', dataCyId: 'on-hold' },
          to: {
            search: `?${new URLSearchParams({
              last_transition: 'transition/place-on-hold',
              ...exisitingStatusFilters,
            })}`,
          },
        },
      },

      {
        text: <span>Active Basket</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'orders', dataCyId: 'active-baskets' },
          to: {
            search: `?${new URLSearchParams({
              last_transition:
                'transition/create-new-active-basket,transition/add-purchase-order-number,transition/customer-add-delivery-date',
              ...exisitingStatusFilters,
            })}`,
          },
        },
      },

      {
        text: <span>Awaiting Approval </span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'orders', dataCyId: 'awaiting-approval' },
          to: {
            search: `?${new URLSearchParams({
              last_transition: 'transition/submit-to-supplier,transition/go-back-to-submitted',
              ...exisitingStatusFilters,
            })}`,
          },
        },
      },
      {
        text: <span>Approved Orders </span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'orders', dataCyId: 'awaiting-approval' },
          to: {
            search: `?${new URLSearchParams({
              last_transition:
                'transition/supplier-accepts-order,transition/supplier-accepts-order-via-oneclick,transition/add-delivery-date,transition/update-line-item-picked-details,transition/supplier-accepts-order-via-operator,transition/remove-on-hold,transition/go-back-to-accepted,transition/update-line-item-quantity-in-active-basket,transition/add-invoice-number-and-file,supplier-send-to-haulier,',

              ...exisitingStatusFilters,
            })}`,
          },
        },
      },

      {
        text: <span>Awaiting dispatch</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'orders', dataCyId: 'ready-for-dispatch' },
          to: {
            search: `?${new URLSearchParams({
              last_transition:
                'transition/mark-as-picked,transition/go-back-to-ready-for-dispatch,transition/add-transport-details',
              ...exisitingStatusFilters,
            })}`,
          },
        },
      },
      {
        text: <span>In Transit</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'orders', dataCyId: 'in-transit' },
          to: {
            search: `?${new URLSearchParams({
              last_transition:
                'transition/mark-as-dispatched,transition/haulier-accepts-order-via-oneclick-by-operator,transition/supplier-send-to-haulier',
              ...exisitingStatusFilters,
            })}`,
          },
        },
      },

      {
        text: <span>Completed</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'orders', dataCyId: 'complete' },
          to: {
            search: `?${new URLSearchParams({
              last_transition: 'transition/mark-as-delivered',
              ...exisitingStatusFilters,
            })}`,
          },
        },
      }
    );

    sideTabs.push(
      {
        text: (
          <span>
            <FormattedMessage id="InboxPage.mySuppliersTitle" />
          </span>
        ),
        selected: isSuppliers,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'suppliers' },
        },

        subNav: supplierTabs,
      },
      {
        text: (
          <span>
            <FormattedMessage id="InboxPage.ordersTabTitle" />
          </span>
        ),
        selected: isOrders,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'orders', dataCyId: 'all' },
          to: {
            search: `?${new URLSearchParams({
              last_transition:
                'transition/create-new-active-basket,transition/add-line-item-to-active-basket,transition/update-line-item-quantity-in-active-basket,transition/add-purchase-order-number,transition/purchased-payment-by-invoice,transition/system-accept-order,transition/submit-to-supplier,transition/supplier-accepts-order,transition/go-back-to-submitted,transition/place-on-hold,transition/remove-on-hold,transition/supplier-accepts-order-via-operator,transition/supplier-accepts-order-via-oneclick,transition/customer-add-delivery-date,transition/add-delivery-date,transition/add-supplier-order-number,transition/update-line-item-picked-details,transition/mark-as-picked,transition/go-back-to-ready-for-dispatch,transition/mark-as-dispatched,transition/add-transport-details,transition/go-back-to-accepted,transition/add-invoice-number-and-file,transition/cancel-from-dispatched-by-operator,transition/mark-as-delivered,transition/cancel-from-delivered-by-operator,transition/supplier-send-to-haulier,transition/haulier-accepts-order-via-oneclick-by-operator',
              ...exisitingStatusFilters,
            })}`,
          },
        },
        subNav: statusTabs,
      }
    );
  }
  if (currentUserPermissions?.selling?.isSeller) {
    const statusTabs = [];

    const customersTabs = [];
    customersTabs.push(
      {
        text: <span>All</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'customers' },
        },
      },
      {
        text: <span class={css.onHoldSubNav}>Add New Customer</span>,

        handleClick: () => {
          setIsInviteBuyerModalOpen(true);
        },
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'customers' },
        },
      }
    );

    statusTabs.push(
      {
        text: <span>All</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'sales', dataCyId: 'all' },
          to: {
            search: `?${new URLSearchParams({
              last_transition:
                'transition/update-line-item-quantity-in-active-basket,transition/add-purchase-order-number,transition/purchased-payment-by-invoice,transition/system-accept-order,transition/submit-to-supplier,transition/supplier-accepts-order,transition/go-back-to-submitted,transition/place-on-hold,transition/remove-on-hold,transition/supplier-accepts-order-via-operator,transition/supplier-accepts-order-via-oneclick,transition/customer-add-delivery-date,transition/add-delivery-date,transition/add-supplier-order-number,transition/update-line-item-picked-details,transition/mark-as-picked,transition/go-back-to-ready-for-dispatch,transition/mark-as-dispatched,transition/add-transport-details,transition/go-back-to-accepted,transition/add-invoice-number-and-file,transition/cancel-from-dispatched-by-operator,transition/mark-as-delivered,transition/cancel-from-delivered-by-operator,transition/supplier-send-to-haulier,transition/haulier-accepts-order-via-oneclick-by-operator',
              ...exisitingStatusFilters,
            })}`,
          },
        },
      },

      {
        text: <span>New</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'sales', dataCyId: 'new' },
          to: {
            search: `?${new URLSearchParams({
              last_transition: 'transition/submit-to-supplier,transition/go-back-to-submitted',
              ...exisitingStatusFilters,
            })}`,
          },
        },
      },

      {
        text: <span>Approved</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'sales', dataCyId: 'approved' },
          to: {
            search: `?${new URLSearchParams({
              last_transition:
                'transition/supplier-accepts-order,transition/supplier-accepts-order-via-oneclick,transition/add-delivery-date,transition/update-line-item-picked-details,transition/supplier-accepts-order-via-operator,transition/update-line-item-quantity-in-active-basket,transition/remove-on-hold,transition/go-back-to-accepted,transition/add-invoice-number-and-file',
              ...exisitingStatusFilters,
            })}`,
          },
        },
      },
      {
        text: <span class={css.onHoldSubNav}>On Hold {onHoldNotificationBadge}</span>,
        visible: ordersOnHold.length > 0,
        linkProps: {
          name: 'InboxPage',

          params: { tab: 'sales', dataCyId: 'on-hold' },
          to: {
            search: `?${new URLSearchParams({
              last_transition: 'transition/place-on-hold',
              ...exisitingStatusFilters,
            })}`,
          },
        },
      },
      {
        text: <span>Ready to dispatch </span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'sales', dataCyId: 'ready-for-dispatch' },
          to: {
            search: `?${new URLSearchParams({
              last_transition:
                'transition/mark-as-picked,transition/go-back-to-ready-for-dispatch,supplier-send-to-haulier,transition/add-transport-details',
              ...exisitingStatusFilters,
            })}`,
          },
        },
      },

      {
        text: <span>In Transit</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'sales', dataCyId: 'in-transit' },
          to: {
            search: `?${new URLSearchParams({
              last_transition:
                'transition/mark-as-dispatched,transition/haulier-accepts-order-via-oneclick-by-operator,transition/supplier-send-to-haulier',
              ...exisitingStatusFilters,
            })}`,
          },
        },
      },
      {
        text: <span>Completed</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'sales', dataCyId: 'complete' },
          to: {
            search: `?${new URLSearchParams({
              last_transition: 'transition/mark-as-delivered',
              ...exisitingStatusFilters,
            })}`,
          },
        },
      }
    );

    sideTabs.push(
      {
        text: <span>Customers</span>,
        selected: isCustomers,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'customers' },
        },
        // subNav: customersTabs,
      },

      {
        text: (
          <span>
            <FormattedMessage id="InboxPage.salesTabTitle" />
            {providerNotificationBadge}
          </span>
        ),
        selected: isSales,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'sales', dataCyId: 'all' },
          to: {
            search: `?${new URLSearchParams({
              last_transition:
                'transition/update-line-item-quantity-in-active-basket,transition/add-purchase-order-number,transition/purchased-payment-by-invoice,transition/system-accept-order,transition/submit-to-supplier,transition/supplier-accepts-order,transition/go-back-to-submitted,transition/place-on-hold,transition/remove-on-hold,transition/supplier-accepts-order-via-operator,transition/supplier-accepts-order-via-oneclick,transition/customer-add-delivery-date,transition/add-delivery-date,transition/add-supplier-order-number,transition/update-line-item-picked-details,transition/mark-as-picked,transition/go-back-to-ready-for-dispatch,transition/mark-as-dispatched,transition/add-transport-details,transition/go-back-to-accepted,transition/add-invoice-number-and-file,transition/cancel-from-dispatched-by-operator,transition/mark-as-delivered,transition/cancel-from-delivered-by-operator',
              ...exisitingStatusFilters,
            })}`,
          },
        },

        subNav: statusTabs,
      },
      {
        text: (
          <span>
            <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
          </span>
        ),
        selected: isYourListings,
        linkProps: {
          name: 'SearchPage',
          params: { tab: 'your-listing' },
        },
      }
    );
  }
  if (currentUserPermissions?.donating?.isDonor) {
    const donationStatusTabs = [];
    donationStatusTabs.push(
      {
        text: <span>All</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'donator-history' },
          to: {
            search: `?${new URLSearchParams({
              last_transition:
                'transition/send-new-donation,transition/donee-rejects-offer-from-offered,transition/donee-accepts-offer,transition/donee-accepts-offer-from-rejected,transition/donee-accepts-offer-via-operator,transition/donee-marks-as-received',
              ...exisitingStatusFilters,
            })}`,
          },
        },
      },
      {
        text: <span>New</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'donator-history' },
          to: {
            search: `?${new URLSearchParams({
              last_transition: 'transition/send-new-donation',
              ...exisitingStatusFilters,
            })}`,
          },
        },
      },
      {
        text: <span>Accepted</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'donator-history' },
          to: {
            search: `?${new URLSearchParams({
              last_transition:
                'transition/donee-accepts-offer,transition/donee-accepts-offer-via-operator',
              ...exisitingStatusFilters,
            })}`,
          },
        },
      },
      {
        text: <span>Rejected</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'donator-history' },
          to: {
            search: `?${new URLSearchParams({
              last_transition: 'transition/donee-rejects-offer-from-offered',
              ...exisitingStatusFilters,
            })}`,
          },
        },
      },
      {
        text: <span>Completed</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'donator-history' },
          to: {
            search: `?${new URLSearchParams({
              last_transition: 'transition/donee-marks-as-received',
              ...exisitingStatusFilters,
            })}`,
          },
        },
      }
    );

    sideTabs.push({
      text: (
        <span>
          <FormattedMessage id="InboxPage.donateTabTitle" />
        </span>
      ),
      selected: false,
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'donate' },
        to: {
          search: `?${new URLSearchParams({
            last_transition:
              'transition/send-new-donation,transition/donee-rejects-offer-from-offered,transition/donee-accepts-offer,transition/donee-accepts-offer-from-rejected,transition/donee-accepts-offer-via-operator,transition/donee-marks-as-received',
            ...exisitingStatusFilters,
          })}`,
        },
      },
      subNav: donationStatusTabs,
    });
  }
  if (currentUserPermissions?.donationReceiving?.isDonee) {
    const donationStatusTabs = [];
    donationStatusTabs.push(
      {
        text: <span>All</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'received-donations' },
          to: {
            search: `?${new URLSearchParams({
              last_transition:
                'transition/send-new-donation,transition/donee-rejects-offer-from-offered,transition/donee-accepts-offer,transition/donee-accepts-offer-from-rejected,transition/donee-accepts-offer-via-operator,transition/donee-marks-as-received',
              ...exisitingStatusFilters,
            })}`,
          },
        },
      },
      {
        text: <span>New</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'received-donations' },
          to: {
            search: `?${new URLSearchParams({
              last_transition: 'transition/send-new-donation',
              ...exisitingStatusFilters,
            })}`,
          },
        },
      },
      {
        text: <span>Accepted</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'received-donations' },
          to: {
            search: `?${new URLSearchParams({
              last_transition:
                'transition/donee-accepts-offer,transition/donee-accepts-offer-via-operator',
              ...exisitingStatusFilters,
            })}`,
          },
        },
      },
      {
        text: <span>Rejected</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'received-donations' },
          to: {
            search: `?${new URLSearchParams({
              last_transition: 'transition/donee-rejects-offer-from-offered',
              ...exisitingStatusFilters,
            })}`,
          },
        },
      },
      {
        text: <span>Completed</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'received-donations' },
          to: {
            search: `?${new URLSearchParams({
              last_transition: 'transition/donee-marks-as-received',
              ...exisitingStatusFilters,
            })}`,
          },
        },
      }
    );
    sideTabs.push({
      text: (
        <span>
          <FormattedMessage id="InboxPage.donateTabTitle" />
        </span>
      ),
      selected: false,
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'received-donations' },
        to: {
          search: `?${new URLSearchParams({
            last_transition:
              'transition/send-new-donation,transition/donee-rejects-offer-from-offered,transition/donee-accepts-offer,transition/donee-accepts-offer-from-rejected,transition/donee-accepts-offer-via-operator,transition/donee-marks-as-received',
            ...exisitingStatusFilters,
          })}`,
        },
      },

      subNav: donationStatusTabs,
    });
  }
  // sideTabs.push({
  //   text: <span>Messages {UnreadMessagesCount}</span>,
  //   selected: isMessaging,
  //   linkProps: {
  //     name: 'MessagingPage',
  //     params: { tab: 'messages' },
  //   },
  // });
  return <TabNav rootClassName={css.tabs} tabRootClassName={css.tab} tabs={sideTabs} />;
};

SideNavigation.defaultProps = {
  className: null,
  rootClassName: null,
  containerClassName: null,
};

const { node, string } = PropTypes;

SideNavigation.propTypes = {
  children: node.isRequired,
  className: string,
  rootClassName: string,
  containerClassName: string,
};

export default SideNavigation;
