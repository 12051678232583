import { ensureTransaction } from './data';

export const TRANSITION_SEND_NEW_DONATION = 'transition/send-new-donation';

export const TRANSITION_DONEE_ACCEPTS_OFFER = 'transition/donee-accepts-offer';
export const TRANSITION_DONEE_ACCEPTS_OFFER_AFTER_REJECTED =
  'transition/donee-accepts-offer-from-rejected';

export const TRANSITION_DONEE_REJECTS_OFFER = 'transition/donee-rejects-offer-from-offered';
// A customer can add many line items.
export const TRANSITION_DONEE_ACCEPTS_OFFER_VIA_OPERATOR =
  'transition/donee-accepts-offer-via-operator';
// A customer can add many line items.
export const TRANSITION_CANCEL_FROM_OFFERED_BY_BUYER = 'transition/cancel-from-offered-by-buyer';

export const TRANSITION_CANCEL_FROM_OFFERED_BY_OPERATOR =
  'transition/cancel-from-offered-by-operator';

export const TRANSITION_DONEE_MARKS_AS_RECEIVED = 'transition/donee-marks-as-received';

export const TRANSITION_CANCEL_FROM_ACCEPTED_BY_DONEE_BY_OPERATOR =
  'transition/cancel-from-accepted-by-donee-by-operator';

// Roles of actors that perform transaction transitions
export const TX_TRANSITION_ACTOR_CUSTOMER = 'customer';
export const TX_TRANSITION_ACTOR_PROVIDER = 'provider';
export const TX_TRANSITION_ACTOR_SYSTEM = 'system';
export const TX_TRANSITION_ACTOR_OPERATOR = 'operator';

export const TX_TRANSITION_ACTORS = [
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
  TX_TRANSITION_ACTOR_SYSTEM,
  TX_TRANSITION_ACTOR_OPERATOR,
];

const STATE_INITIAL = 'initial';
const STATE_CANCELLED_BY_BUYER = 'cancelled-by-buyer';
const STATE_REJECTED_BY_DONEE = 'rejected-by-donee';
const STATE_OFFERED = 'offered';
const STATE_ACCEPTED_BY_DONEE = 'accepted-by-donee';
const STATE_CANCELLED_BY_OPERATOR = 'cancelled_by_operator';
const STATE_RECEIVED = 'received';

const stateDescription = {
  id: 'donation/donation',

  initial: STATE_INITIAL,

  // States
  states: {
    [STATE_INITIAL]: {
      on: {
        [TRANSITION_SEND_NEW_DONATION]: STATE_OFFERED,
      },
    },
    [STATE_OFFERED]: {
      on: {
        [TRANSITION_CANCEL_FROM_OFFERED_BY_BUYER]: STATE_CANCELLED_BY_BUYER,
        [TRANSITION_DONEE_ACCEPTS_OFFER]: STATE_ACCEPTED_BY_DONEE,
        [TRANSITION_DONEE_ACCEPTS_OFFER_VIA_OPERATOR]: STATE_ACCEPTED_BY_DONEE,
        [TRANSITION_CANCEL_FROM_OFFERED_BY_OPERATOR]: STATE_CANCELLED_BY_OPERATOR,
        [TRANSITION_DONEE_REJECTS_OFFER]: STATE_REJECTED_BY_DONEE,
      },
    },

    [STATE_REJECTED_BY_DONEE]: {
      on: {
        [TRANSITION_DONEE_ACCEPTS_OFFER_AFTER_REJECTED]: STATE_ACCEPTED_BY_DONEE,
      },
    },
    [STATE_ACCEPTED_BY_DONEE]: {
      on: {
        [TRANSITION_DONEE_MARKS_AS_RECEIVED]: STATE_RECEIVED,
        [TRANSITION_CANCEL_FROM_ACCEPTED_BY_DONEE_BY_OPERATOR]: STATE_CANCELLED_BY_OPERATOR,
      },
    },
    [STATE_RECEIVED]: {},
  },
};

// Note: currently we assume that state description doesn't contain nested states.
const statesFromStateDescription = description => description.states || {};

// Note: currently we assume that state description doesn't contain nested states.
const restrictedStatesFromStateDescription = (description, role) => {
  let states = {};
  for (const [key, value] of Object.entries(description.states)) {
    if (!value.restricted || role == value.restricted) {
      states[key] = value;
    }
  }
  return states;
};

// Get all the transitions from states object in an array
const getTransitions = (states, role = null) => {
  const stateNames = Object.keys(states);

  const transitionsReducer = (transitionArray, name) => {
    const stateTransitions = states[name] && states[name].on;

    const transitionKeys = stateTransitions ? Object.keys(stateTransitions) : [];
    return [
      ...transitionArray,
      ...transitionKeys.map(key => ({ key, value: stateTransitions[key] })),
    ];
  };

  return stateNames.reduce(transitionsReducer, []);
};

// This is a list of all the transitions that this app should be able to handle.
export const TRANSITIONS = getTransitions(
  statesFromStateDescription(stateDescription, 'supplier')
).map(t => t.key);

export const DONEE_TRANSITIONS = getTransitions(
  restrictedStatesFromStateDescription(stateDescription, 'supplier')
).map(t => t.key);

export const DONOR_TRANSITIONS = getTransitions(
  restrictedStatesFromStateDescription(stateDescription, 'buyer')
).map(t => t.key);

// This function returns a function that has given stateDesc in scope chain.
const getTransitionsToStateFn = stateDesc => state =>
  getTransitions(statesFromStateDescription(stateDesc))
    .filter(t => t.value === state)
    .map(t => t.key);

// Get all the transitions that lead to specified state.
const getTransitionsToState = getTransitionsToStateFn(stateDescription);

/**
 * Helper functions to figure out if transaction is in a specific state.
 * State is based on lastTransition given by transaction object and state description.
 */

const txLastTransition = tx => ensureTransaction(tx).attributes.lastTransition;

export const txIsBasketReady = tx =>
  getTransitionsToState(STATE_BASKET_READY).includes(txLastTransition(tx));

export const dxIsAwaitingDoneeAcceptance = tx => {
  if (getTransitionsToState(STATE_OFFERED).includes(txLastTransition(tx))) {
    return true;
  }
};
export const dxDoneeAcceptedOffer = tx => {
  if (getTransitionsToState(STATE_ACCEPTED_BY_DONEE).includes(txLastTransition(tx))) {
    return true;
  }
};

export const dxDoneeRejectedOffer = tx => {
  if (getTransitionsToState(STATE_REJECTED_BY_DONEE).includes(txLastTransition(tx))) {
    return true;
  }
};

export const dxDoneeReceivedDonation = tx => {
  if (getTransitionsToState(STATE_RECEIVED).includes(txLastTransition(tx))) {
    return true;
  }
};

export const txIsSupplierApprovedOrder = tx =>
  getTransitionsToState(STATE_ACCEPTED_BY_SUPPLIER).includes(txLastTransition(tx));
export const txIsSupplierRejectedOrder = tx =>
  getTransitionsToState(STATE_REJECTED_BY_DONEE).includes(txLastTransition(tx));

export const txIsAwaitingDispatch = tx =>
  getTransitionsToState(STATE_READY_FOR_DISPATCH).includes(txLastTransition(tx));

export const txIsAwaitingPick = tx =>
  getTransitionsToState(STATE_ACCEPTED_BY_SUPPLIER).includes(txLastTransition(tx));

export const txIsInEditableState = (tx, isProvider) =>
  isProvider
    ? [
        TRANSITION_SUPPLIER_ACCEPTS_ORDER,
        TRANSITION_SUPPLIER_ACCEPTS_ORDER_VIA_OPERATOR,
        TRANSITION_UPDATE_LINE_ITEM_PICKED_DETAILS,
      ].includes(txLastTransition(tx))
    : false;

export const txIsInTransit = tx => {
  return getTransitionsToState(STATE_DISPATCHED).includes(txLastTransition(tx));
};

export const txIsEnquired = tx =>
  getTransitionsToState(STATE_ENQUIRY).includes(txLastTransition(tx));

export const txPurchasedPaymentByInvoice = tx =>
  getTransitionsToState(STATE_SUBMITTED_TO_SUPPLIER).includes(txLastTransition(tx));

export const txIsPaymentPending = tx =>
  getTransitionsToState(STATE_PENDING_PAYMENT).includes(txLastTransition(tx));

export const txIsPaymentExpired = tx =>
  getTransitionsToState(STATE_PAYMENT_EXPIRED).includes(txLastTransition(tx));

export const txIsPurchased = tx =>
  getTransitionsToState(STATE_PURCHASED).includes(txLastTransition(tx));

export const txIsCanceled = tx =>
  getTransitionsToState(STATE_CANCELED).includes(txLastTransition(tx));
export const txIsRejected = tx =>
  getTransitionsToState(STATE_REJECTED_BY_DONEE).includes(txLastTransition(tx));

export const txIsDelivered = tx =>
  getTransitionsToState(STATE_DELIVERED).includes(txLastTransition(tx));

export const txIsReceived = tx =>
  getTransitionsToState(STATE_RECEIVED).includes(txLastTransition(tx));

export const txIsDisputed = tx =>
  getTransitionsToState(STATE_DISPUTED).includes(txLastTransition(tx));

export const txIsCompleted = tx =>
  getTransitionsToState(STATE_COMPLETED).includes(txLastTransition(tx));

export const txIsReviewedByProvider = tx =>
  getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(txLastTransition(tx));

export const txIsInFirstReview = tx => firstReviewTransitions.includes(txLastTransition(tx));

export const txIsInFirstReviewBy = (tx, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(txLastTransition(tx))
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(txLastTransition(tx));

export const txIsReviewed = tx =>
  getTransitionsToState(STATE_REVIEWED).includes(txLastTransition(tx));

/**
 * Helper functions to figure out if transaction has passed a given state.
 * This is based on transitions history given by transaction object.
 */

const txTransitions = tx => ensureTransaction(tx).attributes.transitions || [];
const hasPassedTransition = (transitionName, tx) =>
  !!txTransitions(tx).find(t => t.transition === transitionName);

const hasPassedStateFn = state => tx =>
  getTransitionsToState(state).filter(t => hasPassedTransition(t, tx)).length > 0;

// Helper function to check if the transaction has passed a certain state

/**
 * Other transaction related utility functions
 */

export const transitionIsReviewed = transition =>
  getTransitionsToState(STATE_REVIEWED).includes(transition);

export const transitionIsFirstReviewedBy = (transition, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(transition)
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(transition);

export const getReview1Transition = isCustomer =>
  isCustomer ? TRANSITION_REVIEW_1_BY_CUSTOMER : TRANSITION_REVIEW_1_BY_PROVIDER;

export const getReview2Transition = isCustomer =>
  isCustomer ? TRANSITION_REVIEW_2_BY_CUSTOMER : TRANSITION_REVIEW_2_BY_PROVIDER;

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    TRANSITION_SEND_NEW_DONATION,
    TRANSITION_CANCEL_FROM_OFFERED_BY_BUYER,
    TRANSITION_DONEE_ACCEPTS_OFFER,
    TRANSITION_DONEE_ACCEPTS_OFFER_AFTER_REJECTED,
    TRANSITION_DONEE_ACCEPTS_OFFER_VIA_OPERATOR,
    TRANSITION_CANCEL_FROM_OFFERED_BY_OPERATOR,
    TRANSITION_DONEE_MARKS_AS_RECEIVED,
    TRANSITION_CANCEL_FROM_ACCEPTED_BY_DONEE_BY_OPERATOR,
    TRANSITION_DONEE_REJECTS_OFFER,
  ].includes(transition);
};

export const getUserTxRole = (currentUserId, transaction) => {
  const tx = ensureTransaction(transaction);
  const customer = tx.customer;
  if (currentUserId && currentUserId.uuid && tx.id && customer.id) {
    // user can be either customer or provider
    return currentUserId.uuid === customer.id.uuid
      ? TX_TRANSITION_ACTOR_CUSTOMER
      : TX_TRANSITION_ACTOR_PROVIDER;
  } else {
    throw new Error(`Parameters for "userIsCustomer" function were wrong.
      currentUserId: ${currentUserId}, transaction: ${transaction}`);
  }
};

export const txRoleIsProvider = userRole => userRole === TX_TRANSITION_ACTOR_PROVIDER;
export const txRoleIsCustomer = userRole => userRole === TX_TRANSITION_ACTOR_CUSTOMER;

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
  return [
    TRANSITION_SEND_NEW_DONATION,
    TRANSITION_CANCEL_FROM_OFFERED_BY_BUYER,
    TRANSITION_DONEE_ACCEPTS_OFFER,
    TRANSITION_DONEE_ACCEPTS_OFFER_AFTER_REJECTED,
    TRANSITION_DONEE_ACCEPTS_OFFER_VIA_OPERATOR,
    TRANSITION_CANCEL_FROM_OFFERED_BY_OPERATOR,
    TRANSITION_DONEE_MARKS_AS_RECEIVED,
    TRANSITION_CANCEL_FROM_ACCEPTED_BY_DONEE_BY_OPERATOR,
    TRANSITION_DONEE_REJECTS_OFFER,
  ].includes(transition);
};
