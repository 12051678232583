import React, { Component } from 'react';
import { string, bool, func } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { formatMoney } from '../../../util/currency';

import {
  getSoldByMethod,
  getTotalWeightForWeightedProduct,
  getTotalPickedWeight,
  convertToHumanReadableKgString,
  getOriginalOrderedQuantity,
} from '../../../util/data';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { LINE_ITEMS, LINE_ITEM_LISTING, propTypes } from '../../../util/types';

import { Form, FieldTextInput, PrimaryButton } from '../..';

import css from './LineItemListingSupplierEditableForm.module.css';

const BLUR_TIMEOUT_MS = 100;
class LineItemListingSupplierEditableFormComponent extends Component {
  constructor(props) {
    super(props);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.blurTimeoutId = null;
  }

  handleFocus() {
    this.props.onFocus();
    window.clearTimeout(this.blurTimeoutId);
  }

  handleBlur() {
    // We only trigger a blur if another focus event doesn't come
    // within a timeout. This enables keeping the focus synced when
    // focus is switched between the message area and the submit
    // button.
    this.blurTimeoutId = window.setTimeout(() => {
      this.props.onBlur();
    }, BLUR_TIMEOUT_MS);
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            messagePlaceholder,
            handleSubmit,
            inProgress,
            sendMessageError,
            invalid,
            form,
            formId,
            lineItems,
            isProvider,
            intl,
            transaction,
          } = formRenderProps;

          // resolve unknown non-reversal line items
          const allItems = lineItems.filter(item => item.code.indexOf(LINE_ITEM_LISTING) > -1);

          const items = isProvider
            ? allItems.filter(item => item.includeFor.includes('provider'))
            : allItems.filter(item => item.includeFor.includes('customer'));

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = inProgress;
          const submitDisabled = invalid || submitInProgress;

          return (
            <div>
              <Form
                key="editLineItems"
                className={classes}
                onSubmit={values => handleSubmit(values, form)}
              >
                {items.map((item, i) => {
                  const quantity = item.quantity;
                  const listing = item.listing;
                  const soldByMethod = getSoldByMethod(item);
                  const pickedWeight = getTotalPickedWeight(transaction, item);

                  const label =
                    quantity && quantity > 1 ? `${listing?.attributes.title} x ${quantity}` : '';
                  const quantityLabel = quantity && quantity > 0 ? `${quantity}` : '';

                  const convertWeightToOtherForDisplay = listing.attributes.publicData.pricing
                    .method?.convertWeightToOtherForDisplay
                    ? listing.attributes.publicData.pricing.method.convertWeightToOtherForDisplay
                    : false;

                  const quantityLabelDisplay = convertWeightToOtherForDisplay
                    ? Math.floor(
                        quantityLabel /
                          (listing.attributes.publicData.pricing.method.unitWeight / 1000)
                      )
                    : quantityLabel;
                  const formattedTotal = formatMoney(intl, item.lineTotal);

                  const unitType = !!listing.attributes?.publicData?.pricing?.method?.unitType
                    ? listing.attributes.publicData.pricing.method.unitType
                    : '-';
                  const formattedUnitPrice = formatMoney(intl, item.unitPrice);

                  const originalQuantity = getOriginalOrderedQuantity(transaction, item);

                  return (
                    <div key={`${i}-item.code`} className={css.lineItem}>
                      <div className={css.itemLabel}>
                        <span className={css.itemLabel}>
                          {label}
                          {soldByMethod.nickName == 'by-weight' ? (
                            <div>
                              <div className={css.lineItemOrderedWeight}>
                                <span className={css.lineItemOrderedWeight}>
                                  {LineItemListingSupplierEditableForm.sendFailed}
                                  <FormattedMessage id="TransactionPanel.orderedWeight" />
                                </span>
                                <span className={css.lineItemOrderedWeight}>
                                  {' '}
                                  -&nbsp;
                                  {convertToHumanReadableKgString(
                                    getTotalWeightForWeightedProduct(transaction, item)
                                  )}
                                </span>
                              </div>
                              <div className={css.lineItemPickedItemLabel}>
                                {pickedWeight ? (
                                  <div>
                                    <span className={css.lineItemOrderedWeight}>
                                      <FormattedMessage id="TransactionPanel.pickedWeight" />
                                    </span>
                                    <span className={css.lineItemOrderedWeight}>
                                      {' '}
                                      - &nbsp;
                                      {convertToHumanReadableKgString(pickedWeight)}
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                              <div className={css.lineItemPickedItemUpdate}>
                                <span className={css.itemLabel}>
                                  <FieldTextInput
                                    type="textarea"
                                    id={item.code ? `${item.code}.weight` : 'weight'}
                                    name={item.code ? `lineItems[${item.code}].weight` : 'weight'}
                                    placeholder={`Picked weight in KG`}
                                    onFocus={this.handleFocus}
                                    onBlur={this.handleBlur}
                                    className={css.updatePickedWeight}
                                    data-cy="textInputUpdatePickedWeight"
                                  />

                                  <PrimaryButton
                                    rootClassName={css.submitButton}
                                    inProgress={submitInProgress}
                                    disabled={submitDisabled}
                                    onFocus={this.handleFocus}
                                    onBlur={this.handleBlur}
                                    data-cy="buttonInputUpdatePickedWeight"
                                  >
                                    Updates
                                  </PrimaryButton>
                                </span>
                              </div>
                            </div>
                          ) : null}

                          {soldByMethod.nickName == 'by-unit' ? (
                            <div>
                              <div className={css.lineItemOrderedWeight}>
                                <span className={css.lineItemOrderedWeight}>
                                  {LineItemListingSupplierEditableForm.sendFailed}
                                  <FormattedMessage id="TransactionPanel.orderedQuantity" />
                                </span>
                                <span className={css.lineItemOrderedWeight}>
                                  {' '}
                                  -&nbsp;
                                  {originalQuantity}
                                </span>
                              </div>
                              <div className={css.lineItemPickedItemLabel}></div>
                              <div className={css.lineItemPickedItemUpdate}>
                                <span className={css.itemLabel}>
                                  <FieldTextInput
                                    type="textarea"
                                    id={item.code ? `${item.code}.quantity` : 'quantity'}
                                    name={
                                      item.code ? `lineItems[${item.code}].quantity` : 'quantity'
                                    }
                                    placeholder={`Picked Quantity`}
                                    onFocus={this.handleFocus}
                                    onBlur={this.handleBlur}
                                    className={css.updatePickedQuantity}
                                    data-cy="textInputUpdatePickedQuantity"
                                  />

                                  <PrimaryButton
                                    rootClassName={css.submitButton}
                                    inProgress={submitInProgress}
                                    disabled={submitDisabled}
                                    onFocus={this.handleFocus}
                                    onBlur={this.handleBlur}
                                    data-cy="buttonUpdatePickedQuantity"
                                  >
                                    Update
                                  </PrimaryButton>
                                </span>
                              </div>
                            </div>
                          ) : null}
                        </span>
                      </div>
                      <div className={css.itemQuantity}>{quantityLabelDisplay}</div>
                      <div className={css.itemUnits}>{unitType}</div>
                      <div className={css.itemUnitCost}>{formattedUnitPrice}</div>

                      <div className={css.itemValue}>{formattedTotal}</div>
                    </div>
                  );
                })}

                <div className={css.submitContainer}>
                  <div className={css.errorContainer}>
                    {sendMessageError ? (
                      <p className={css.error}>
                        <FormattedMessage id="LineItemListingEditableForm.sendFailed" />
                      </p>
                    ) : null}
                  </div>
                </div>
              </Form>
            </div>
          );
        }}
      />
    );
  }
}

LineItemListingSupplierEditableFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  messagePlaceholder: null,
  onFocus: () => null,
  onBlur: () => null,
  sendMessageError: null,
};

LineItemListingSupplierEditableFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,

  messagePlaceholder: string,
  onSubmit: func.isRequired,
  onFocus: func,
  onBlur: func,
  sendMessageError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const LineItemListingSupplierEditableForm = compose(injectIntl)(
  LineItemListingSupplierEditableFormComponent
);

LineItemListingSupplierEditableForm.displayName = 'LineItemListingEditableForm';

export default LineItemListingSupplierEditableForm;
