import React, { Component } from 'react';
import { string, func, bool } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { parse, stringify } from '../../util/urlHelpers';
import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';

import InlinePrice from '../../containers/ListingPage/ListingDetails/InlinePrice';
import InlineProductOrderForm from '../../components/OrderPanel/ProductOrderForm/InlineProductOrderForm';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.amount == '') {
    return {};
  }
  if (price) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardWithInlineOrderComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    onSubmit,
    category,
  } = props;

  const { bid = null } = parse(location.search);

  const ListingPageView = bid ? 'ListingPageSupplierView' : 'ListingPage';

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, pricePerCase, pricePerKG, pricePerUnit } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const description = !!listing.attributes.publicData?.searchPageDescription
    ? listing.attributes.publicData.searchPageDescription
    : null;
  const { pickupEnabled, shippingEnabled, pricing } = listing?.attributes?.publicData || {};
  const currentStock = listing.currentStock?.attributes?.quantity;

  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const { formattedPrice, priceTitle } = priceData(price, intl);
  const unitType = config.lineItemUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  const unitTranslationKey =
    pricing?.method?.unitType && pricing.method.unitType != ''
      ? `per ${pricing.method.unitType}`
      : isDaily
      ? 'ListingCard.perDay'
      : 'ListingCard.perUnit';

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  const dataCy = !!pricing?.method.nickName
    ? `linkProductCard-${pricing.method.nickName}`
    : 'linkProductCard';

  const titleParts = title.split('(');
  const titleDescription = titleParts[0];
  const titleQuantity = titleParts[1] ? titleParts[1].replace(')', '') : '';

  const pricePerCaseString = !!pricePerCase ? `(${formatMoney(intl, pricePerCase)} per case)` : '';

  const pricePerKGString = !!pricePerKG ? `(${formatMoney(intl, pricePerKG)} per KG)` : '';
  const pricePerUnitString = !!pricePerUnit ? `(${formatMoney(intl, pricePerUnit)} per unit)` : '';

  const showListing =
    category == null || category.name == listing.attributes?.publicData?.productCategory
      ? true
      : false;

  const productSpecContent = listing.attributes?.publicData?.productSpecPdfUrl ? (
    <div className={css.productSpecPdf}>
      <a href={listing.attributes.publicData.productSpecPdfUrl} target="_blank">
        Product Spec PDF{' '}
        <img src="https://platter-product-assets-live.s3.eu-west-1.amazonaws.com/dl2.webp" />
      </a>
    </div>
  ) : null;

  if (showListing) {
    return (
      <div key={dataCy}>
        <NamedLink
          className={classes}
          name={ListingPageView}
          params={{ id, slug, bid }}
          dataCy={dataCy}
          dataCyId={id}
          title={title}
        >
          <AspectRatioWrapper
            className={css.aspectRatioWrapper}
            width={aspectWidth}
            height={aspectHeight}
            {...setActivePropsMaybe}
          >
            <LazyImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={variants}
              sizes={renderSizes}
            />
          </AspectRatioWrapper>
          <div className={css.info}>
            <div className={css.title}>
              {richText(titleDescription, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,

                length: 24,
              })}
            </div>
            <div className={css.subTitle}>
              {richText(titleQuantity, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </div>

            <div className={css.subTitleDisplayPrice}>
              {richText(pricePerCaseString, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
              {richText(pricePerKGString, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
              {richText(pricePerUnitString, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </div>

            <div className={css.price}>
              <InlinePrice formattedPrice={formattedPrice} pricing={pricing} />

              {config.listing.showUnitTypeTranslations ? (
                <div className={css.perUnit}>
                  <FormattedMessage id={unitTranslationKey} />
                </div>
              ) : null}
            </div>
            <div className={css.mainInfo}>
              <div className={css.description}>
                <div className={!productSpecContent ? css.noProductSpec : null}>
                  {richText(description, {
                    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                    longWordClass: css.longWord,

                    length: 83,
                  })}
                </div>
              </div>
            </div>
          </div>
        </NamedLink>
        {productSpecContent}
        <div>
          <InlineProductOrderForm
            formId={id}
            onSubmit={onSubmit}
            price={price}
            pricing={pricing}
            formattedPrice={formattedPrice}
            currentStock={currentStock}
            pickupEnabled={pickupEnabled}
            shippingEnabled={shippingEnabled}
            listingId={currentListing.id}
            isOwnListing={currentListing.attributes.isOwnListing}
            //           onFetchTransactionLineItems={onFetchTransactionLineItems}
            //           onContactUser={onContactUser}
            //           lineItems={lineItems}
            //           fetchLineItemsInProgress={fetchLineItemsInProgress}
            //           fetchLineItemsError={fetchLineItemsError}
            description={description}
          />
        </div>
        {showAuthorInfo ? (
          <div className={css.authorInfo}>
            <FormattedMessage id="ListingCard.author" values={{ authorName }} />
          </div>
        ) : null}
      </div>
    );
  } else {
    return null;
  }
};

ListingCardWithInlineOrderComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardWithInlineOrderComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,
  onSubmit: func.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardWithInlineOrderComponent);
