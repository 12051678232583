import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: June 3rd, 2024</p>
      <h2>Privacy Policy</h2>
      <p>
        At Platter, we take the privacy and security of our users' information very seriously. We
        understand that trust is a crucial component of our relationship with our customers, and we
        are committed to protecting the personal information that you share with us.
      </p>
      <p>
        This privacy policy explains how we collect, use, and protect your personal information when
        you use our Software as a Service (SaaS) platform, Platter.
      </p>
      <h2>Information Collection and Use</h2>
      <p>
        When you sign up for an account on Platter, we will collect certain personal information
        from you, including your name, email address, and other contact information. We may also
        collect other information from you, such as your company name and job title, as well as any
        other information you choose to provide us with.
      </p>
      <p>
        We use this information to provide you with access to our SaaS platform, to communicate with
        you about your account and our services, and to personalise your experience on Platter. We
        may also use your information to send you marketing and promotional materials, but only if
        you have given us permission to do so.
      </p>
      <h2>Legal Basis for Processing</h2>
      <p>
        We process your personal data based on the following legal grounds: your consent, the
        performance of a contract, compliance with a legal obligation, and our legitimate interests.
      </p>
      <h2>Information Sharing and Disclosure</h2>
      <p>
        We will not share or disclose your personal information with any third parties, except as
        described in this privacy policy. We may share your information with third-party service
        providers who assist us in operating our business or providing our services. For example, we
        may share your information with companies that help us process payments, send email
        communications, provide customer support, or deliver marketing and advertising services.
      </p>
      <p>
        We ensure that any third parties we share your information with are subject to strict data
        processing agreements and are obligated to protect your information in accordance with this
        privacy policy and applicable data protection laws.
      </p>
      <p>
        We may also disclose your information if required to do so by law or in response to a valid
        request by a government or law enforcement agency.
      </p>
      <h2>Third-Party Services</h2>
      <p>
        Our SaaS platform may include links to third-party websites, services, or applications.
        Please note that this privacy policy does not apply to the privacy practices of third
        parties. We encourage you to review the privacy policies of any third-party services before
        providing your personal information.
      </p>
      <h2>Data Retention</h2>
      <p>
        We retain personal data for as long as necessary to fulfil the purposes for which it was
        collected or as required by applicable laws. After this period, we will securely delete or
        anonymise your data.
      </p>
      <h2>Cookies and Tracking Technologies</h2>
      <p>
        We use cookies and similar tracking technologies to enhance your experience on our platform.
        For more information, please refer to our Cookies Policy.
      </p>
      <h2>Security</h2>
      <p>
        We take a number of measures to protect your personal information from unauthorised access,
        use, or disclosure. We use a variety of security technologies and procedures to help protect
        your personal information from unauthorised access, use, or disclosure.
      </p>
      <p>
        For example, we store your personal information on secure servers that are located in
        controlled facilities. We also use firewalls and intrusion detection systems to help prevent
        unauthorised access to our servers. We also use encryption to protect sensitive information,
        such as payment card information, from unauthorised access.
      </p>
      <h2>International Data Transfers</h2>
      <p>
        If we transfer your personal data outside the UK/EU, we ensure appropriate safeguards are in
        place, such as standard contractual clauses or an adequacy decision.
      </p>
      <h2>Children’s Privacy</h2>
      <p>
        Our services are not intended for children under the age of 16. We do not knowingly collect
        personal data from children under 16. If we become aware that we have collected personal
        data from a child under 16, we will take steps to delete such information.
      </p>

      <h2>Your Rights</h2>
      <p>
        Under GDPR, you have the right to access, rectify, delete, or restrict the processing of
        your personal data. You also have the right to object to our processing of your data, and
        the right to data portability. You can exercise these rights by contacting us at
        hello@weareplatter.com . We may need to verify your identity before processing your request.
        We will respond to your request within one month.
      </p>

      <h2>Changes to This Privacy Policy</h2>
      <p>
        We may update this privacy policy from time to time to reflect changes in our practices or
        for other operational, legal, or regulatory reasons. We will post any changes to this
        privacy policy on our website, and we will notify you of any material changes by email or
        through a notice on our website.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any questions about this privacy policy or our information collection, use, or
        disclosure practices, please contact us at hello@weareplatter.com. You can also reach us at
        our office: 8, Balmes road. Hackney,London n15tq{' '}
      </p>
      <p>Effective as of Jan 1st 2023.</p>
      <h2>Platter GDPR Policy</h2>
      <h3>Introduction</h3>
      <p>
        Platter is committed to protecting the privacy and personal data of our customers and users
        in accordance with the General Data Protection Regulation (GDPR). This policy sets out our
        obligations and your rights under GDPR.
      </p>
      <h3>Types of data we collect</h3>
      <p>
        We collect and process personal data such as names, email addresses, and payment
        information. We also collect usage data, including information about how our service is
        used.
      </p>
      <h3>How we use your data</h3>
      <p>
        How We Use Your Data We use your data to provide and improve our service, process payments,
        communicate with you, and comply with legal obligations. We may also use your data for
        marketing and research purposes, but only with your explicit consent.
      </p>
      <h3>Data sharing</h3>
      <p>
        We do not share your data with any third parties, except as necessary to provide and improve
        our service, comply with legal obligations, or as required by court order.
      </p>
      <h2>Data Storage and Security</h2>
      <p>
        We use industry-standard security measures to protect your data and keep it confidential. We
        store your data on secure servers and take all reasonable steps to prevent unauthorised
        access, alteration, or destruction of your personal data.
      </p>

      <h3>Your rights</h3>
      <p>
        Under GDPR, you have the right to access, rectify, delete, or restrict the processing of
        your personal data. You also have the right to object to our processing of your data, and
        the right to data portability. You can exercise these rights by contacting us at
        hello@weareplatter.com.
      </p>
      <h3>Changes to this policy</h3>
      <p>
        We may update this policy from time to time to reflect changes in our practices or legal
        requirements. If we make any significant changes, we will notify you by email or through our
        service.
      </p>
      <h3>Contact us</h3>
      <p>
        If you have any questions about this policy or our handling of your personal data, please
        contact us at hello@weareplatter.com
      </p>
      
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
