import { parse } from '../../util/urlHelpers';

import { pluginHaulageOneClick } from '../../util/api';
import { googleChat } from '../../util/googleChat';

// ================ Action types ================ //

export const ONECLICK_REQUEST = 'app/PasswordRecoveryPage/ONECLICK_REQUEST';
export const ONECLICK_SUCCESS = 'app/PasswordRecoveryPage/ONECLICK_SUCCESS';
export const ONECLICK_ERROR = 'app/PasswordRecoveryPage/ONECLICK_ERROR';
export const ONECLICK_ALREADY_ACCEPTED_ERROR =
  'app/PasswordRecoveryPage/ONECLICK_ALREADY_ACCEPTED_ERROR';

// ================ Reducer ================ //

const initialState = {
  oneClickInProgress: false,
  orderAccepted: false,
  oneClickError: false,
  oneClickAlreadyAcceptedError: false,
  deliveryNoteUrl: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case ONECLICK_REQUEST:
      return {
        ...state,
        oneClickInProgress: true,
      };

    case ONECLICK_SUCCESS:
      return {
        ...state,
        oneClickInProgress: false,
        orderAccepted: true,
        deliveryNoteUrl: payload,
      };

    case ONECLICK_ERROR:
      return {
        ...state,
        oneClickInProgress: false,
        oneClickError: true,
      };

    case ONECLICK_ALREADY_ACCEPTED_ERROR:
      return {
        ...state,
        oneClickInProgress: false,
        oneClickError: true,
        oneClickAlreadyAcceptedError: true,

        deliveryNoteUrl: payload,
      };

    case ONECLICK_REQUEST:
      return {
        ...state,
        oneClickInProgress: true,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const oneClickRequest = () => ({ type: ONECLICK_REQUEST });
export const oneClickSuccess = deliveryNoteUrl => ({
  type: ONECLICK_SUCCESS,
  payload: deliveryNoteUrl,
});
export const oneClickError = email => ({ type: ONECLICK_ERROR });
export const oneClickAlreadyAcceptedError = deliveryNoteUrl => ({
  type: ONECLICK_ALREADY_ACCEPTED_ERROR,
  payload: deliveryNoteUrl,
});

// ================ Thunks ================ //

export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  dispatch(oneClickRequest());
  if (typeof window !== 'undefined') {
    const oneClickStatus = await pluginHaulageOneClick(parse(search));
    if (oneClickStatus.status === 'accepted') {
      dispatch(oneClickSuccess(oneClickStatus.deliveryNoteUrl));
      googleChat('oneclick.success', {
        transactionId: transacitonUUID,
        supplierId: transactionProtectedData.supplierId,
        buyerId: transactionProtectedData.buyerId,
      });
    }
    if (oneClickStatus.status === 'alreadyAccepted') {
      dispatch(oneClickAlreadyAcceptedError(oneClickStatus.deliveryNoteUrl));
    } else dispatch(oneClickError());
  }
};
